import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-inline: 14px; 
  
  .same_buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    width: auto;
    height: 27px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #304864;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 12px;
`;

export const Heading = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #2d4764;
`;

export const HeadingDivider = styled.div`
  flex: 1;
  height: 2px;
  background-color: #e2e2e2;
`;

export const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
  margin-block: 5px;
`;

interface ParameterLineProps {
  link?: boolean;
  hasSlider?: boolean;
  isPadding?: boolean;
  isEqualPadding?: boolean;
}
export const Line = styled.div<ParameterLineProps>`
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  margin-block: 4px;
  height:30px;

  cursor: ${(p) => (p.link ? "pointer" : "initial")};
  padding-left: ${(p) => (p.isPadding ? "17px" : "0px")};

  @media screen and (max-width: 500px) {
    margin-block: 8px;
    padding: 10px;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding-right: 10px;
    padding-left: ${(p) => p.isEqualPadding && "10px"};
    padding-block: 10px;
    height:60px;
    gap: 0px;
    ${({ hasSlider }) => hasSlider && "flex-wrap: wrap;"}
  }
`;

interface ParameterLabelProps {
  bold?: boolean;
  hasSlider?: boolean;
}
export const Label = styled.div<ParameterLabelProps>`
  display: flex;
  gap: 6px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
  font-weight: ${(p) => (p.bold ? "bold" : "normal")};
  flex-shrink: 1;
  min-width: 31%;

  ${({ hasSlider }) => hasSlider && "max-width: 35%;"}

  @media screen and (max-width: 500px) {
    max-width: calc(100% - 100px);
  }
`;

export const Input = styled.div`
  flex-grow: 1;
  min-width: 80px;
  width: 40%;
  .css-eg0mwd-MuiSlider-thumb {
    background-color: #f7f7fc !important;
    border: 0.893617px solid #2d4764 !important;
    width: 14px !important;
    height: 14px !important;
  }
  .css-1tfve6q-MuiSlider-mark {
    width: 0px !important;
  }
  .css-14pt78w-MuiSlider-rail {
    background-color: #d3deea !important;
    opacity: 1 !important;
  }

  @media screen and (max-width: 500px) {
    order: 3;
    width: 100%;
  }
`;

interface ValueProps {
  bold?: boolean;
  hasSlider?: boolean;
}
export const Value = styled.div<ValueProps>`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #304864;
  /* margin-left: 1.5rem; */
  font-weight: ${(p) => (p.bold ? "bold" : "normal")};
  width: 25%;
  text-align: right;
  @media screen and (max-width: 500px) {
    order: 2;
    margin-left: 0;
    width: auto;
  }
`;

interface PercentageProps {
  istwoNum?: Boolean;
}
export const Percentage = styled.div<PercentageProps>`
  position: absolute;
  right: ${(props) => {
    if (props.istwoNum) {
      return "13px";
    } else {
      return "6px";
    }
  }};
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;

export const ValueWrapper = styled.div`
  min-width: 85px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

interface InputPropsThing {
  isFloatValue?: boolean;
}
export const AllSubMainInput = styled.input<InputPropsThing>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  margin-left: 1.5rem;
  width: 63px;
  height: 27px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 16px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #304864;
`;
