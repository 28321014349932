import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Settings } from "react-slick";
import styled from "styled-components";

const PrevButton = styled.button`
  border-radius: 50%;
  border: 1px solid #666666;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translatey(-50%);
  @media only screen and (max-width: 500px) {
    left: 12px;
  }

  img {
    margin: auto;
  }

  .arrow_left {
    display: block;
    position: absolute;
    top: 50%;
    z-index: 5000;
    transform: translateY(-50%);
    cursor: pointer;
    color: black;
    width: 2em;
    left: -2px;
  }
`;

const NextButton = styled.button`
  border-radius: 50%;
  border: 1px solid #666666;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translatey(-50%);
  @media only screen and (max-width: 500px) {
    right: 0px;
    background: white;
  }

  img {
    margin: auto;
  }

  .arrow_right {
    display: block;
    position: absolute;
    top: 50%;
    right: -10px;
    z-index: 5000;
    transform: translateY(-50%);
    cursor: pointer;
    color: black;
    width: 2em;
  }
`;

interface SampleNextArrowProps {
  onClick?: any;
}
export const SampleNextArrow: React.FC<SampleNextArrowProps> = (props: SampleNextArrowProps) => {
  return (
    <NextButton
      onClick={props.onClick}
      style={{
        position: "absolute",
        right: window.innerWidth > 500 ? "-56px" : "-34px",
        zIndex: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ArrowForwardIos
        className="arrow_right"
        style={{
          color: "rgb(102, 102, 102)",
          transform: "none",
          fontSize: 20,
          position: "initial",
        }}
      />
    </NextButton>
  );
};

interface SamplePrevArrowProps {
  onClick?: any;
}
export const SamplePrevArrow: React.FC<SamplePrevArrowProps> = (props: SamplePrevArrowProps) => {
  return (
    <PrevButton
      onClick={props.onClick}
      style={{
        position: "absolute",
        left: window.innerWidth > 500 ? "-56px" : "-34px",
        zIndex: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "4.5px",
      }}
    >
      <ArrowBackIos
        className="arrow_left"
        style={{
          color: "rgb(102, 102, 102)",
          transform: "none",
          fontSize: 20,
          position: "initial",
        }}
      />
    </PrevButton>
  );
};

export const generateSettings = (width: number): Settings => {
  return {
    accessibility: true,
    speed: 600,
    slidesToShow: Math.max(Math.floor(width / 220), 1),
    slidesToScroll: Math.max(Math.floor(width / 220), 1),
    infinite: width >= 1000,
    dots: width >= 800,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: function (prev: any, next: any) {},
  };
};

export const generateSettingsForOffers = (width: number): Settings => {
  return {
    accessibility: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: width >= 1000,
    dots: width >= 800,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: function (prev: any, next: any) {},
  };
};