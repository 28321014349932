import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import { ProjectResponseDB } from "src/db-types/project";

import { ProjectCard } from "../ProjectCard/ProjectCard";

import { useSliderSettings } from "./hooks";

import { ProjectState } from "src/redux/project";

interface IProjectsSliderView {
  projects: ProjectResponseDB[];
  savedProjectsEnabled: boolean;
  onDelete: (address: string, id: string) => void;
}
export const ProjectsSliderView: React.FC<IProjectsSliderView> = (props: IProjectsSliderView) => {
  const { projects, savedProjectsEnabled, onDelete } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = useSliderSettings();
  const lastProjectsLength = useRef(projects.length);

  const [filteredProjects, setFilteredProjects] = useState<ProjectResponseDB[]>([]);

  useEffect(() => {
    if (lastProjectsLength.current < projects.length) {
      setCurrentSlide(Math.max(0, filteredProjects.length - 2));
    }
    lastProjectsLength.current = projects.length;
  }, [projects.length]);

  useEffect(() => {
    if (!savedProjectsEnabled) {
      setFilteredProjects(projects);
    } else {
      const uniqueProjects: Record<number, ProjectResponseDB> = {};

      projects.forEach((project) => {
        if (project.shortId in uniqueProjects) {
          if (project.version < uniqueProjects[project.shortId].version) {
            uniqueProjects[project.shortId] = project;
          }
        } else {
          uniqueProjects[project.shortId] = project;
        }
      });
      setFilteredProjects(Object.values(uniqueProjects));
    }
  }, [projects, savedProjectsEnabled]);

  const handleDelete = useCallback(
    (address, id) => {
      setCurrentSlide(Math.min(currentSlide, filteredProjects.length - 2));
      onDelete(address, id);
    },
    [onDelete, currentSlide, filteredProjects.length],
  );
  return (
    <div className="cardsContainer">
      {filteredProjects?.length > 0 && (
        <Slider
          key={filteredProjects.length}
          {...settings}
          initialSlide={currentSlide}
          afterChange={(slideIdx) => setCurrentSlide(slideIdx)}
        >
          {filteredProjects.map((data) => {
            return (
              <ProjectCard
                key={data._id}
                project={data}
                savedProjectsEnabled={savedProjectsEnabled}
                onDelete={handleDelete}
                setfilteredData ={setFilteredProjects}
              />
            );
          })}
        </Slider>
      )}
    </div>
  );
};
