import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";
import { useAppDispatch } from ".";

export const useFetch3dModel = () => {
  const dispatch = useAppDispatch();
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);
  const adminGeneratedBakeModelUrls = useSelector(ProjectSelectors.getAdminGeneratedBakeModelUrls);
  const quote = useSelector(ProjectSelectors.getQuoteProfile);
  const bakeModelImages = useSelector(ProjectSelectors.getBakeModelImages);
  const installerUploadedObj = selectedInstallers[0]?.files?.find((a) => a.endsWith(".obj"));
  const installerUploadedMtl = selectedInstallers[0]?.files?.find(
    (a) => a.includes(".obj.mtl") || a.includes(".mtl"),
  );

  const transformedUrls = useMemo(() => {
    const updatedUrls = adminGeneratedBakeModelUrls?.map((rawUrl: string) => {
      const [, , bucket, ...objectKeyParts] = rawUrl.split("/");
      const objectKey = objectKeyParts.join("/");

      return `https://${bucket.replace(".s3.", "")}.s3.eu-central-1.amazonaws.com/${objectKey}`;
    });
    return updatedUrls;
  }, [adminGeneratedBakeModelUrls]);

  useEffect(() => {
    const modelUrls: any = {};
    let active: string | null = null;

    if (bakeModelImages) {
      // user generated model
      modelUrls.userGenerated = {
        mtl: bakeModelImages.find((img) => img.includes(".obj.mtl")),
        obj: bakeModelImages.find((img) => img.endsWith(".obj")),
        images:
          bakeModelImages.filter((img) => !img.includes(".mtl") && !img.includes(".obj")) || [],
      };
      active = "userGenerated";
    }

    if (installerUploadedObj) {
      // files uploaded by installer
      modelUrls.installerGenerated = {
        obj: installerUploadedObj,
        mtl: installerUploadedMtl,
        images:
          selectedInstallers[0]?.files?.filter(
            (img) => !img.includes(".obj") && !img.includes(".mtl"),
          ) || [],
      };
      active = active || "installerGenerated";
    }

    if (transformedUrls) {
      // for admin generated model
      const objUrl: any = transformedUrls.find((img) => img.endsWith(".obj"));
      const mtlUrl: any = transformedUrls.find((img) => img.includes(".mtl"));
      const imgUrls: any = transformedUrls.filter(
        (img) => !img.includes(".mtl") && !img.includes(".obj"),
      );

      modelUrls.adminGenerated = {
        obj: objUrl,
        mtl: mtlUrl,
        images: imgUrls,
      };
      active = active || "adminGenerated";
    }

    if (quote.imgURLs.length) {
      modelUrls.cksProvided = {
        // cks provided model
        mtl: quote.imgURLs.find((img) => img.includes(".mtl")),
        obj: quote.imgURLs.find((img) => img.includes(".obj")),
        images: quote.imgURLs.filter((img) => !img.includes(".mtl") && !img.includes(".obj")) || [],
      };
      active = active || "cksProvided";
    }
    dispatch(ProjectActions.updateThreeDModels({ active, models: modelUrls }));
  }, [bakeModelImages, quote, installerUploadedObj]);
};
