import styled from "styled-components";

interface PrefProps {
  disabled?: boolean;
}

export const Preferences = styled.div<PrefProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .preference {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 14px;
    color: ${(p) => (p.disabled ? "grey" : "#2d4764")};

    &.hasSliderOrInput {
      gap: 1rem;
      width: 100%;

      .ant-slider {
        flex-grow: 1;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #2d4764 !important;
      border-color: #2d4764 !important;
    }
  }
`;

export const StyledPreferenceText = styled.span<PrefProps>`
  color: ${(p) => (p.disabled ? "grey" : "#2d4764")};
`;
