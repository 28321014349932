/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useThree } from "@react-three/fiber";
import {
    useState,
    useRef,
    useEffect,
    forwardRef,
} from "react";
import * as THREE from "three";

import { deleteIcon, plusIcon } from "src/assets/step33dViwer/index.js";
import { solarProductionData } from "./PopUp/SolarProductionData.js";

import eventEmitter from "./EventEmitter/EventEmitter.js";
import FlowManager from "./FlowManager.jsx";
import { loadIcon, loadOBJPanelModule, loadMaterials, initLoaders, loadGLTFModule } from "./Loaders.js"
import PanelGrid from "./PanelGrid.js";
import { MeshBVH, acceleratedRaycast } from "./utils/three-mesh-bvh.js";

import { useSelector } from "react-redux";
import { ProjectSelectors } from "src/redux/selectors";

const { PI, sin, cos, min, max } = Math;
const PanelGridRenderer = ({ documentRef, modelRef, onSolarDataChange, project, productObjFiles, setPanelsLoaded }) => {


    const quoteDetails = useSelector(ProjectSelectors.getQuote);
    const [isLoading, setIsLoading] = useState(true);
    const {
        gl, // WebGL renderer
        scene, // Default scene
        camera, // Default camera
        raycaster, // Default raycaster
        size, // Bounds of the view (which stretches 100% and auto-adjusts)
        viewport, // Bounds of the viewport in 3d units + factor (size/viewport)
        aspect, // Aspect ratio (size.width / size.height)
        mouse, // Current, centered, normalized 2D mouse coordinates
        // raycaster, // Intternal raycaster instance
        clock, // THREE.Clock (useful for useFrame deltas)
        invalidate, // Invalidates a single frame (for <Canvas invalidateFrameloop />)
        intersect, // Calls onMouseMove handlers for objects underneath the cursor
        setDefaultCamera, // Sets the default camera
    } = useThree();

    let terrainGroup;
    let modules, deleteObject, plusObject, dimentions;


    // debugger
    const getTerrain = () => {
        if (!modelRef.current) return;

        const modelMesh = modelRef.current.scene.children[0];
        // console.log(modelRef.current);

        if (!modelMesh) return;
        
        if (!modelMesh.geometry.boundsTree) {
            modelMesh.raycast = acceleratedRaycast;
            const geometry = modelMesh.geometry;
            geometry.boundsTree = new MeshBVH(geometry, {
                lazyGeneration: false,
            });
        }
        return modelRef.current.scene;
    };

    const renderer = gl;
   
    

    documentRef.current.terrainGroup = terrainGroup = getTerrain();
    documentRef.current.scene = scene;
    documentRef.current.renderer = renderer;


    const getVisibilities = (panelGrid) => {

        let visibilities = [];
        panelGrid.map((panel) => {
            if(panel.name === "supported") {
                visibilities.push(panel.isNotVisible ? true : false);
            }
        });
        return visibilities;
    };


    const panelGrids = documentRef.current.panelGrids;
    const toJSON = () => {
        const out = [];
        panelGrids.forEach((pg) => {
            const q = pg.quaternion;
            const isNotVisible = getVisibilities(pg.children);
            out.push({
                size: pg.size.clone(),
                position: pg.position.clone(),
                selectionSize: pg.selectionSize.clone(),
                quaternion: {
                    x: q.x,
                    y: q.y,
                    z: q.z,
                    w: q.w,
                },
                gridFaceUp: pg.gridFaceUp,
                isNotVisible: isNotVisible
            });
        });
        return out;
    };

    modules = documentRef.current.modules;
    deleteObject = documentRef.current.deleteObject;
    plusObject = documentRef.current.plusObject;
    dimentions = documentRef.current.dimentions;

    const fromJSON = (json) => {
        // debugger
        let out;
        try {
            out = JSON.parse(json);
        } catch {
            out = undefined;
        }
        if (!out) {
            return/// alert("Failed to load JSON.");
        }

        panelGrids.forEach((pg) => pg.disposeOccupancy());
        panelGrids.forEach(
            (pg) =>
                pg.selectionMesh &&
                pg.selectionMesh.parent.remove(pg.selectionMesh)
        );
        panelGrids.length = 0;

        // return
        out.forEach((pg) => {
            const grid = new PanelGrid({
                scene,
                modules,
                deleteObject,
                plusObject,
                dimentions,
                documentRef
            });
            grid.size.copy(pg.size);
            grid.position.copy(pg.position);
            grid.quaternion.copy(pg.quaternion);
            grid.selectionSize.copy(pg.selectionSize);
            grid.gridFaceUp = pg.gridFaceUp;
            grid.selectionMesh.scale.copy(grid.selectionSize);
            grid.selectionMesh.visible = true;
            grid.recomputeOccupancy({
                gridFaceUp: grid.gridFaceUp,
                targets: getTerrain().children,
            });
            grid.initNotVisible = pg.isNotVisible;
            grid.redrawOccupancy(scene);
            grid.panelMatrix = grid.occupancy.panelMatrix;

            grid.children.map((panel, index) => {
                if(panel.name === "supported") {
                    const panelIndex = grid.children.findIndex(child => child.uuid === panel.uuid);
                    const panelPosition = getPosition(grid.panelMatrix, panelIndex - 1);
                    grid.panelMatrix[panelPosition.row][panelPosition.col].enabled = !grid.initNotVisible[index - 1];  
                }; 
            });
            
            scene.add(grid);
            panelGrids.push(grid);
        });
        setPanelsLoaded(true);
    };

    const getPosition = (matrix, index) => {
        let row = 0;
        while (index >= matrix[row].length) {
          index -= matrix[row].length;
          row++;
        }
        const col = index;
        return({row, col});
    };

    async function removePanelGrid(panelGrid) {
        // Find the index of the panelGrid to remove
        const index = panelGrids.indexOf(panelGrid);
        if (index > -1) {
            panelGrids.splice(index, 1);
            scene.remove(panelGrid);
            // panelGrid.dispose(); 

        /* if (panelGrid.boxFrame) {
            scene.remove(panelGrid.boxFrame);
            panelGrid.boxFrame = null;
          }
      
          if (documentRef.current.boxFrame === panelGrid.boxFrame) {
            documentRef.current.boxFrame = null;
          } */
    
            // Fetch data after removing the panel grid
            const data = await solarProductionData(panelGrids, quoteDetails, []);
            setTimeout(()=>{
                onSolarDataChange(data);
            },200)
        }
    }

    //  })
    // await loadOBJPanelModule()
    useEffect(() => {
        // console.log(project,"project");
        setIsLoading(true);
        async function loadPanelModules(renderer, scene) {
            const createDeleteIcon = async () => {
                const tex = await new THREE.TextureLoader().loadAsync(
                    deleteIcon
                );
                tex.colorSpace = "srgb";
                const deleteObject = new THREE.Sprite(
                    new THREE.SpriteMaterial({
                        map: tex,
                        // sizeAttenuation:false,
                    })
                );
                // deleteObject.scale.multiplyScalar(.1)
                return deleteObject;
            };

            const createPlusIcon = async () => {
                const tex = await new THREE.TextureLoader().loadAsync(
                    plusIcon
                );
                tex.colorSpace = "srgb";
                const plusObject = new THREE.Sprite(
                    new THREE.SpriteMaterial({
                        map: tex,
                        // sizeAttenuation:false,
                    })
                );
                return plusObject;
            };

            const panelName = project?.threeDObjDetails?.panelName;
            let productFile = productObjFiles.find(item => item.obj === panelName);
            if(!productFile) {
                productFile = productObjFiles[0];
            };
            let materials, objModule;
            const { obj, mtl, height, width } = productFile;

            try {
                const { mtlLoader } = initLoaders();

                materials = await loadMaterials(mtlLoader, mtl);
                objModule = await loadOBJPanelModule(obj, materials, documentRef.current.dimentions);
                dimentions = {
                    height: height ? + height / 1000 : 2,
                    width: width ? + width / 1000 : 1,
                };
            } catch (error) {
                console.error("Error loading panel:", error);
            }

            if (objModule) {
                console.log(objModule, "panel");
                const modules = [objModule];
                modules.forEach((m) => {
                    m.updateMatrix();
                    m.updateMatrixWorld();
                });

            }
            deleteObject = await createDeleteIcon();
            plusObject = await createPlusIcon();
            modules = [objModule];
            // panelSize= new THREE.Vector3()

            // modules[0].scale.multiplyScalar(.5);//traverse(e=>e.isMesh&&e.geometry.scale(.5,.5,1.))

            documentRef.current.modules = modules;
            documentRef.current.deleteObject = deleteObject;
            documentRef.current.plusObject = plusObject;
            documentRef.current.loaded = true;
            documentRef.current.dimentions = dimentions

            setIsLoading(false);

            // let boxFrame = new THREE.Group()

            // console.log(boxFrame,"boxframe");

            documentRef.current.toJSON = toJSON;
            documentRef.current.fromJSON = fromJSON;
            documentRef.current.removePanelGrid = removePanelGrid;
            // documentRef.current.boxFrame = boxFrame


            const mapName = "Untitled";
            // fromJSON(project?.threeDObjDetails?.panelPlacement);

            if(!getTerrain()) {
                return;
            };

            fromJSON(project?.threeDObjDetails?.panelPlacement);
            console.log(project?.threeDObjDetails?.panelPlacement,"panelplacemnet");



        }
        loadPanelModules();

    }, []);
};

export default PanelGridRenderer;
