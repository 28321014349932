import * as THREE from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { GUI } from "three/addons/libs/lil-gui.module.min.js";
import { MeshoptDecoder } from "three/addons/libs/meshopt_decoder.module.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { KTX2Loader } from "three/addons/loaders/KTX2Loader.js";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";

import FlowManager from "./flow.js";


export default function Renderer3(threejsEl) {
  console.log(threejsEl,"threejsEl")
  const flow = new FlowManager();
  const container = document.createElement("div");
  // container.style.position = "fixed";
  // container.style.top = "0";
  // container.style.left = "0";
  // container.style.right = "0";
  // container.style.bottom = "0";
  container.style.width = "100%";
  container.style.height = "100%";
  if (threejsEl?.current) {
    threejsEl?.current?.appendChild(container);
  }

  const scene = new THREE.Scene();
  scene.background = new THREE.Color("black");

  const dirLight = new THREE.DirectionalLight("white", 0.9);
  dirLight.position.set(0, 1.75, 0);
  dirLight.position.multiplyScalar(70);
  dirLight.castShadow = true;
  dirLight.shadow.mapSize.width = 2048;
  dirLight.shadow.mapSize.height = 2048;
  dirLight.shadow.camera.top = dirLight.shadow.camera.right = 2.5;
  dirLight.shadow.camera.far = 200;
  dirLight.shadow.camera.bottom = dirLight.shadow.camera.left = -2.5;
  dirLight.shadow.camera.updateProjectionMatrix();
  scene.add(dirLight);

  // const light = new THREE.AmbientLight('white',0.3)
  // scene.add(light)

  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.5, 300);
  const renderer = new THREE.WebGLRenderer({
    antialias: true,
  });
  const labelRenderer = new CSS2DRenderer();
  labelRenderer.domElement.style.position = "absolute";
  labelRenderer.domElement.style.top = "0px";
  labelRenderer.domElement.style.pointerEvents = "none";
  if (threejsEl?.current) {
    threejsEl?.current?.appendChild(labelRenderer.domElement);
  }
  renderer.setSize(window.innerWidth, window.innerHeight);
  labelRenderer.setSize(window.innerWidth, window.innerHeight);

  renderer.shadowMap.enabled = false;

  renderer.shadowMap.type = THREE.PCFSoftShadowMap;

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.05;
  controls.screenSpacePanning = false;
  controls.minDistance = 1;
  controls.maxDistance = 500;
  controls.maxPolarAngle = Math.PI / 2;

  controls.target.set(0, 0.5, 0);
  camera.position.set(-2, 2, -2);

  container.appendChild(renderer.domElement);

  const gui = new GUI();
  gui.close();
  gui.hide();
  const onWindowResize = (event) => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    renderer.setSize(width, height);
    labelRenderer.setSize(threejsEl.current.clientWidth, threejsEl.current.clientHeight);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  };

  onWindowResize();
  window.addEventListener("resize", onWindowResize, false);

  const mixer = new THREE.AnimationMixer();

  const glbLoader = new GLTFLoader();
  const ktx2Loader = new KTX2Loader()
    .setTranscoderPath("https://threejs.org/examples/jsm/libs/basis/")
    .detectSupport(renderer);
  glbLoader.setKTX2Loader(ktx2Loader);
  glbLoader.setMeshoptDecoder(MeshoptDecoder);

  const keys = {};
  const frameTasks = [];

  const io = {
    frame: 0,
    seconds: 0,
    now: 0,
    keys,
    addFrameTask: (task) => frameTasks.push(task),
    render: () => {
      labelRenderer.render(scene, camera);
      renderer.render(scene, camera);
    },
    urlParams: new URLSearchParams(window.location.search),
  };

  threejsEl.current.addEventListener("keydown", (e) => !keys[e.code] && (keys[e.code] = true));
  threejsEl.current.addEventListener("keyup", (e) => keys[e.code] && (keys[e.code] = false));
  threejsEl.current.addEventListener("pointerdown", (e) => (io.buttons = e.buttons));
  threejsEl.current.addEventListener("pointerup", (e) => (io.buttons = e.buttons));

  const raycaster = (io.raycaster = new THREE.Raycaster());
  io.mouseNDC = new THREE.Vector2();

  io.mouseScreen = new THREE.Vector2();

  function onPointerMove(event) {
    const rect = threejsEl.current.getBoundingClientRect();
    io.mouseScreen.set(event.clientX, event.clientY);
    io.mouseNDC.set(
      ((event.clientX - rect.left) / rect.width) * 2 - 1,
      -((event.clientY - rect.top) / rect.height) * 2 + 1,
    );

    raycaster.setFromCamera(io.mouseNDC, camera);
  }
  threejsEl.current.addEventListener("pointermove", onPointerMove);
  threejsEl.current.addEventListener("blur", () => {
    Object.keys(io.keys).forEach((k) => delete io.keys[k]);
  });
  threejsEl.current.addEventListener("focus", () => {});

  let lastTime;

  const updateAndDraw = (time) => {
    requestAnimationFrame(updateAndDraw);
    io.now = time; // performance.now();
    io.seconds = io.now / 1000;
    if (!lastTime) lastTime = time;
    const dt = time - lastTime;
    lastTime = time;
    // let dt = 1/60;
    mixer.update(dt);
    frameTasks.forEach((task) => task(time, dt));
    io.update && io.update(io.seconds, dt);
    flow.updateAll(time);
    controls.update();
    io.render();
    io.frame++;
  };
  io.startRendering = () => requestAnimationFrame(updateAndDraw);

  return {
    THREE,
    scene,
    camera,
    controls,
    renderer,
    dirLight,
    gui,
    glbLoader,
    io,
    flow,
    labelRenderer,
  };
}
