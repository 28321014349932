import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ScaleIcon } from "src/assets/step33dViwer";
import "./MeasurementPopup.css";

const MeasurementPopup = ({ setModelScale, measuredValue, modelScale, setScalePoints }: any) => {
  // State for the editable "Actual" value
  const [actualValue, setActualValue] = useState(0);
  const [scale, setScale] = useState(modelScale);
  const { t } = useTranslation();

  const handleSubmit = () => {
    setModelScale(scale);
    toast.success(`${t("your model has been scaled by")} ${scale.toFixed(1)}X`)
    setScalePoints([])
  };

  useEffect(() => {
    if (actualValue) {
      setScale((actualValue * modelScale) / measuredValue);
    } else {
      setScale(modelScale);
    }
  }, [measuredValue, actualValue, modelScale]);

  return (
    <div
      className="absolute top-[75%] left-1/2 transform -translate-x-1/2 flex items-center w-max bg-gray-100 border border-gray-300 shadow-lg rounded-lg p-2 space-x-2"
      style={{ zIndex: 1000 }}
    >
      {/* Icon */}
      <div className="text-blue-500">
        <img src={ScaleIcon} alt="ScaleIcon" />
      </div>

      {/* Measure Field */}
      <div className="text-sm">
        <span className="font-bold">{t("Measure")}:</span>{" "}
        <span className="bg-[#D8D8D8] p-1 rounded-md">{measuredValue}m</span>
      </div>

      {/* Editable Actual Field */}
      <div className="flex items-center space-x-1">
        <span className="font-bold text-sm">{t("Actual")}:</span>
        <div className="flex items-center bg-[#D8D8D8] p-1 rounded-md text-sm border border-gray-300 px-1 py-0.5 ">
          <input
            type="number"
            className="bg-[#D8D8D8] w-auto focus:outline-none appearance-none no-arrows"
            value={actualValue}
            autoFocus
            style={{
              width: `${
                String(actualValue).length === 0
                  ? String(actualValue).length + 1
                  : String(actualValue).length
              }ch`,
            }}
            onChange={(e: any) => setActualValue(e.target.value)}
          />
          {String(actualValue).length > 0 && <span>m</span>}
        </div>
      </div>

      {/* Scale Field */}
      <div className="text-sm">
        <span className="font-bold">{t("Scale")}:</span>
        <span className="text-green-500"> {scale.toFixed(1)}x</span>
      </div>

      {/* Confirm Button */}
      <button className="bg-yellow-500 text-white text-sm px-3 py-1 rounded" onClick={handleSubmit}>
        {t("Confirm")}
      </button>
    </div>
  );
};

export default MeasurementPopup;
