import { Method } from "axios";

// export const host = "https://solarhub-backend-v2.vercel.app";
// const host = "https://backend.prod.solarhub24.de";
const host  = process.env.REACT_APP_BACKEND_URL


// bakemodel host
export const bakeModelHost = "https://tufhsl1q81.execute-api.eu-central-1.amazonaws.com";

// ranting API url
const golfstromHost = "https://app.golfstrom.solar/api/integration/v1/offers";

interface Endpoint {
  url: string;
  method: Method;
}

export const baseURL = `${host}/api`;

export const login: Endpoint = {
  url: `${baseURL}/user/login`,
  method: "POST",
};

export const allProducts: Endpoint = {
  url: `${baseURL}/product`,
  method: "GET",
};

export const user: Endpoint = {
  url: `${baseURL}/user`,
  method: "GET",
};

export const signup: Endpoint = {
  url: `${baseURL}/user/signup`,
  method: "POST",
};

export const resetPass = (token: string): Endpoint => ({
  url: `${baseURL}/user/reset-password/${token}`,
  method: "POST",
});

export const signupInWebinar: Endpoint = {
  url: `${baseURL}/user/webinar/signup`,
  method: "POST",
};

export const forgotPassword: Endpoint = {
  url: `${baseURL}/user/forgot-password`,
  method: "POST",
};

export const googleLogin: Endpoint = {
  url: `${baseURL}/oauth/google/auth-code`,
  method: "POST",
};

export const googleRefresh: Endpoint = {
  url: `${baseURL}/oauth/google/refresh-token`,
  method: "POST",
};

export const userDetails: Endpoint = {
  url: `${baseURL}/user/update/personal-details`,
  method: "PUT",
};

export const constants: Endpoint = {
  url: `${baseURL}/constant`,
  method: "GET",
};

export const getRefCode: (userId: string, quoteId: string) => Endpoint = (
  userId: string,
  quoteId: string,
) => ({
  url: `${baseURL}/user/${userId}/${quoteId}`,
  method: "GET",
});

export const quote: Endpoint = {
  url: `${baseURL}/quote`,
  method: "POST",
};

export const saveProject: Endpoint = {
  url: `${baseURL}/saved-project/create`,
  method: "POST",
};

export const updateProject: (id: string) => Endpoint = (id: string) => ({
  url: `${baseURL}/saved-project/update/${id}`,
  method: "PUT",
});

interface IGetProject {
  shortId: number;
  version: number;
}
export const getProject: (args: IGetProject) => Endpoint = (args: IGetProject) => ({
  url: `${baseURL}/saved-project/${args.shortId}/${args.version}`,
  method: "GET",
});

export const updateLastLog: Endpoint = {
  url: `${baseURL}/user/update/last-log`,
  method: "PUT",
};

interface IGetAllProjects {
  uid: string;
}
export const getAllProjects: (args: IGetAllProjects) => Endpoint = (args: IGetAllProjects) => ({
  url: `${baseURL}/saved-project/${args.uid}`,
  method: "GET",
});

export const createHubspotDeal: Endpoint = {
  url: `${baseURL}/deal/create`,
  method: "POST",
};

export const updateRefCredits: Endpoint = {
  url: `${baseURL}/user/creditupdate`,
  method: "PUT",
};

export const deleteProjects: Endpoint = {
  url: `${baseURL}/saved-project/delete`,
  method: "PUT",
};

interface IGetAllVersions {
  shortId: number;
}
export const getAllVersions: (args: IGetAllVersions) => Endpoint = (args: IGetAllVersions) => ({
  url: `${baseURL}/saved-project/project/versions/${args.shortId}`,
  method: "GET",
});

interface ICreateNewVersion {
  quoteId: string;
}
export const createNewVersion: (args: ICreateNewVersion) => Endpoint = (
  args: ICreateNewVersion,
) => ({
  url: `${baseURL}/saved-project/new-version/${args.quoteId}`,
  method: "PUT",
});

export const uploadImages: Endpoint = {
  url: `${baseURL}/media`,
  method: "POST",
};

export const getAllWebinars: Endpoint = {
  url: `${baseURL}/webinar/with-details`,
  method: "GET",
};

interface getPartnerCityProps {
  id: string | null;
}

export const getPartnerCity: (props: getPartnerCityProps) => Endpoint = ({ id }) => {
  return {
    url: `${baseURL}/partner-cities/${id}`,
    method: "POST",
  };
};

export const getRentingData: Endpoint = {
  url: `${baseURL}/quote/rentingPrice`,
  method: "PATCH",
};

   export const getRentingOffer: Endpoint = {
     url: `${baseURL}/quote/rentingOffer`,
     method: "PATCH",
   };

export const nearbyInstallers: Endpoint = {
  url: `${baseURL}/installer/nearBy`,
  method: "POST",
};

export const addInstaller: Endpoint = {
  url: `${baseURL}/matched/create`,
  method: "POST",
};

interface DeleteInstallerProps {
  iid: string;
  pid: string;
}
export const deleteInstaller: (props: DeleteInstallerProps) => Endpoint = ({ iid, pid }) => {
  return {
    url: `${baseURL}/matched/${iid}/${pid}`,
    method: "DELETE",
  };
};

//update obj download count api
export const updateDownloadCount : Endpoint = {
    url: `${baseURL}/user/downloadupdate`,
    method:"PUT"
}

export const exploreInstaller: Endpoint = {
  url: `${baseURL}/installer/search`,
  method: "PUT",
};

interface updateInstallerProps {
  id: string;
}

export const updateInstaller: (props: updateInstallerProps) => Endpoint = ({ id }) => {
  return {
    url: `${baseURL}/matched/${id}`,
    method: "PUT",
  };
};

export const requestBakeModel: Endpoint = {
  url: `${bakeModelHost}/bakeModel`,
  method: "POST",
};

interface FeatchArtifacesProps {
  bakeModelId: string;
}

export const fetchArtifacts: (props: FeatchArtifacesProps) => Endpoint = ({ bakeModelId }) => {
  return {
    url: `${bakeModelHost}/bakedModels?bakedModelId=${bakeModelId}`,
    method: "GET",
  };
};

export const updateQuoteCount: Endpoint = {
  url: `${baseURL}/quote/updatevisit`,
  method: "PUT",
};

export const updateQuote: Endpoint = {
  url: `${baseURL}/quote/update`,
  method: "PUT",
};

export const update3dQuote: Endpoint = {
  url: `${baseURL}/quote/update-3d-details`,
  method: "PUT",
};

export const listOfProductObjFiles: Endpoint = {
  url: `${host}/api/product/product-obj-files`,
  method: "GET",
};

export const sendResultMailToInstaller: Endpoint = {
  url: `${baseURL}/email/acceptanceOffer`,
  method: "POST",
}

export const updateimgUrLs: Endpoint = {
  url: `${host}/api/quote/update/image-urls`,
  method: "PUT",
}
export const shareProjectWithCustomer: Endpoint = {
  url: `${baseURL}/email/shareContactDetails`,
  method: "POST",
}

export const downloadProjectPdf: Endpoint = {
  url: `${baseURL}/comman/projectPdf`,
  method: "POST",
}

export const updateImages: Endpoint = {
  url: `${baseURL}/quote/update/image-urls`,
  method: "PUT",
};
