import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CloseIcon, deleteIcon } from "src/assets/step33dViwer";

const PolygonInfoPopup = ({ polygons, onClose, setPolygons, setHistoryPolygons, editor }) => {
  const { t } = useTranslation();
  const [detailsVisible, setDetailsVisible] = useState([]);
  if (!polygons || polygons.length === 0) return <></>;

  // Assuming point.distanceTo() is defined somewhere
  const distance = (p1, p2) => {
    return p1?.distanceTo(p2).toFixed(2);
  };

  const calculatePolygonMetrics = (points) => {
    let perimeter = 0;
    let area = 0;
    const n = points.length;

    for (let i = 0; i < n; i++) {
      const p1 = points[i];
      const p2 = points[(i + 1) % n];

      const sideLength = distance(p1, p2);
      perimeter += parseFloat(sideLength);

      area += p1.x * p2.z - p2.x * p1.z;
    }

    area = Math.abs(area) / 2;
    return { perimeter: perimeter.toFixed(2), area: area.toFixed(2) };
  };

  const onDeletePolygon = (index) => {
    const modifiedPolygons = polygons.filter((el, i) => i !== index);
    setPolygons(modifiedPolygons);
    setHistoryPolygons(modifiedPolygons)
    editor.document.history = editor.document.history.filter((item, i) => item.polygonIndex !== (index + 1));
    editor.document.cursor = editor.document.history.filter((item, i) => item.polygonIndex !== (index + 1)).length;
    // if (modifiedPolygons.length === 0) {
    //   debouncedDispatch(modifiedPolygons, cablingLoops);
    // }
  };

  return (
    <div>
      {/* <div style={{ display: "flex", gap: "20px" }}>
        <h3>
          <strong>{t("Polygon Information")}</strong>
        </h3>
        <button onClick={onClose}>
          <img src={CloseIcon} className="close-icon" />
        </button>
      </div> */}

      {polygons.map((polygon, index) => {
        const evenPoints = polygon.filter((_, i) => i % 2 === 0);
        const { perimeter, area } = calculatePolygonMetrics(polygon);

        // Calculate distances between even-indexed points for this polygon
        const distances = evenPoints.map((_, i) => {
          const nextIndex = (i + 1) % evenPoints.length;
          return distance(evenPoints[i], evenPoints[nextIndex]);
        });

        return (
          <div key={index}>
            <h4
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              {t("Polygon")} {index + 1}
              <button
                className="toggle-details"
                onClick={() => {
                  if (detailsVisible.includes(index)) {
                    setDetailsVisible(detailsVisible.filter((ele) => ele !== index));
                  } else {
                    setDetailsVisible([...detailsVisible, index]);
                  }
                }}
              >
                {detailsVisible.includes(index) ? "▲" : "▼"}{" "}
                {/* Up arrow if details are visible, down arrow otherwise */}
              </button>
              <button className="" onClick={() => onDeletePolygon(index)}>
                <img src={deleteIcon} alt="deleteIcon" height={32} width={32} />
              </button>
            </h4>

            {!detailsVisible.includes(index) && (
              <div style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}>
                {" "}
                <h5>
                  <strong>{t("Distances between Points")}:</strong>
                </h5>
                <ul>
                  {distances.map((dist, idx) => (
                    <li key={idx}>
                      {t("Distance from point")} {idx + 1} {t("to")} {idx + 2}: {dist} {t("meters")}
                    </li>
                  ))}
                </ul>
                <p>
                  <strong>{t("Area")}:</strong> {area} {t("square meters")}
                </p>
                <p>
                  <strong>{t("Perimeter")}:</strong> {perimeter} {t("meters")}
                </p>
              </ div>
            )}
            <span className="separator"></span>
          </div>
        );
      })}
    </div>
  );
};

export default PolygonInfoPopup;
