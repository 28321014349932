import { useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks";

import { useURLData } from "src/hooks/useURLData";
import { isOnDefaultProject } from "src/utils";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

export const useQuoteChange = (): void => {
  const dispatch = useAppDispatch();

  const { isOnDefault } = useURLData();

  const quote = useAppSelector(ProjectSelectors.getQuote);
  const profileName = useAppSelector(ProjectSelectors.getProfileName);

  const updateRoofPitch = useCallback(
    (roofPitch: number) => {
      dispatch(
        ProjectActions.updateEnergyProductionParams({
          roofPitch,
        }),
      );
    },
    [dispatch],
  );

  const updateModuleQty = useCallback(
    (quantity: number) => {
      dispatch(
        ProjectActions.updateSolarPanel({
          quantity,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (isOnDefault) {
      const { roofTilt, moduleQuantity } = quote.profiles[profileName];

      updateModuleQty(moduleQuantity);
      updateRoofPitch(roofTilt);
    }
  }, [dispatch, isOnDefault, quote.profiles, updateModuleQty, updateRoofPitch]);
};
