import { CircularProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useRegisterIntoWbinar } from "src/hooks";

import * as BackendEndpoints from "src/api/backend-endpoints";
import { convertUserFromDB } from "src/db-converters";
import { openCorrPopupBasedOnPartnerStatus } from "src/helpers/partner-cities/partnerCityHelper";
import { useLoginAPI } from "src/hooks/apis";
import { trackingEvents, Mixpanel } from "src/mix-panel";
import { MasterCheckbox } from "src/shared/components";
import { getErrorMessage } from "src/utils";

import AuthButtons from "../modeButtons/authButtons";
import { AuthPopupProps, AUTH_MODES } from "../types";

import cssClasses from "./login.module.css";

import { UserActions } from "src/redux/actionCreators";
import {
  POPUP_KEYS,
  actionCreators as PopupActions,
  selectors as PopupSelectors,
} from "src/redux/popups";
import { selectors as ProjectSelectors } from "src/redux/project";
import { getQuote } from "src/redux/project/selectors";
import { UserAuthStatus, selectors as UserSelector } from "src/redux/user";

import utilCssClasses from "src/styles/util.module.css";
import useGetSaveProjectFun from "src/hooks/useGetSaveProjectFun";

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading: webinarLoading, registerForWebinar } = useRegisterIntoWbinar();

  // const { save } = useProjectTools();
  const authPopupProps = useSelector(PopupSelectors.getAuthPopup);
  // const project = useSelector(ProjectSelectors.getProject);
  const isAuthLoading = useSelector(UserSelector.getIsAuthLoading);
  const popupProps = useSelector(PopupSelectors.getAuthPopup);
  const quote = useSelector(getQuote);
  const { saveProject } = useGetSaveProjectFun();

  const { error, fetchData, loading, response } = useLoginAPI();

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const isWebinarMode = useMemo(
    () =>
      popupProps.data.authMode === AUTH_MODES.webinar_signup ||
      popupProps.data.authMode === AUTH_MODES.webinar_login,
    [popupProps],
  );

  const submitHandler = useCallback(() => {
    Mixpanel.track(trackingEvents.USER_LOGIN, {});
    // await logInWithEmailPassword(loginForm, save, project, navigate);

    // makeApiCall(loginForm);
    if (isWebinarMode) {
      registerForWebinar(loginForm.email, loginForm.password);
      return;
    }
    void fetchData({
      ...BackendEndpoints.login,
      data: loginForm,
    });
  }, [fetchData, isWebinarMode, loginForm, registerForWebinar]);

  useEffect(() => {
    dispatch(UserActions.setIsAuthLoading(loading || webinarLoading));
  }, [dispatch, loading, webinarLoading]);

  useEffect(() => {
    if (!response) return;

    const {
      data: { user, token },
    } = response.data;

    localStorage.setItem("token", token);
    const userState = convertUserFromDB(user);
    dispatch(UserActions.setUser(userState));
    dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedIn));
    saveProject();
    if (authPopupProps.data.afterSuccess) {
      authPopupProps.data.afterSuccess();
    }

    if(quote.mapboxAddress.complete !== "Ringstraße 7, 79252 Stegen, Germany") {
      openCorrPopupBasedOnPartnerStatus();
    }
    
    dispatch(PopupActions.closePopup(POPUP_KEYS.auth));
  }, [authPopupProps.data, dispatch, response, quote]);

  useEffect(() => {
    if (!error) return;

    const message = getErrorMessage(error);
    const id = toast.error(t(message));
    dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedOut));
  }, [dispatch, error, t]);

  const updateForm = useCallback(
    (target: keyof typeof loginForm, value: string | boolean, prev: typeof loginForm) => {
      const newForm: typeof loginForm = { ...prev };

      if (target === "email") {
        newForm.email = value as string;
      } else if (target === "password") {
        newForm.password = value as string;
      } else if (target === "rememberMe") {
        newForm.rememberMe = value as boolean;
      }

      return newForm;
    },
    [],
  );

  const goToSignUP = useCallback(() => {
    let nextMode = AUTH_MODES.signup;

    if (authPopupProps.data.authMode === AUTH_MODES.webinar_login) {
      nextMode = AUTH_MODES.webinar_signup;
    }

    const data: AuthPopupProps = {
      authMode: nextMode,
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.auth, data));
  }, [authPopupProps.data.authMode, dispatch]);

  return (
    <>
      <div className={cssClasses.loginContainer} id="login-form">
        <TextField
          fullWidth
          id="email-form-field"
          label={t("Email")}
          variant="outlined"
          onChange={(e: any) => {
            setLoginForm((prev: any) => updateForm("email", e.target.value, prev));
          }}
          InputProps={{
            style: { color: "#2d4764" },
            inputProps: {
              style: { color: "#2d4764" }, 
            },
          }}
          InputLabelProps={{
            style: { color: "#2d4764" },
          }}
        />

        <TextField
          fullWidth
          id="password-form-field"
          label={t("Password")}
          type="password"
          variant="outlined"
          onChange={(e: any) => {
            setLoginForm((prev: any) => updateForm("password", e.target.value, prev));
          }}
          InputProps={{
            style: { color: "#2d4764" },
            inputProps: {
              style: { color: "#2d4764" }, 
            },
          }}
          InputLabelProps={{
            style: { color: "#2d4764" },
          }}
        />

        <div className={cssClasses.loginButtonRow}>
          <MasterCheckbox
          
            checked={loginForm.rememberMe}
            onChange={(value) => {
              setLoginForm((prev: any) => updateForm("rememberMe", value, prev));
            }}
          >
            <div className={utilCssClasses.link1}>
            {t("REMEMBER LOGIN")}
            </div>
          </MasterCheckbox>
          <div
            className={utilCssClasses.link}
            onClick={() => {
              const data: AuthPopupProps = {
                authMode: AUTH_MODES.forgot_password,
              };
              dispatch(PopupActions.openPopup(POPUP_KEYS.auth, data));
            }}
          >
            {t("FORGOT PASSWORD")}
          </div>
        </div>
      </div>

      <button className={cssClasses.loginButton} onClick={submitHandler} disabled={isAuthLoading} id="login-submit">
        {t("LOGIN")}
        {isAuthLoading && <CircularProgress size={20} className="circular-progress-custom" />}
      </button>
      {!isWebinarMode && <AuthButtons />}

      <div className={cssClasses.formFooter} id='register-now'>
        <div className={cssClasses.blueLinkText} onClick={goToSignUP}>
          {t("YOU DONT HAVE AN ACCOUNT")}
        </div>
        <div className={cssClasses.yellowLinkText} onClick={goToSignUP}>
          {t("REGISTER NOW")}
        </div>
      </div>
    </>
  );
};

export default LoginForm;
