import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { InputValueWrapper } from "src/pages/ApplicationPage/components/ApplicationStep1/components/Configuration/components/ConfigurationParam/components/ManageAssumptions/ManageAssumptions.styles";
import MasterInput from "src/shared/components/MasterInput/MasterInput";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";
import { getUserPreferredLanguage, getUserPreferredLanguageShort } from "src/utils";

import { HeatpumpConfiguration } from "src/redux/project";
import { ProjectSelectors } from "src/redux/selectors";

interface FuturePriceIncreaseForOilGasProps {
  onValueChange: (heatpumpPartData: Partial<HeatpumpConfiguration>) => void;
}
const FuturePriceIncreaseForOilGas: React.FC<FuturePriceIncreaseForOilGasProps> = (
  props: FuturePriceIncreaseForOilGasProps,
) => {
  const { onValueChange } = props;

  const { t } = useTranslation();

  const { configuration } = useSelector(ProjectSelectors.getHeatpump);
  const userPreferredLanguage = getUserPreferredLanguageShort();

  const onSliderChangeHandler = useCallback(
    (value: any) => {
      onValueChange({
        futureOilGasCostIncrease: value,
      });
    },
    [onValueChange],
  );

  return (
    <ParameterStyles.Line
      hasSlider
      style={{ height: userPreferredLanguage === "de" ? "65px" : "30px" }}
    >
      <ParameterStyles.Label hasSlider>{t("OIL AND GAS PRICE INCREASE")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          onChange={onSliderChangeHandler}
          value={configuration.futureOilGasCostIncrease}
          max={20}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <InputValueWrapper>
          <MasterInput
            appearance={{ bgcolor: "#fff" }}
            value={configuration.futureOilGasCostIncrease}
            onChange={onSliderChangeHandler}
            label="%"
          />
        </InputValueWrapper>
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default FuturePriceIncreaseForOilGas;
