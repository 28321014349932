import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ProductFilters, useAppDispatch, useNumberFormat, useWindowDimensions } from "src/hooks";

import {
  BatteryStep2Image,
  HeatpumpStep2Image,
  InverterStep2Image,
  SolarPanelStep2Image,
  WallboxStep2Image,
  EMSImage,
} from "src/assets/images";
import { DeleteIconStep2, ShowOptionsStep2 } from "src/assets/svgs";
import { ProductDB, ProductWithPrice } from "src/db-types";
import { getCheapestProduct, productHasImage } from "src/helpers";
import { InfoIcon } from "src/shared/components";
import { SecondaryButton } from "src/shared/StyledComponents";

import { ProductListing } from "./components";
import {
  BatteryFilters,
  EMSFilters,
  HeatpumpFilters,
  InverterFilters,
  SolarPanelFilters,
  WallboxFilters,
} from "./components/Filters";
import * as S from "./ProductCard.styles";

import { PopupActions } from "src/redux";
import { ProjectComponents } from "src/redux/project";
import { FilterableBaseComponent } from "src/redux/project/types/components";
import { ComponentCosts } from "src/redux/project/types/financial-details";
import {
  PopupSelectors,
  ProductSelectors,
  ProjectSelectors,
  UserSelectors,
} from "src/redux/selectors";
import {
  addBatteryProduct,
  addHeatpumpProduct,
  addInverter,
  addSolarPanel,
  addWallboxProduct,
  addEMSProduct,
} from "src/redux/project/action/action.creators";
import { AdditionalSoftwareFilters } from "./components/Filters/AdditionalSoftware";
import { AdditionalHardwareFilters } from "./components/Filters/AdditionalHardware";

export type Step2ComponentKeys = Exclude<keyof ProjectComponents, "waterHeating" | "cars">;

const getImageFromProductName = (productName: Step2ComponentKeys): string => {
  switch (productName) {
    case "battery":
      return BatteryStep2Image;
    case "heatpump":
      return HeatpumpStep2Image;
    case "inverter":
      return InverterStep2Image;
    case "solarPanel":
      return SolarPanelStep2Image;
    case "wallbox":
      return WallboxStep2Image;
    case "additionalSoftware":
      return BatteryStep2Image;
    case "additionalHardware":
      return BatteryStep2Image;
    case "EMS":
      return EMSImage;
  }
};

const getTitleFromProductName = (productName: Step2ComponentKeys): string => {
  if (productName === "solarPanel") return "Solar Panel";
  else if (productName === "inverter") return "Inverter";
  else if (productName === "battery") return "Battery";
  else if (productName === "heatpump") return "Heatpump";
  else if (productName === "wallbox") return "Wallbox";
  else if (productName === "additionalSoftware") return "Additional Software";
  else if (productName === "additionalHardware") return "Additional Hardware";
  else if (productName === "EMS") return "EMS";

  return "Solar Panel";
};

interface ProductCardProps {
  productName: Step2ComponentKeys;
  onDeleteClick?: () => void;
  standalone?: {
    filteredProducts: ProductWithPrice[];
    productState: FilterableBaseComponent;
    componentCosts: ComponentCosts;
    onPreferenceChange: (filters: ProductFilters) => void;
    isBatteryAdded?: boolean;
  };
  item?: ProductDB | null;
}
export const ProductCard: React.FC<ProductCardProps> = (props: ProductCardProps) => {
  const { productName, onDeleteClick, standalone, item } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showProducts, setShowProducts] = useState(false);
  const popups = useSelector(PopupSelectors.getPopup);

  const productState = useSelector(ProjectSelectors.getComponents)[productName];
  const filtered = useSelector(ProductSelectors.getAllFilteredProducts);
  const projectType = useSelector(ProjectSelectors.getProjectType);
  const {
    costs: { componentCosts },
  } = useSelector(ProjectSelectors.getFinancialDetails);

  const installer = useSelector(UserSelectors.getInstaller);
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const inverterProduct = useSelector(ProjectSelectors.getInverter);
  const wallboxProduct = useSelector(ProjectSelectors.getWallbox);
  const batteryProduct = useSelector(ProjectSelectors.getBattery);
  const heatpumpProduct = useSelector(ProjectSelectors.getHeatpump);
  const EMSProduct = useSelector(ProjectSelectors.getProductEMS);
  const simplify = useSelector(UserSelectors.getSimplify);

  const { formatNumber } = useNumberFormat();
  const { width } = useWindowDimensions();

  const imageSrc = useMemo(() => {
    return getImageFromProductName(productName);
  }, [productName]);

  const generatePriceString = useCallback(
    (price: number) => {
      return `~€${formatNumber(price)}`;
    },
    [formatNumber],
  );

  const getRelaventPrice = useCallback(
    (productName: Step2ComponentKeys) => {
      const {
        solarPanelCost,
        batteryCost,
        heatpumpCost,
        inverterCost,
        wallboxCost,
        additionalSoftwareCost,
        additionalHardwareCost,
        EMSCost,
      } = standalone?.componentCosts ?? componentCosts;

      switch (productName) {
        case "battery":
          return batteryCost;
        case "heatpump":
          return heatpumpCost;
        case "inverter":
          return inverterCost;
        case "wallbox":
          return wallboxCost;
        case "solarPanel":
          return solarPanelCost;
        case "additionalSoftware":
          return additionalSoftwareCost;
        case "additionalHardware":
          return additionalHardwareCost;
        case "EMS":
          return EMSCost;
      }
    },
    [componentCosts, standalone?.componentCosts],
  );

  const Price = useMemo(() => {
    const cost = getRelaventPrice(productName);
    const bulkPriceProduct = item?.bulkPrice?.[`${installer?._id || "ADMIN"}`];
    return !item
      ? generatePriceString(cost)
      : generatePriceString(
          (bulkPriceProduct
            ?.filter((bulkPrice) => Number(bulkPrice.quantity) <= productState.quantity)
            .sort((a, b) => Number(b.quantity) - Number(a.quantity))[0]?.priceb2c ||
            item?.priceB2c) *
            (Number(productState.quantity) || Number(standalone?.productState.quantity)),
        );
  }, [generatePriceString, getRelaventPrice, productName, productState, item, installer]);

  const Subtitle = useMemo(() => {
    const cost = getRelaventPrice(productName);
    if (productName === "solarPanel")
      return `${standalone?.productState.quantity ?? productState.quantity} ${t("Panels")}`;
    else
      return `${standalone?.productState.quantity ?? productState.quantity} x €${formatNumber(
        cost,
      )}`;
  }, [
    formatNumber,
    getRelaventPrice,
    productName,
    productState.quantity,
    standalone?.productState.quantity,
  ]);

  const filteredProducts = useMemo(() => {
    if (standalone?.filteredProducts) {
      return standalone.filteredProducts;
    }

    let products = [];
    switch (productName) {
      case "solarPanel":
        products = filtered.solarPanels;
        break;
      case "battery":
        products = filtered.batteries;
        break;
      case "heatpump":
        products = filtered.heatpumps;
        break;
      case "inverter":
        products = filtered.inverters;
        break;
      case "wallbox":
        products = filtered.wallboxes;
        break;
      case "additionalSoftware":
        products = filtered.additionalSoftware;
        break;
      case "additionalHardware":
        products = filtered.additionalHardware;
        break;
      case "EMS":
        products = filtered.EMS;
        break;
    }
    return products;
  }, [
    filtered.batteries,
    filtered.heatpumps,
    filtered.inverters,
    filtered.solarPanels,
    filtered.wallboxes,
    filtered.additionalSoftware,
    filtered.additionalHardware,
    filtered.EMS,
    productName,
    standalone?.filteredProducts,
  ]);

  const cheapestProduct = useMemo(() => {
    return getCheapestProduct(filteredProducts);
  }, [filteredProducts]);

  const cheapestProductHasImage = productHasImage(cheapestProduct);

  const ShowButton = useMemo(() => {
    return (
      <S.ShowOptionsButtonRow>
        <SecondaryButton onClick={() => setShowProducts(true)}>
          {t("Show Options")} <ShowOptionsStep2 />
        </SecondaryButton>
      </S.ShowOptionsButtonRow>
    );
  }, [t]);

  const HideButton = useMemo(() => {
    return (
      <S.ShowOptionsButtonRow>
        <SecondaryButton onClick={() => setShowProducts(false)}>
          {t("Hide Options")} <ShowOptionsStep2 />
        </SecondaryButton>
      </S.ShowOptionsButtonRow>
    );
  }, [t]);
  const Preferences = useMemo(() => {
    return (
      <S.Preferences>
        {productName === "solarPanel" && (
          <>
            <div className="heading">{!item ? t("Choose your preferences") : item?.name}</div>
            <SolarPanelFilters
              onPreferenceChange={standalone?.onPreferenceChange}
              filteredProducts={standalone?.filteredProducts}
              preferences={standalone?.productState.preferences}
            />
          </>
        )}
        {productName === "inverter" && (
          <>
            <div className="heading">{!item ? t("Choose your preferences") : item?.name}</div>
            <InverterFilters
              onPreferenceChange={standalone?.onPreferenceChange}
              filteredProducts={standalone?.filteredProducts}
              preferences={standalone?.productState.preferences}
              isBatteryAdded={standalone?.isBatteryAdded}
            />
          </>
        )}
        {productName === "battery" && (
          <>
            <div className="heading">{!item ? t("Choose your preferences") : item?.name}</div>
            <BatteryFilters
              onPreferenceChange={standalone?.onPreferenceChange}
              filteredProducts={standalone?.filteredProducts}
              preferences={standalone?.productState.preferences}
            />
          </>
        )}
        {productName === "wallbox" && (
          <>
            <div className="heading">{!item ? t("Choose your preferences") : item?.name}</div>

            <WallboxFilters
              onPreferenceChange={standalone?.onPreferenceChange}
              filteredProducts={standalone?.filteredProducts}
              preferences={standalone?.productState.preferences}
            />
          </>
        )}
        {productName === "heatpump" && (
          <>
            <div className="heading">{!item ? t("Choose your preferences") : item?.name}</div>

            <HeatpumpFilters
              onPreferenceChange={standalone?.onPreferenceChange}
              filteredProducts={standalone?.filteredProducts}
              preferences={standalone?.productState.preferences}
            />
          </>
        )}
        {productName === "additionalSoftware" && (
          <>
            <div className="heading">{!item ? t("Choose your product") : item?.name}</div>
            <AdditionalSoftwareFilters
              onPreferenceChange={standalone?.onPreferenceChange}
              filteredProducts={standalone?.filteredProducts}
              preferences={standalone?.productState.preferences}
            />
          </>
        )}
        {productName === "additionalHardware" && (
          <>
            <div className="heading">{!item ? t("Choose your product") : item?.name}</div>
            <AdditionalHardwareFilters
              onPreferenceChange={standalone?.onPreferenceChange}
              filteredProducts={standalone?.filteredProducts}
              preferences={standalone?.productState.preferences}
            />
          </>
        )}
        {productName === "EMS" && (
          <>
            <div className="heading">{!item ? t("Choose your product") : item?.name}</div>
            <EMSFilters
              onPreferenceChange={standalone?.onPreferenceChange}
              filteredProducts={standalone?.filteredProducts}
              preferences={standalone?.productState.preferences}
            />
          </>
        )}
      </S.Preferences>
    );
  }, [
    productName,
    standalone?.filteredProducts,
    standalone?.isBatteryAdded,
    standalone?.onPreferenceChange,
    standalone?.productState.preferences,
    t,
    item,
  ]);

  useEffect(() => {
    if (installer?._id) {
      if (productName === "solarPanel") {
        if (!solarPanelProduct?.item) {
          const standardProducts = filteredProducts.filter(
            (product) => product.installerAccess?.[installer?._id]?.isStandard,
          );
          dispatch(addSolarPanel(standardProducts?.[0] || filteredProducts[0]));
        }
      }
      if (productName === "inverter") {
        if (!inverterProduct?.item) {
          const standardProducts = filteredProducts.filter(
            (product) => product.installerAccess?.[installer?._id]?.isStandard,
          );
          dispatch(addInverter(standardProducts[0] || filteredProducts[0]));
        }
      }
      if (productName === "battery") {
        if (!batteryProduct?.item) {
          const standardProducts = filteredProducts.filter(
            (product) => product.installerAccess?.[installer?._id]?.isStandard,
          );
          dispatch(addBatteryProduct(standardProducts[0] || filteredProducts[0]));
        }
      }
      if (productName === "wallbox") {
        if (!wallboxProduct?.item) {
          const standardProducts = filteredProducts.filter(
            (product) => product.installerAccess?.[installer?._id]?.isStandard,
          );
          dispatch(addWallboxProduct(standardProducts[0] || filteredProducts[0]));
        }
      }
      if (productName === "heatpump") {
        if (!heatpumpProduct?.item) {
          const standardProducts = filteredProducts.filter(
            (product) => product.installerAccess?.[installer?._id]?.isStandard,
          );
          dispatch(addHeatpumpProduct(standardProducts[0] || filteredProducts[0]));
        }
      }
      if (productName === "EMS") {
        if (!EMSProduct?.item) {
          const standardProducts = filteredProducts.filter(
            (product) => product.installerAccess?.[installer?._id]?.isStandard,
          );
          dispatch(addEMSProduct(standardProducts[0] || filteredProducts[0]));
        }
      }
    }
  }, [
    installer,
    productName,
    solarPanelProduct,
    inverterProduct,
    wallboxProduct,
    batteryProduct,
    heatpumpProduct,
    EMSProduct,
  ]);

  return (
    <>
      <S.ProductHeaderCard>
        <S.ConfigurationRow>
          <S.ImageAndTitle>
            <div className="heading">
              <span>{t(getTitleFromProductName(productName))}</span>
              <InfoIcon
                onClick={() => {
                  dispatch(
                    PopupActions.openProductInfoPopup({
                      type: productName,
                    }),
                  );
                }}
              />
            </div>
            <div className="image-div">
              {item && <img src={item?.imgUrls?.[0]} />}
              {cheapestProductHasImage && !item && <img src={cheapestProduct?.imgUrls?.[0]} />}
              {!cheapestProductHasImage && !item && <img src={imageSrc} className="image" />}
            </div>
          </S.ImageAndTitle>
          {width > 700 && Preferences}
          <S.PriceBox>
            {onDeleteClick && (
              <button className="delete-button" onClick={onDeleteClick}>
                <DeleteIconStep2 />
              </button>
            )}
            {((popups.MULTI_TENANT && Price != "~€0") || !popups.MULTI_TENANT) && (
              <div>
                <div className="price">{Price}</div>
                <div className="sub-title">{Subtitle}</div>
              </div>
            )}
          </S.PriceBox>
        </S.ConfigurationRow>
        {width < 700 && Preferences}
        {!simplify ? (showProducts ? HideButton : ShowButton) : ""}
        {showProducts && (
          <ProductListing
            products={filteredProducts}
            qtyToBuy={standalone?.productState.quantity ?? productState.quantity}
            productName={productName}
          />
        )}
        {showProducts && HideButton}
      </S.ProductHeaderCard>
    </>
  );
};
