/* eslint-disable new-cap */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unmodified-loop-condition */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useThree, useFrame } from "@react-three/fiber";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as THREE from "three";

import { RotateIcon } from "src/assets/step33dViwer";

import CustomDeleteCursor from "../assets/step33dViwer/CursorDeleteIcon.svg"

import eventEmitter from "./EventEmitter/EventEmitter.js";
import FlowManager from "./FlowManager.jsx";
import MyCustomTransformControls from "./MyCustomTransformControls.js"
import PanelGrid from "./PanelGrid.js";
import { solarProductionData } from "./PopUp/SolarProductionData.js";

import { ProjectSelectors } from "src/redux/selectors";


const PanelGridPlacement = ({
  modelRef,
  cursorRef,
  controlsRef,
  documentRef,
  cameraRef,
  onSolarDataChange,
  dispatch,
  ProjectActions,
  project,
  canvasRef,
  setIconPosition,
  setDisableRotation,
  mouseDown,
  threejsRef,
  setCablingLoops,
  cablingLoops,
  selectedMode,
  setStatisticsReload
}) => {
  const quoteDetails = useSelector(ProjectSelectors.getQuote);

  if (!documentRef.current.loaded) return;

  const {
    gl, // WebGL renderer
    scene, // Default scene
    camera, // Default camera
    raycaster, // Default raycaster
    size, // Bounds of the view (which stretches 100% and auto-adjusts)
    viewport, // Bounds of the viewport in 3d units + factor (size/viewport)
    aspect, // Aspect ratio (size.width / size.height)
    mouse, // Current, centered, normalized 2D mouse coordinates
    clock, // THREE.Clock (useful for useFrame deltas)
    invalidate, // Invalidates a single frame (for <Canvas invalidateFrameloop />)
    intersect, // Calls onMouseMove handlers for objects underneath the cursor
    setDefaultCamera, // Sets the default camera
  } = useThree();
  const io = {
    raycaster,
    buttons: 0,
  };
  let validHover;
  const renderer = gl;

  const flow = new FlowManager();
  useFrame((state) => flow.updateAll());

  const doc = documentRef.current;

  const panelGrids = doc.panelGrids;
  const editor = doc.editor;

  const terrainGroup = doc.terrainGroup;

  let transformControls;

  // const modules = doc.modules

    /* useEffect(() => {
    // Set up the interval to run every 3 seconds (3000 milliseconds)
    const interval = setInterval(() => {
      console.info("This runs every 3 seconds", isRotating);
      // Add any other logic you want to trigger here
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); */

  function* hoverFlow() {
    while (1) {
      if (!terrainGroup) yield 0;
      const hits = io.raycaster.intersectObjects(terrainGroup.children, true);
      validHover = false;
      const ah = cursorRef.current;
      if (hits.length) {
        ah.visible = true;
        ah.position.copy(hits[0].point);
        ah.lookAt(v.copy(hits[0].normal).multiplyScalar(-1).add(ah.position));
        validHover = true;
      } else ah.visible = false;
      yield 0;
    }
  }

  flow.start(hoverFlow);

  const { modules, deleteObject, plusObject, dimentions } = doc;
  let gridFaceUp = 0;

  PanelGrid.prototype.rebuildGrid = function () {
    // this === editingPanelGrid && updateBoxFrame(editingPanelGrid);

    this.disposeOccupancy();

    this.recomputeOccupancy({
      gridFaceUp,
      targets: terrainGroup.children,
    });
    this.redrawOccupancy(scene);
  };

  const getPosition = (matrix, index) => {
    let row = 0;
    while (index >= matrix[row].length) {
      index -= matrix[row].length;
      row++;
    }
    const col = index;
    return({row, col});
  };

  const updatePanelMatrix = () => {
    if(documentRef.current.scaleArrows) {
      const lineIndex = getLineIndex();
      if(lineIndex !== undefined) {
        documentRef.current.lastLineIndex = lineIndex;
      }
    };
    const panelGrid = documentRef.current.activeGrid;
    if(!panelGrid.panelMatrix) {
      panelGrid.panelMatrix = panelGrid.occupancy.panelMatrix;
      return;
    }

    switch(documentRef.current.lastLineIndex) {
      case 0:
        if(panelGrid.panelMatrix.length === 0) {return;}
        while(panelGrid.panelMatrix[0].length > panelGrid.occupancy.panelMatrix[0].length) {
          panelGrid.panelMatrix.forEach((row) => {
            row.shift();
          });
        }
        while(panelGrid.panelMatrix[0].length < panelGrid.occupancy.panelMatrix[0].length) {
          panelGrid.panelMatrix.forEach((row) => {
            row.unshift({obstructed: false, enabled: true});
          });
        }
        break;
      case 1:
        if(panelGrid.panelMatrix.length === 0) {return;}
        while(panelGrid.panelMatrix[0].length > panelGrid.occupancy.panelMatrix[0].length) {
          panelGrid.panelMatrix.forEach((row) => {
            row.pop();
          });
        }
        while(panelGrid.panelMatrix[0].length < panelGrid.occupancy.panelMatrix[0].length) {
          panelGrid.panelMatrix.forEach((row) => {
            row.push({obstructed: false, enabled: true});
          });
        }
        break;
      case 2:
        while(panelGrid.panelMatrix.length > panelGrid.occupancy.panelMatrix.length) {
          panelGrid.panelMatrix.shift();
        }
        while(panelGrid.panelMatrix.length < panelGrid.occupancy.panelMatrix.length) {
          const row = [];
          panelGrid.occupancy.panelMatrix[0].forEach(() => {
            row.push({obstructed: false, enabled: true});
          })
          panelGrid.panelMatrix.unshift(row);
        }
        break;
      case 3:
        while(panelGrid.panelMatrix.length > panelGrid.occupancy.panelMatrix.length) {
          panelGrid.panelMatrix.pop();
        }
        while(panelGrid.panelMatrix.length < panelGrid.occupancy.panelMatrix.length) {
          const row = [];
          panelGrid.occupancy.panelMatrix[0].forEach(() => {
            row.push({obstructed: false, enabled: true});
          })
          panelGrid.panelMatrix.push(row);
        }
        break;
      default:
        break;
    } 

    panelGrid.occupancy.panelMatrix.map((row, rowIndex) => {
      row.map((col, colIndex) => {
        panelGrid.panelMatrix[rowIndex][colIndex].obstructed = col.obstructed;
      });
    });
  }

  const updatePanelMatrixEnabled = (panel) => {
    const panelGrid = documentRef.current.activeGrid;
    const panelIndex = documentRef.current.activeGrid.children.findIndex(child => child.uuid === panel.uuid);
    const panelPosition = getPosition(panelGrid.panelMatrix, panelIndex - 1);
    panelGrid.panelMatrix[panelPosition.row][panelPosition.col].enabled = !panel.isNotVisible;
  }

  const createPanelGrid = () => {
    const ah = cursorRef.current;
    const pg = new PanelGrid({
      scene,
      modules,
      deleteObject,
      plusObject,
      dimentions,
      documentRef
    });
    pg.up = pg.up.clone();
    ah.matrix.decompose(pg.position, pg.quaternion, pg.scale);
    pg.updateMatrix();
    scene.add(pg);
    return pg;
  };

  const mouseOver = () => {
    if (panelLayoutActive) {
      const hits = io.raycaster.intersectObjects(
        panelGrids.map((pg) => pg.selectionMesh),
        false,
      );

      threejsRef.current.style.cursor = "crosshair";

      if(!documentRef.current.activeGrid) {
        if (hits.length) {
          threejsRef.current.style.cursor = "pointer";
        }
        return;
      }

      threejsRef.current.style.cursor = "default";

      const boxFrame = documentRef.current.boxFrame;
      const boxFrameHits = io.raycaster.intersectObjects(boxFrame.children, true);
      if(selectedMode === "Grid move mode") {
        resetHighlightScale();
      }
      if(selectedMode === "Grid move mode" && boxFrameHits.length > 0) {
        const boxFrameLines = documentRef.current.boxFrame.children;
        lineIndex = boxFrameLines.findIndex((line) => line.uuid === boxFrameHits[0].object.uuid);
        highlightScale(lineIndex);
        // threejsRef.current.style.cursor = "col-resize";
        return;
      }

      if(documentRef.current.scaleArrows) {
        const scaleArrowsChildren = documentRef.current.scaleArrows.children;
        const scaleArrows = scaleArrowsChildren.filter((child) => 
          child.name === "scale-arrow-one" ||
          child.name === "scale-arrow-two" ||
          child.name === "scale-arrow-three" ||
          child.name === "scale-arrow-four"
        );
        const scaleArrowHits = io.raycaster.intersectObjects(scaleArrows, true);
  
        if(selectedMode === "Grid move mode" && scaleArrowHits.length > 0) {
          switch(scaleArrowHits[0].object.name) {
            case "scale-arrow-one":
              highlightScale(0);
              break;
            case "scale-arrow-two":
              highlightScale(3);
              break;
            case "scale-arrow-three":
              highlightScale(1);
              break;
            case "scale-arrow-four":
              highlightScale(2);
              break;
            default:
              console.error("Scale arrow not found.");
          } 
          return;
        }
      }

      const rotateIcon = documentRef.current.activeGrid.children.filter(child => child.name === "rotate-icon");
      const iconHits = io.raycaster.intersectObjects(rotateIcon, true);
      if(iconHits.length > 0) {
        threejsRef.current.style.cursor = "grab";
        return;
      }
  
      if (hits.length) {
        const clickedGrid = panelGrids.find((pg) => pg.selectionMesh.id === hits[0].object.id);
        doc.selectedPanelGrid = clickedGrid;
        const childHits = io.raycaster.intersectObjects(documentRef.current.activeGrid.children.slice(1), true);

        if(selectedMode === "Grid move mode" && documentRef.current.activeGrid === clickedGrid) {
          threejsRef.current.style.cursor = "all-scroll";
          return;
        }

        if(documentRef.current.activeGrid !== clickedGrid) {
          if (hits.length) {
            threejsRef.current.style.cursor = "pointer";
          }
          return;
        }

        // Exit if not in Panel edit mode
        if(!(selectedMode === "Panel edit mode")) {
          return;
        }

        // panelGrids.forEach((pg) => {
          documentRef.current.activeGrid.children.forEach((child) => {
            if (child.name === "supported") {
              if(child.isNotVisible) {
                setPanelMaterial(child, deletedMaterial);
              }else {
                setPanelMaterial(child);
              }
            }
        /*     if (child.name === "supported") {
              child.children.forEach((c) => {
                if (c.name === "deleteIcon") {
                  c.visible = false;
                }
              });
            } */
          });
        // });
  
        if (childHits.length) {
          const intersectedPanel = childHits[0].object.parent.parent;

          if (intersectedPanel.name === "supported") {
            if(intersectedPanel.isNotVisible) {
              setPanelMaterial(intersectedPanel, restoreMaterial);
            }else {
              setPanelMaterial(intersectedPanel, deleteMaterial);
            }
          }
  
      /*     const addIconVisible = intersectedPanel.children.some((c) => c.name === "add-icon" && c.visible);
  
          intersectedPanel.children.forEach((c) => {
            if (c.name === "deleteIcon" && !addIconVisible) { 
              c.visible = true;
            }
          }); */
  
          if (intersectedPanel) {
            if(intersectedPanel.isNotVisible) {
              threejsRef.current.style.cursor = "copy";
            }else {
              threejsRef.current.style.cursor = "url(" + CustomDeleteCursor + "), auto";
            }
          }
  
          controlsRef.enabled = true;
          inDrag = false;
          controlsRef.current.enabled = true;
          controlsRef.current.update();
        }
      } else {
        panelGrids.forEach((pg) => {
          pg.children.forEach((child) => {
            if (child.name === "supported") {
              if(child.isNotVisible) {
                setPanelMaterial(child, deletedMaterial);
              }else {
                setPanelMaterial(child);
              }
            }
          /*   if (child.name === "supported") {
              child.children.forEach((c) => {
                if (c.name === "deleteIcon") {
                  c.visible = false;
                }
              });
            } */
          });
        });
      }
    }
  };
  
  function* dragFlow() {
    inDrag = true;
    const ah = cursorRef.current;
    dragStart = ah.position.clone();
    dragEnd = dragStart.clone();

    const firstClick = dragStart.clone();

    while (io.buttons && !escapePressed) {
        dragStart.copy(ah.position);

        if (firstClick.distanceTo(dragStart) > 1) {
            abortAction();
            inDrag = false;
            controlsRef.current.enabled = true;
            controlsRef.current.update();
            return;
        }

        p0.copy(ah.position);
        yield 0;
    }

    if (escapePressed) {
        abortAction();
        inDrag = false;
        controlsRef.current.enabled = true;
        controlsRef.current.update();
        return;
    }
    const hits = io.raycaster.intersectObjects(
        panelGrids.map((pg) => pg.selectionMesh),
        false,
    );

    if(documentRef.current.activeGrid && !hits.length) {
      documentRef.current.resetActive();
      threejsRef.current.style.cursor = "crosshair";
      inDrag = false;
      controlsRef.current.enabled = true;
      controlsRef.current.update();
      return;
    }

    if (hits.length) {
        const clickedGrid = panelGrids.find((pg) => pg.selectionMesh.id === hits[0].object.id);
        doc.selectedPanelGrid = clickedGrid;
        const childHits = io.raycaster.intersectObjects(clickedGrid.children.slice(1), true);

        if (childHits.length) {
          const clickedObject = childHits[0].object.parent.parent;

          if(documentRef.current.activeGrid === clickedGrid) {
            // updateBoxFrame(panelGrid)

            // Exit if not in Panel edit mode
            if(!(selectedMode === "Panel edit mode")) {
              return;
            }

            clickedObject.isNotVisible = !clickedObject.isNotVisible;
            if(clickedObject.isNotVisible) {
              updatePanelMatrixEnabled(clickedObject);
              setPanelMaterial(clickedObject, restoreMaterial);
              threejsRef.current.style.cursor = "copy";
            }else {
              updatePanelMatrixEnabled(clickedObject);
              setPanelMaterial(clickedObject, deleteMaterial);
              threejsRef.current.style.cursor = "url(" + CustomDeleteCursor + "), auto";
            }
          }

          /*   if (clickedObject.name === "deleteIcon" || clickedObject.name === "add-icon") {
                controlsRef.current.enabled = true;
                inDrag = false;

                if (clickedObject.name === "deleteIcon") {
                    const module = clickedObject;
                    const iconVisible = module.parent.children.find((el) => el.name === "add-icon");

                    setParentInvisibleExceptTarget(module.parent, ["add-icon"]);

                    iconVisible.visible = true;
                    module.visible = false;
                    module.userData.originalRaycast = module.raycast;
                    module.raycast = () => {};

                    const fetchData = async () => {
                        const data = await solarProductionData(panelGrids, quoteDetails, []);
                        onSolarDataChange(data);
                    };
                    setTimeout(() => {
                        fetchData();
                    }, 500);

                } else if (clickedObject.name === "add-icon") {
                    const module = clickedObject;

                    restoreParentMaterial(module.parent);
                    const iconVisible = module.parent.children.find((el) => el.name === "deleteIcon");
                    iconVisible.visible = true;
                    iconVisible.raycast = iconVisible.userData.originalRaycast;
                    delete iconVisible.userData.originalRaycast;
                    module.parent.visible = true;
                    module.visible = false;

                    const fetchData = async () => {
                        const data = await solarProductionData(panelGrids, quoteDetails, []);
                        onSolarDataChange(data);
                    };
                    setTimeout(() => {
                        fetchData();
                    }, 200);
                }
                return;
            } */

            if (panelGrid !== clickedGrid) {
                panelGrid = clickedGrid;
                // updateBoxFrame(panelGrid)
             
            }
          
            controlsRef.current.enabled = true;
        }
        documentRef.current.setActive(clickedGrid);
        inDrag = false;
        controlsRef.current.enabled = true;
        controlsRef.current.update();

        if(selectedMode === "Grid move mode" && documentRef.current.activeGrid === clickedGrid) {
          threejsRef.current.style.cursor = "all-scroll";
          return;
        }

        if(selectedMode === "Panel edit mode" && childHits.length && documentRef.current.activeGrid === clickedGrid) {
          const clickedObject = childHits[0].object.parent.parent;
          if(clickedObject.isNotVisible) {
            setPanelMaterial(clickedObject, restoreMaterial);
            threejsRef.current.style.cursor = "copy";
            setStatisticsReload(Math.random());
          }else {
            setPanelMaterial(clickedObject, deleteMaterial);
            threejsRef.current.style.cursor = "url(" + CustomDeleteCursor + "), auto";
            setStatisticsReload(Math.random());
          }
        }

        return;
    }

    const restoreIconHits = io.raycaster.intersectObjects(
        scene.children.filter((child) => child.name === "restoreIcon"),
        true,
    );

    function makeAllChildrenVisible(element) {
        element.visible = true;
        if (element.children && element.children.length > 0) {
            element.children.forEach((child) => {
                makeAllChildrenVisible(child);
            });
        }
    }

    if (restoreIconHits.length) {
        const iconMesh = restoreIconHits[0].object;
        const clickedGrid = iconMesh.userData.clickedGrid;

        clickedGrid.children.forEach((child) => {
            makeAllChildrenVisible(child);
        });

        scene.remove(iconMesh);
        delete clickedGrid.restoreIcon;

        controlsRef.current.enabled = true;
        inDrag = false;
        controlsRef.current.enabled = true;
        controlsRef.current.update();
        return;
    }

    controlsRef.current.enabled = false;

    let xlen;
    const ep = 0.0001;

    while (!io.buttons && !escapePressed) {
        p1.copy(ah.position);
        xlen = p0.distanceTo(p1);

        if (!editingPanelGrid) doc.editingPanelGrid = editingPanelGrid = createPanelGrid();
        editingPanelGrid.selectionMesh.scale.set(0.1, 0.1, 0.1);
        if (xlen > ep) {
            editingPanelGrid.position.copy(p0);
            editingPanelGrid.lookAt(v.copy(p1));
            editingPanelGrid.selectionMesh.scale.z = xlen;
            vx.copy(p1).sub(p0).normalize();
        }
        // updateBoxFrame(editingPanelGrid);
        yield 0;
    }

    if (escapePressed || io.buttons !== 1) {
        abortAction();
        inDrag = false;
        controlsRef.current.enabled = true;
        controlsRef.current.update();
        return;
    }

    while (io.buttons && !escapePressed) {
        yield 0;
    }

    while (!io.buttons && !escapePressed) {
        p2.copy(ah.position);

        let ylen = p1.distanceTo(p2);

        if (ylen > ep && xlen > ep) {
            vy.copy(p2).sub(p1);
            const d = vy.dot(vx);
            v.copy(vx).multiplyScalar(d);
            vy.sub(v);
            ylen = vy.length();
            vy.normalize();
            vz.crossVectors(vy, vx).normalize();

            if (editingPanelGrid) {
                editingPanelGrid.up.copy(vy).multiplyScalar(-1);
                const faceUpDot = vz.dot(camera.up);
                gridFaceUp = faceUpDot;
                editingPanelGrid.gridFaceUp = gridFaceUp;

                if (faceUpDot < 0) {
                    vz.multiplyScalar(-1);
                }

                editingPanelGrid.lookAt(v.copy(editingPanelGrid.position).add(vz));
                editingPanelGrid.size.set(xlen, -ylen, 0.1);

                const selectionThickness = 0.2;
                const sscl = editingPanelGrid.selectionMesh.scale.set(
                    faceUpDot < 0 ? -xlen : xlen,
                    -ylen,
                    selectionThickness,
                );
                editingPanelGrid.selectionSize.copy(editingPanelGrid.selectionMesh.scale);
                editingPanelGrid.rebuildGrid();
            }
        }
        yield 0;
    }

    if (escapePressed || io.buttons !== 1) {
        abortAction();
        inDrag = false;
        controlsRef.current.enabled = true;
        controlsRef.current.update();
        return;
    } else {
        if (editingPanelGrid) {
            editingPanelGrid.selectionMesh.material = PanelGrid.deselectedStateMaterial;

            function* animateMeshPopIn({ object, delay, duration, easing }) {
                object.traverse(
                    (e) => e.isMesh && e.originalMaterial && (e.material = e.originalMaterial),
                );
                if (!object.placedPosition) object.placedPosition = object.position.clone();
                if (!object.placedRotation) object.placedRotation = object.rotation.clone();
                if (!object.placedScale) object.placedScale = object.scale.clone();
                flow.tweenVector3({
                    object,
                    start: object.placedScale.clone().multiplyScalar(0.5),
                    end: object.placedScale,
                    value: "scale",
                    delay,
                    duration,
                    easing,
                });
                flow.tweenVector3({
                    object,
                    value: "position",
                    start: object.parent.worldToLocal(
                        object.parent.localToWorld(object.position.clone()).add(new vec3(0, 8, 0)),
                    ),
                    end: object.placedPosition,
                    delay,
                    duration,
                    easing,
                });
                flow.tweenVector3({
                    object,
                    value: "rotation",
                    start: new vec3().copy(object.rotation).add(new vec3(0, Math.PI * 2, 0)),
                    end: object.placedRotation,
                    delay,
                    duration,
                    easing,
                });
                yield;
            }

            editor.document.doCommand({
                name: "addGrid",
                grid: editingPanelGrid,
                do: function () {
                    scene.add(this.grid);
                    for (let i = 1; i < this.grid.children.length; i++) {
                        flow.start(animateMeshPopIn, {
                            object: this.grid.children[i],
                            delay: (300 * i) / this.grid.children.length,
                            duration: 300,
                            easing: flow.OutCubic,
                        });
                    }
                    panelGrids.push(this.grid);

                    const fetchData = async () => {
                        const data = await solarProductionData(panelGrids, quoteDetails, []);
                        onSolarDataChange(data);
                    };

                    setTimeout(() => {
                        fetchData();
                    }, 500);
                },
                undo: function () {
                    const grid = panelGrids.pop();
                    if (grid?.parent) {
                        grid.parent.remove(grid);
                    }
                },
            });
        }
    }

    while (io.buttons) {
      yield 0;
    }
  
    inDrag = false;
    controlsRef.current.enabled = true;
    controlsRef.current.update();

    setTimeout(() => {
      documentRef.current.setActive(editingPanelGrid);
    }, 500);
    // resetSelection();
  }

  
  const mapName = "Untitled";

  let escapePressed = false;
  const vec3 = THREE.Vector3;

  // ---Pointer hover over the map flow
  const v = new vec3();
  const vx = new vec3();
  const vy = new vec3();
  const vz = new vec3();
  const panelLayoutActive = true;
  let panelGrid;
  let editingPanelGrid;
  let dragStart;
  let dragEnd;
  let inDrag;
  const p1 = new vec3();
  const p0 = new vec3();
  const p2 = new vec3();

  const arrowMeshGeometry = new THREE.ConeGeometry(0.6, 1, 32);
  arrowMeshGeometry.translate( 0, 0.05, 0 );
  const lineMeshGeometry = new THREE.CylinderGeometry(0.07, 0.07, 1, 6);
  lineMeshGeometry.rotateX(-Math.PI * 0.5);
  const lineMeshMaterial = new THREE.MeshBasicMaterial({
    color: 0x22d94a,
    depthTest: false,
  });
  const highlightMaterial = new THREE.MeshBasicMaterial({
    color: "yellow",
    depthTest: false,
  });
  const lineTemplate = new THREE.Mesh(lineMeshGeometry, lineMeshMaterial);
  const arrowTemplate = new THREE.Mesh( arrowMeshGeometry, lineMeshMaterial );
  lineTemplate.renderOrder = 5;

  const v0 = new THREE.Vector3();
  const v1 = new THREE.Vector3();
  let boxFrame;
  let scaleArrows;

  useEffect(() => {
    return () => {
      documentRef.current.resetActive();
      documentRef.current.setActive = null;
      documentRef.current.resetActive = null;
    };
  }, []);

  const deleteCables = () => {
    // Remove all entries from cablingLoops where the panel matches the grid to remove
    const updatedCablingLoops = cablingLoops
      .map(
        (loop) =>
          loop.filter((point) => {
            return !documentRef.current.activeGrid.children
              .slice(1)
              .filter((ele) => ele.name)
              .find((e) => e.id === point.panel.id);
          }), // Assuming 'grid.id' is the panel identifier
      )
      .filter((loop) => loop.length > 0); // Remove empty loops

    // Update the state or variable holding cablingLoops
    setCablingLoops(updatedCablingLoops);
  }

  const updateBoxFrame = (fromGrid) => {
    if (!fromGrid) return;
    // let sscl = fromGrid.selectionMesh.scale;
    if (!boxFrame) {
      boxFrame = new THREE.Group();
      for (let i = 0; i < 4; i++) boxFrame.add(lineTemplate.clone());
      boxFrame.name = "box-frame";
      scene.add(boxFrame);
    }

    // if (!fromGrid.boxFrame) {
    //   fromGrid.boxFrame = new THREE.Group();
    //   for (let i = 0; i < 4; i++) fromGrid.boxFrame.add(lineTemplate.clone());
    //   scene.add(fromGrid.boxFrame);
    // }
    // const boxFrame = fromGrid.boxFrame;
    // boxFrame.position.copy(sscl).multiplyScalar(0.5)
    // fromGrid.localToWorld(boxFrame.position);
    // fromGrid.position)
    // boxFrame.rotation.copy(fromGrid.rotation);

    const setLine2 = (line, x1, y1, x2, y2) => {
      v0.set(x1, y1, 0);
      v1.set(x2, y2, 0);
      fromGrid?.selectionMesh.localToWorld(v0);
      fromGrid?.selectionMesh.localToWorld(v1);
      line.position.copy(v0).lerp(v1, 0.5);
      const dist = v0.distanceTo(v1);
      line.lookAt(v1);
      line.scale.z = dist;
    };
    const sx = 1; // sscl.x
    const sy = 1; // sscl.y
    setLine2(boxFrame.children[0], 0, 0, sx, 0);
    setLine2(boxFrame.children[1], 0, sy, sx, sy);
    setLine2(boxFrame.children[2], 0, 0, 0, sy);
    setLine2(boxFrame.children[3], sx, 0, sx, sy);
    // boxFrame.setSize(sscl.x * 0.5, sscl.y * 0.5, sscl.z * 0.5);

    const corners = [];
    corners.push(new THREE.Vector3(0, 0, 0));
    corners.push(new THREE.Vector3(sx, 0, 0));
    corners.push(new THREE.Vector3(sx, sy, 0));
    corners.push(new THREE.Vector3(0, sy, 0));
    corners.forEach((vector) => {
      fromGrid?.selectionMesh.localToWorld(vector);
    });

    boxFrame.cornerWorldCoordinates = corners;
   
    documentRef.current.boxFrame = boxFrame;
  };
  /*
    useEffect(() => {
      let modelMesh= modelRef.current.scene.children[0];
      modelMesh.raycast = acceleratedRaycast;
      const geometry = modelMesh.geometry;
      geometry.boundsTree = new MeshBVH(geometry, {
        lazyGeneration: false,
      });
    
    }, []);
*/

  const loader = new THREE.TextureLoader();
  const rotateTexture = loader.load(RotateIcon);

  const showRotate = () => {
    const rotateMaterial = new THREE.SpriteMaterial({
      map: rotateTexture,
      color: 0xffffff,
      depthTest: false,
    });
    const rotateIcon = new THREE.Sprite(rotateMaterial);

    // Calculate the panel width and height based on the bounding box dimensions
    rotateIcon.rotation.x = -Math.PI / 2; // Rotate to align with the panel horizontally
    rotateIcon.rotation.y = Math.PI / 2;

    if(documentRef.current.activeGrid.gridFaceUp > 0) {
      rotateIcon.position.set(
        documentRef.current.activeGrid.size.x + 2,
        documentRef.current.activeGrid.size.y / 2,
        0,
      );
    }else {
      rotateIcon.position.set(
        -documentRef.current.activeGrid.size.x - 2,
        documentRef.current.activeGrid.size.y / 2,
        0,
      );
    }

    rotateIcon.scale.set(1.5, 1.5, 1.5);
    rotateIcon.name = "rotate-icon";
    documentRef.current.activeGrid.add(rotateIcon);
  }

  const hideRotate = () => {
    if(!documentRef.current.activeGrid) {return;};
    const rotateIcon = documentRef.current.activeGrid.children.filter(child => child.name === "rotate-icon");
    documentRef.current.activeGrid.remove(rotateIcon[0]);
  }

  const highlightScale = (lineIndex) => {
    const boxFrameLines = documentRef.current.boxFrame.children;
    const scaleArrowsChildren = documentRef.current.scaleArrows.children;
    let scaleArrow;
    switch(lineIndex) {
      case 0:
        scaleArrow = scaleArrowsChildren.find((child) => child.name === "scale-arrow-one");
        break;
      case 1:
        scaleArrow = scaleArrowsChildren.find((child) => child.name === "scale-arrow-three");
        break;
      case 2:
        scaleArrow = scaleArrowsChildren.find((child) => child.name === "scale-arrow-four");
        break;
      case 3:
        scaleArrow = scaleArrowsChildren.find((child) => child.name === "scale-arrow-two");
        break;
      default:
        console.error("Line index fetch failed.");
    } 

    boxFrameLines[lineIndex].material = highlightMaterial;
    scaleArrow.material = highlightMaterial;
  }

  const resetHighlightScale = () => {
    const boxFrameLines = documentRef.current.boxFrame.children;
    boxFrameLines.forEach((line) => {
      line.material = lineMeshMaterial;
    });

    const scaleArrowsChildren = documentRef.current.scaleArrows.children;

    scaleArrowsChildren[0].material = lineMeshMaterial;
    scaleArrowsChildren[1].material = lineMeshMaterial;
    scaleArrowsChildren[2].material = lineMeshMaterial;
    scaleArrowsChildren[3].material = lineMeshMaterial;
  }

  const showScale = (fromGrid, lineIndex) => {
    if (!fromGrid) return;
    if (!scaleArrows) {
      scaleArrows = new THREE.Group();
      for (let i = 0; i < 4; i++) scaleArrows.add(arrowTemplate.clone());
      scaleArrows.name = "scale-arrows";
      scene.add(scaleArrows);
    }

    const ySpace = 1 / documentRef.current.activeGrid.scale.y * 1;
    const xSpace = 1 / documentRef.current.activeGrid.scale.x * 1;

    if(fromGrid.gridFaceUp > 0) {
      if(lineIndex === undefined || lineIndex === 1 || lineIndex === 3) {
        scaleArrows.children[0].position.set(
          fromGrid.size.x / 2,
          ySpace,
          0,
        );
        scaleArrows.children[1].position.set(
          fromGrid.size.x + xSpace,
          fromGrid.size.y / 2,
          0,
        );
        scaleArrows.children[2].position.set(
          fromGrid.size.x / 2,
          fromGrid.size.y - ySpace,
          0,
        );
        scaleArrows.children[3].position.set(
          -xSpace,
          fromGrid.size.y / 2,
          0,
        );
      }else {
        scaleArrows.children[0].position.set(
          -fromGrid.size.x / 2,
          -fromGrid.size.y + ySpace,
          0,
        );
        scaleArrows.children[1].position.set(
          xSpace,
          -fromGrid.size.y / 2,
          0,
        );
        scaleArrows.children[2].position.set(
          -fromGrid.size.x / 2,
          -ySpace,
          0,
        );
        scaleArrows.children[3].position.set(
          -fromGrid.size.x - xSpace,
          -fromGrid.size.y / 2,
          0,
        );
      }
  
      scaleArrows.children[0].rotation.set(0, 0, 0);
      scaleArrows.children[1].rotation.set(0, 0, -Math.PI / 2);
      scaleArrows.children[2].rotation.set(0, 0, -Math.PI);
      scaleArrows.children[3].rotation.set(0, 0, Math.PI / 2);
    }else {
      if(lineIndex === undefined || lineIndex === 1 || lineIndex === 3) {
        scaleArrows.children[0].position.set(
          -fromGrid.size.x / 2,
          ySpace,
          0,
        );
        scaleArrows.children[1].position.set(
          -fromGrid.size.x - xSpace,
          fromGrid.size.y / 2,
          0,
        );
        scaleArrows.children[2].position.set(
          -fromGrid.size.x / 2,
          fromGrid.size.y - ySpace,
          0,
        );
        scaleArrows.children[3].position.set(
          xSpace,
          fromGrid.size.y / 2,
          0,
        );
      }else {
        scaleArrows.children[0].position.set(
          fromGrid.size.x / 2,
          -fromGrid.size.y + ySpace,
          0,
        );
        scaleArrows.children[1].position.set(
          -xSpace,
          -fromGrid.size.y / 2,
          0,
        );
        scaleArrows.children[2].position.set(
          fromGrid.size.x / 2,
          -ySpace,
          0,
        );
        scaleArrows.children[3].position.set(
          fromGrid.size.x + xSpace,
          -fromGrid.size.y / 2,
          0,
        );
      }

      scaleArrows.children[0].rotation.set(0, 0, 0);
      scaleArrows.children[1].rotation.set(0, 0, Math.PI / 2);
      scaleArrows.children[2].rotation.set(0, 0, -Math.PI);
      scaleArrows.children[3].rotation.set(0, 0, -Math.PI / 2);
    }

    scaleArrows.children[0].name = "scale-arrow-one";
    scaleArrows.children[1].name = "scale-arrow-two";
    scaleArrows.children[2].name = "scale-arrow-three";
    scaleArrows.children[3].name = "scale-arrow-four";

    fromGrid.localToWorld(scaleArrows.children[0]);
    fromGrid.localToWorld(scaleArrows.children[1]);
    fromGrid.localToWorld(scaleArrows.children[2]);
    fromGrid.localToWorld(scaleArrows.children[3]);

    scaleArrows.children[0].scale.set(1, 1, 1);
    scaleArrows.children[1].scale.set(1, 1, 1);
    scaleArrows.children[2].scale.set(1, 1, 1);
    scaleArrows.children[3].scale.set(1, 1, 1);

    documentRef.current.scaleArrows = scaleArrows;
  }

  const hideScale = () => {
    let arrowsToReset =  documentRef.current.scaleArrows;
    if (arrowsToReset) {
      arrowsToReset?.parent?.remove(scaleArrows);
      arrowsToReset = null;
      documentRef.current.scaleArrows = null;
      scaleArrows = null;
    }
  }

  const transparentMaterial = new THREE.MeshBasicMaterial({
    opacity: 0.1,
    transparent: true,
    // color: "red",
  });
  function setParentInvisibleExceptTarget(parent, targetNames) {
    parent.traverse((child) => {
      if (child.isMesh) {
        if (targetNames.includes(child.name)) {
          // Restore original material for target children

          if (child.userData.originalMaterial) {
            child.material = child.userData.originalMaterial;
          }
          child.visible = true;
        } else {
          // Set transparent material for non-target children

          child.userData.originalMaterial = child.material;
          child.material = transparentMaterial;
          child.visible = false; // Optionally set to false to hide it completely
        }
      }
    });
    parent.isNotVisible = true;
  }

  function restoreParentMaterial(parent) {
    parent.traverse((child) => {
      if (child.isMesh) {
        if (child.userData.originalMaterial) {
          child.material = child.userData.originalMaterial; // Restore material
          delete child.userData.originalMaterial; // Clean up after restoration
        } else {
          console.warn("Original material not found for child:", child); // Fallback warning
        }
        child.visible = true; // Ensure the child is visible
      }
    });
    parent.visible = true; // Ensure the parent is visible
    parent.isNotVisible = false; // Reset any custom flags
    console.log("Parent material restored and made visible:", parent); // Log the parent for verification
  }
  // const toJSON = documentRef.current.toJSON;

  const getLineIndex = () => {
    if(!documentRef.current.boxFrame) {return;}
    const boxFrameLines = documentRef.current.boxFrame.children;

    const scaleArrowsChildren = documentRef.current.scaleArrows.children;
    const scaleArrows = scaleArrowsChildren.filter((child) => 
      child.name === "scale-arrow-one" ||
      child.name === "scale-arrow-two" ||
      child.name === "scale-arrow-three" ||
      child.name === "scale-arrow-four"
    );

    let returnLineIndex;
    let intersect = io.raycaster.intersectObjects(scaleArrows, true);
    if(intersect.length > 0) {
      switch(intersect[0].object.name) {
        case "scale-arrow-one":
          returnLineIndex = 0;
            break;
          case "scale-arrow-two":
            returnLineIndex = 3;
            break;
          case "scale-arrow-three":
            returnLineIndex = 1;
            break;
          case "scale-arrow-four":
            returnLineIndex = 2;
            break;
          default:
            console.error("Scale arrow not found.");
        } 
    }
		intersect = io.raycaster.intersectObjects(boxFrameLines, true);
    if(intersect.length > 0) {
      returnLineIndex = boxFrameLines.findIndex((line) => line.uuid === intersect[0].object.uuid);
    }
    return returnLineIndex;
  }

  const refreashPanels = () => {
    documentRef.current.activeGrid.disposeOccupancy();
    documentRef.current.activeGrid.recomputeOccupancy({
      gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
      targets: terrainGroup.children,
    });
    updatePanelMatrix();
    documentRef.current.activeGrid.redrawOccupancy(scene);
    updatePanelMatrix();
    hideRotate();
    showRotate();
    // setStatisticsReload(Math.random());
  }

  const setActive = (grid) => {
    hideRotate();
    documentRef.current.activeGrid = grid;
    
    documentRef.current.updateBoxFrame(documentRef.current.activeGrid);
    updatePanelMatrix();

    if(documentRef.current.selectedMode === "Grid move mode") {
      showRotate();
      documentRef.current.showScale(documentRef.current.activeGrid)

      removeControls();
      const controls = new MyCustomTransformControls(camera, renderer.domElement, getLineIndex, documentRef, refreashPanels);
      controls.attach(documentRef.current.activeGrid);
      scene.add(controls);
      transformControls = controls;
    }
  }

  const resetActive = () => {
    hideRotate();
    removeControls();

    documentRef.current.activeGrid = null;
    let boxFrameToReset =  documentRef.current.boxFrame;
    if (boxFrameToReset) {
      boxFrameToReset?.parent?.remove(boxFrame);
      boxFrameToReset = null;
      documentRef.current.boxFrame = null;
      boxFrame = null;
    }

    let arrowsToReset =  documentRef.current.scaleArrows;
    if (arrowsToReset) {
      arrowsToReset?.parent?.remove(scaleArrows);
      arrowsToReset = null;
      documentRef.current.scaleArrows = null;
      scaleArrows = null;
    }
  }

  if(!documentRef.current.setActive) {
    documentRef.current.setActive = setActive;
    documentRef.current.resetActive = resetActive;
    documentRef.current.updateBoxFrame = updateBoxFrame;
    documentRef.current.showScale = showScale;
    documentRef.current.hideScale = hideScale;
  }

  const resetSelection = () => {
    controlsRef.current.enabled = true;
    inDrag = false;
    /* if (boxFrame) {
      boxFrame?.parent?.remove(boxFrame);
      boxFrame = null;
    } */
    editingPanelGrid = null;
  };

  documentRef.current.resetSelection = resetSelection;

  const abortAction = () => {
    if (editingPanelGrid) {
      editingPanelGrid.disposeOccupancy();
      editingPanelGrid.parent.remove(editingPanelGrid);
      editingPanelGrid.selectionMesh && editingPanelGrid.remove(editingPanelGrid.selectionMesh);
    }
    resetSelection();

    escapePressed = false;
  };

  const onKeyDown = (e) => {
    if (e.key === "Escape") {
      escapePressed = true;
      abortAction();
    }
    if (e.ctrlKey) {
      if (e.code === "KeyS") {
        dispatch(
          ProjectActions.updateProject({
            threeDObjDetails: {
              ...project.threeDObjDetails,
              panelPlacement: JSON.stringify(doc.toJSON()),
            },
          }),
        );
        // localStorage["panelLayout_" + mapName] = JSON.stringify(doc.toJSON());
        e.preventDefault();
        e.stopPropagation();
      }
      if (e.code === "KeyO") {
        // console.log(localStorage["panelLayout_" + mapName]);

        doc.fromJSON(project?.threeDObjDetails?.panelPlacement);
        e.preventDefault();
        e.stopPropagation();
        return true;
      }
      if (e.code === "KeyY") {
        editor.document.redo();
      }
      if (e.code === "KeyZ") {
        if (e.shiftKey) editor.document.redo();
        else editor.document.undo();
      }
    } else {
      if (e.code === "Delete") {
        const gridIndex = panelGrids.findIndex((pg) => pg.id === panelGrid.id);

        if (gridIndex !== -1) {
          const grid = panelGrids[gridIndex];
          removePanelGrid(grid);
          grid.parent?.remove(grid);
          if (boxFrame?.parent) {
            boxFrame.parent.remove(boxFrame);
          }

          // const savedLayouts = JSON.parse(project?.threeDObjDetails?.panelPlacement);
          // console.log("Before removing layout:", savedLayouts);
          // savedLayouts.splice(gridIndex, 1);
          // console.log("After removing layout:", savedLayouts);

          // dispatch(
          //   ProjectActions.updateProject({
          //     threeDObjDetails: {
          //       ...project.threeDObjDetails,
          //       panelPlacement: JSON.stringify(savedLayouts),
          //     },
          //   }),
          // );

          const fetchData = async () => {
            const data = await solarProductionData(panelGrids, quoteDetails, []);
            onSolarDataChange(data);
            // console.log("Current panelGrids length:", panelGrids.length);
          };

          setTimeout(() => {
            fetchData();
          }, 500);
        }
      }
    }
  };
  const contextMenuAbort = (event) => {
    if (!panelLayoutActive) return;
    event.preventDefault();
  };

  const onPointerUp = (e) => {
    if (!panelLayoutActive) return;
    if (e.target !== renderer.domElement) return;

    io.buttons = e.buttons;

    e.preventDefault();
    e.stopPropagation();
  };

  const onPointerDown = (e) => {
    if (!panelLayoutActive) return;
    if (e.target !== renderer.domElement) return;

    io.buttons = e.buttons;
    if (e.buttons !== 1) validHover = false;
    
    if(documentRef.current.activeGrid) {
      const rotateIcon = documentRef.current.activeGrid.children.filter(child => child.name === "rotate-icon");
      const iconHits = io.raycaster.intersectObjects(rotateIcon, true)
      if(iconHits.length > 0) {
        handlePanelRotate(e);
        return;
      }

      const boxFrame = documentRef.current.boxFrame;
      const boxFrameHits = io.raycaster.intersectObjects(boxFrame.children, true);
      if(selectedMode === "Grid move mode" && boxFrameHits.length > 0) {
        const boxFrameLines = documentRef.current.boxFrame.children;
        lineIndex = boxFrameLines.findIndex((line) => line.uuid === boxFrameHits[0].object.uuid);
        handleEdgeDown(e, lineIndex);
        return;
      }

      if(documentRef.current.scaleArrows) {
        const scaleArrowsChildren = documentRef.current.scaleArrows.children;
        const scaleArrows = scaleArrowsChildren.filter((child) => 
          child.name === "scale-arrow-one" ||
          child.name === "scale-arrow-two" ||
          child.name === "scale-arrow-three" ||
          child.name === "scale-arrow-four"
        );
        const scaleArrowHits = io.raycaster.intersectObjects(scaleArrows, true);
  
        if(selectedMode === "Grid move mode" && scaleArrowHits.length > 0) {
          switch(scaleArrowHits[0].object.name) {
            case "scale-arrow-one":
              handleEdgeDown(e, 0);
              break;
            case "scale-arrow-two":
              handleEdgeDown(e, 3);
              break;
            case "scale-arrow-three":
              handleEdgeDown(e, 1);
              break;
            case "scale-arrow-four":
              handleEdgeDown(e, 2);
              break;
            default:
              console.error("Scale arrow not found.");
          } 
          return;
        }
      }
      
      const hits = io.raycaster.intersectObjects(documentRef.current.activeGrid.children, true);
      if (hits.length && selectedMode === "Grid move mode") {
        handleMoveDown(e);
        return;
      }
    }

    if (validHover) {
      if (!inDrag) flow.start(dragFlow);
      return true;
    }
  };

  const handleUndoRedo = () => {
    editor.document.undo();
    const fetchData = async () => {
      const data = await solarProductionData(panelGrids, quoteDetails, []);

      onSolarDataChange(data);
    };

    setTimeout(() => {
      fetchData();
    }, 200);
  };


  const removePanelGrid = documentRef.current.removePanelGrid;

  let isRotating = false;

  const handlePanelRotate = (event) => {
    deleteCables();
    isRotating = true;
    resetSelection();
    canvasRef.current.addEventListener("mousemove", handleRotateMouseMove);
    canvasRef.current.addEventListener("mouseup", handleRotateMouseUp);
    controlsRef.current.enabled = false;
    controlsRef.current.update();
    setDisableRotation(true)
  };

  const calculateAngle = (A, B, C) => {
  
    // Vector components for AB and BC
    const ABx = A.x - B.x;
    const ABy = A.y - B.y;
    const BCx = C.x - B.x;
    const BCy = C.y - B.y;
  
    // Compute the cross and dot products
    const dotProduct = ABx * BCx + ABy * BCy;
    const crossProduct = ABx * BCy - ABy * BCx;
  
    // Compute angle in radians using atan2, converting directly to degrees
    const angleDegrees = Math.atan2(crossProduct, dotProduct) * (180 / Math.PI);

    return angleDegrees;
  }

  const rotatePanelGrid = (event) => {
    if (!documentRef.current.activeGrid) return;

    const pivot = new THREE.Object3D();
    pivot.position.copy(documentRef.current.activeGrid.selectionSize).multiplyScalar(0.5);
    documentRef.current.activeGrid.add(pivot);
    pivot.updateMatrixWorld(true);

    documentRef.current.activeGrid.parent.attach(pivot);
    pivot.attach(documentRef.current.activeGrid);

    const position = pivot.position.clone().project(cameraRef.current);
    const pivotX = (position.x * 0.5 + 0.5) * canvasRef.current.clientWidth;
    const pivotY = (-position.y * 0.5 + 0.5) * canvasRef.current.clientHeight;

    const cursorX = event.layerX;
    const cursorY = event.layerY;

    if(!documentRef.current.activeGrid.userData.startCursorCoordinates) {
      documentRef.current.activeGrid.userData.startCursorCoordinates = {x:cursorX, y:cursorY};
    }
    if(!documentRef.current.activeGrid.userData.startRotation) {
      documentRef.current.activeGrid.userData.startRotation = pivot.rotation.z;
    }

    const startX = documentRef.current.activeGrid.userData.startCursorCoordinates.x;
    const startY = documentRef.current.activeGrid.userData.startCursorCoordinates.y;

    const rotationAmount = THREE.MathUtils.degToRad(calculateAngle(
      {x:startX, y:startY},
      {x:pivotX, y:pivotY},
      {x:cursorX, y:cursorY}
    ));

    pivot.rotation.z = documentRef.current.activeGrid.userData.startRotation - rotationAmount;

    pivot.updateMatrix(true);
    pivot.updateMatrixWorld(true);
    pivot.parent.attach(documentRef.current.activeGrid);
    pivot.parent.remove(pivot);

    // doc.selectedPanelGrid.position.add(center);

    documentRef.current.activeGrid.disposeOccupancy();

    documentRef.current.activeGrid.recomputeOccupancy({
      gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
      targets: terrainGroup.children,
    });
    documentRef.current.activeGrid.redrawOccupancy(scene);
    updatePanelMatrix();
    setStatisticsReload(Math.random());
  };

  const handleRotateMouseMove = (event) => {
    // end rotate if mouse up was ouside of canvas
    if(event.buttons === 0) {
      handleRotateMouseUp();
    }
    if (!isRotating || !documentRef.current.activeGrid) return;

    threejsRef.current.style.cursor = "grabbing";

    // Disable controls during rotation
    controlsRef.current.enabled = false;
    controlsRef.current.update();
    setDisableRotation(true)

    event.preventDefault();

    rotatePanelGrid(event);

    // refresh active grid highlighting
    documentRef.current.updateBoxFrame(documentRef.current.activeGrid);
    documentRef.current.showScale(documentRef.current.activeGrid);
    // setActive(documentRef.current.activeGrid);
  };

  const handleRotateMouseUp = () => {
    // updateBoxFrame(editingPanelGrid);
    setDisableRotation(false)

    threejsRef.current.style.cursor = "default";

    // cealup rotation variables
    documentRef.current.activeGrid.userData.startCursorCoordinates = null;
    documentRef.current.activeGrid.userData.startRotation = null;

    isRotating = false;
    setIconPosition(null);
    canvasRef.current.removeEventListener("mousemove", handleRotateMouseMove);
    canvasRef.current.removeEventListener("mouseup", handleRotateMouseUp);

    controlsRef.current.enabled = true;
    controlsRef.current.update();

    // Call additional functions after rotation
    // solarProductionData();
    // handleRotationStop(); // Uncomment if needed
  };


  let isMouseDown = false;

  const handleMoveDown = (event) => {
    deleteCables();
    isMouseDown = true;
    mouseDown.current = true
    if (!documentRef.current.activeGrid) return;
    resetSelection();
    controlsRef.current.enabled = false;
    controlsRef.current.update();
    setDisableRotation(true)

    const intersects = io.raycaster.intersectObjects(documentRef.current.activeGrid.children, true);

    if (intersects.length) {
      isMouseDown = true;

      // Calculate the drag offset
      const intersectPoint = intersects[0].point;
      // dragOffset.copy(intersectedGrid.position).sub(intersectPoint);
      dragStart = intersectPoint.clone();
      canvasRef.current.addEventListener("mousemove", handleMovePanel);
      canvasRef.current.addEventListener("mouseup", handleMoveUp);
    }
  };


  const handleMovePanel = (event) => {
    event.stopPropagation()

    if (!isMouseDown) return;
    // console.log(isMouseDown, "isMouseDown")
    setDisableRotation(true)

    const intersects = io.raycaster.intersectObjects(terrainGroup.children, true);

    if (intersects.length) {
      const intersectPoint = intersects[0].point.clone();
      const dragCurrent = intersectPoint.clone();
      let dragDelta = dragCurrent.sub(dragStart);
      if (!documentRef.current.activeGrid.userData.beforeDragPosition)
        documentRef.current.activeGrid.userData.beforeDragPosition = documentRef.current.activeGrid.position.clone();
      if (!documentRef.current.activeGrid.userData.startOffset)
        documentRef.current.activeGrid.userData.startOffset = dragDelta.clone();
      dragDelta = dragDelta.sub(documentRef.current.activeGrid.userData.startOffset);
      documentRef.current.activeGrid.position
        .copy(documentRef.current.activeGrid.userData.beforeDragPosition)
        .add(dragDelta);
      // doc.selectedPanelGrid.position.copy(intersectPoint).add({...dragOffset});

      documentRef.current.activeGrid.disposeOccupancy();
      documentRef.current.activeGrid.recomputeOccupancy({
        gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
        targets: terrainGroup.children,
      });

      documentRef.current.activeGrid.redrawOccupancy(scene);
      updatePanelMatrix();
      setStatisticsReload(Math.random());
    }

    // refresh active grid highlighting
    documentRef.current.updateBoxFrame(documentRef.current.activeGrid);
    documentRef.current.showScale(documentRef.current.activeGrid);
    // setActive(documentRef.current.activeGrid);
  };

  const handleMoveUp = (event) => {
    event.stopPropagation()

    isMouseDown = false;
    mouseDown.current = false

    // cleanup of variables
    documentRef.current.activeGrid.userData.beforeDragPosition = null;
    documentRef.current.activeGrid.userData.startOffset = null;

    controlsRef.current.enabled = true;
    controlsRef.current.update();
    setDisableRotation(false)
    documentRef.current.activeGrid.disposeOccupancy();
    documentRef.current.activeGrid.recomputeOccupancy({
      gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
      targets: terrainGroup.children,
    });

    documentRef.current.activeGrid.redrawOccupancy(scene);
    updatePanelMatrix();
    canvasRef.current.removeEventListener("mousemove", handleMovePanel);
    canvasRef.current.removeEventListener("mouseup", handleMoveUp);
    canvasRef.current.removeEventListener("mousedown", handleMoveDown);
  };

  // const MouseOver =()=>{
  //   console.log("mouseover called ");

  //   canvasRef.current.addEventListener("mousemove",mouseOver)
  // }

  let lineIndex;

  const removeControls = () => {
    if (transformControls) {
      // Detach the object if necessary
      transformControls.detach();
      
      // Remove from the scene
      scene.remove(transformControls);
      
      // Dispose of controls if it has a dispose method
      if (transformControls.dispose) {
        transformControls.dispose();
      }
      
      // Clear the reference
      transformControls = null;
    }
  }

  const handleEdgeDown = (event, lineIndex) => {
    deleteCables();
    isMouseDown = true;
    mouseDown.current = true
    if (!documentRef.current.activeGrid) return;
    resetSelection();
    controlsRef.current.enabled = false;
    controlsRef.current.update();
    setDisableRotation(true);

    canvasRef.current.addEventListener("mousemove", handleMoveEdge);
    canvasRef.current.addEventListener("mouseup", handleEdgeUp);
  }

  const handleMoveEdge = (event) => {
    setStatisticsReload(Math.random());
  }

  const handleEdgeUp = (event) => {
    event.stopPropagation()

    isMouseDown = false;
    mouseDown.current = false

    controlsRef.current.enabled = true;
    controlsRef.current.update();
    setDisableRotation(false);

   /*  scene.add(editingPanelGrid);
    panelGrids.push(editingPanelGrid);

    const fetchData = async () => {
      const data = await solarProductionData(panelGrids, quoteDetails, []);
      onSolarDataChange(data);
    };

    setTimeout(() => {
      fetchData();
    }, 500); */

    canvasRef.current.removeEventListener("mousemove", handleMoveEdge);
    canvasRef.current.removeEventListener("mouseup", handleEdgeUp);
  }

  useEffect(() => {
    if(selectedMode === "Grid move mode" && documentRef.current.activeGrid) {
      showRotate();
      documentRef.current.showScale(documentRef.current.activeGrid);
    }else {
      hideRotate();
      if(documentRef.current.hideScale) {
        documentRef.current.hideScale();
      }
    }
    documentRef.current.selectedMode = selectedMode;
  }, [selectedMode]);

  useEffect(() => {
    function cleanupListeners() {
      console.log("CLEANUP LISTENERS");
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("contextmenu", contextMenuAbort);
      window.removeEventListener("pointerup", onPointerUp);
      window.removeEventListener("pointerdown", onPointerDown);
      // eventEmitter.off("save", handleSave);
      eventEmitter.off("undo", handleUndoRedo);
      eventEmitter.off("rotate", handlePanelRotate);
      eventEmitter.off("move", handleMoveDown);
      eventEmitter.off("mouseover", mouseOver)
    }

    function setupListeners() {
      console.log("SETUP LISTENERS");
      document.addEventListener("keydown", onKeyDown);
      document.addEventListener("contextmenu", contextMenuAbort);
      window.addEventListener("pointerup", onPointerUp);
      window.addEventListener("pointerdown", onPointerDown);
      // eventEmitter.on("save", handleSave);
      eventEmitter.on("undo", handleUndoRedo);
      eventEmitter.on("rotate", handlePanelRotate);
      eventEmitter.on("move", handleMoveDown);
      eventEmitter.on("mouseover", mouseOver)

      return cleanupListeners;
    }

    const cleanup = setupListeners();
    return cleanup;
  }, [contextMenuAbort]);
};

export default PanelGridPlacement;


const deleteMaterial = new THREE.MeshBasicMaterial({
  opacity: 0.2,
  transparent: true,
  color: "red",
});

const deletedMaterial = new THREE.MeshBasicMaterial({
  opacity: 0.1,
  transparent: true,
  color: "green",
});

const restoreMaterial = new THREE.MeshBasicMaterial({
  opacity: 0.5,
  transparent: true,
  color: "green",
});

function setPanelMaterial(panel, material) {
  if(material) {
    panel.traverse((child) => {
      if (child.isMesh) {
        if(!child.userData.originalMaterial) {
          child.userData.originalMaterial = child.material;
        }
        child.material = material;
      }
    });
  }else {
    panel.traverse((child) => {
      if (child.userData.originalMaterial) {
        child.material = child.userData.originalMaterial;
        delete child.userData.originalMaterial;
      }
    }); 
  }
};

export const setVisibilityOnInit = (panelGrids) => {
  panelGrids.map((panelGrid) => {
    if(!panelGrid.initNotVisible) {
      return;
    }
    panelGrid.children.map((panel, index) => {
      if(panel.name === "supported") {
        panel.isNotVisible = panelGrid.initNotVisible[index - 1]
      }
    });
  });

  panelGrids.forEach((pg) => {
    pg.children.forEach((child) => {
      if (child.name === "supported") {
        if(child.isNotVisible) {
          setPanelMaterial(child, deletedMaterial);
        }else {
          setPanelMaterial(child);
        }
      }
    });
  });
}

export const applyVisibility = (panelGrid) => {
  panelGrid.children.forEach((child) => {
    if (child.name === "supported") {
      if(child.isNotVisible) {
        setPanelMaterial(child, deletedMaterial);
      }else {
        setPanelMaterial(child);
      }
    }
  });
}