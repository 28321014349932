import React from "react";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, onCancel }) => {

  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-lg font-semibold">{t("Do you want to replace the screenshots?")}</h3>
        <div className="mt-4 flex justify-center gap-4">
          <button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="bg-primary-color text-darkest-text px-4 py-2 rounded-lg hover:opacity-button-hover hover:scale-button-hover"
          >
           {t("Yes")}
          </button>
          <button
            onClick={() => {
              onCancel();
              onClose();
            }}
            className="bg-grey text-darkest-text px-4 py-2 rounded-lg hover:opacity-button-hover hover:scale-button-hover"
          >
           {t("No")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
