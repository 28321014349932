/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import { Environment, OrbitControls, PerspectiveCamera, useGLTF } from "@react-three/drei";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import axios from "axios";
import React, {
  Suspense,
  useState,
  useRef,
  forwardRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as THREE from "three";
import { MOUSE } from "three";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import { EnvmapHDRI } from "src/assets/envmap";
import {
  Home,
  HomeYellow,
  HouseModel,
  Light,
  HandWhite,
  Planet,
  PlanetYellow,
  BlueSolarPanel,
  SolarPanelYellow,
  LowPolyPanel,
  MeshpotPanel,
  FullScreenIcon,
  ZoomIn,
  ZoomOut,
  ScreenShotIcon,
  CloseIcon,
  UndoIcon,
  Lock,
  MaxWidth,
  CabelingIcon,
  CabelingYellowIcon,
  Polygon,
  MesureYellow,
  deleteIcon,
  cablingBlueIcon,
  aiIcon,
  penIcon,
  pluIcon,
  RotateIcon,
  MoveIcon,
  Redo,
  ScaleIcon,
  ScaleYellowIcon,
  ScaleWhiteIcon,
} from "src/assets/step33dViwer";
import { ReactComponent as Compass } from "src/assets/step33dViwer/compass.svg";
import { ReactComponent as FullscreenMaximize } from "src/assets/step33dViwer/fullscreen-maximize.svg";
import { ReactComponent as FullscreenMinimize } from "src/assets/step33dViwer/fullscreen-minimize.svg";
import { ReactComponent as HideStatistics } from "src/assets/step33dViwer/hide-statistics.svg";
import { TabBar } from "src/shared/components";
import { isValidURL } from "src/utils";

import { updateimgUrLs, update3dQuote, getProject } from "./api/backend-endpoints";
import { BackendApis } from "./api/index";
import { ApiAxiosClient } from "./axios";
import { getColorFromCSS } from "./helpers/GetColorFromCSS";
import { useAppDispatch } from "./hooks";
import Cabeling, { getSurfacePosition, calculatePanelCenter, throttle } from "./PanelPlacement/Cabeling";
import { Editor } from "./PanelPlacement/Editor";
import eventEmitter from "./PanelPlacement/EventEmitter/EventEmitter";
import { initLoaders, loadMaterials, loadOBJPanelModule } from "./PanelPlacement/Loaders";
import Measurement from "./PanelPlacement/Measurement";
import MeasurementPopup from "./PanelPlacement/MeasurementPopup";
import PanelGridPlacement, { setVisibilityOnInit } from "./PanelPlacement/PanelGridPlacement";
import PanelGridRenderer from "./PanelPlacement/PanelGridRenderer";
import PolygonInfoPopup from "./PanelPlacement/polygonsDetailsPopup";
import ConfirmationDialog from "./PanelPlacement/PopUp/ConfirmationDialog";
import { solarProductionData } from "./PanelPlacement/PopUp/SolarProductionData.js";
import RadioContainer from "./PanelPlacement/RadioContainer";
import { ProjectActions } from "./redux/actionCreators";
import { ProjectSelectors } from "./redux/selectors";
import Tooltip from "./tooltip/Tooltip";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";

// Loading the House Model
const Model = forwardRef(({ scale, mtlUrl, objUrl, setPanelRendererReload}, ref) => {
  const [objData, setObjData] = useState(null);
  const materials = useLoader(MTLLoader, mtlUrl);

  useEffect(() => {
    const fetchAndProcessObj = async () => {
      // Fetch the OBJ file
      const response = await fetch(objUrl);
      const objText = await response.text();
  
      // Preprocess the OBJ text (e.g., remove line segments)
      const preprocessedObjText = preprocessOBJ(objText);
  
      // Create a Blob with the preprocessed OBJ text
      const blob = new Blob([preprocessedObjText], { type: "application/octet-stream" });
      const preprocessedObjUrl = URL.createObjectURL(blob);
  
      // Set the URL for use with useLoader
      setObjData(preprocessedObjUrl);
    };

    fetchAndProcessObj();
  }, [objUrl]);
 
  const obj = useLoader(OBJLoader, objData, (loader) => {
    materials.preload();
    loader.setMaterials(materials);
  });

  function preprocessOBJ(objText) {
    // Split the OBJ text into lines
    const lines = objText.split("\n");
  
    // Filter out line segments (lines starting with "l")
    const filteredLines = lines.filter(line => !line.startsWith("l"));
  
    // Return the filtered OBJ content as a string
    return filteredLines.join("\n");
  }

  // When the model has finished loading, set its layers
  useEffect(() => {
    if (obj) {
      // Traverse through the object's meshes and assign layers
      obj.traverse((child) => {
        if (child.isMesh) {
          // Set this mesh to layers 1 and 2 (for example)
          child.layers.enable(0);  // Enable layer 1
          child.layers.enable(1);  // Enable layer 2
        }
      });

      setPanelRendererReload(Math.random());
    }
  }, [obj]); // Runs when the `obj` has finished loading

  React.useImperativeHandle(ref, () => ({
    scale: obj.scale,
    position: obj.position,
    scene: obj,
  }));

  return <primitive object={obj} scale={scale} position={[0, 0, 0]} />;
});

// Cursor position and cylinder below cursor to check raycasting on model
const MouseLogger = ({
  modelRef,
  cylinderref,
  drawingLine,
  startPoint,
  addEndPoint,
  selectedMode,
  deleteMeasurementPoint,
  points,
  polygons,
  historyPoints,
}) => {
  const { camera, gl } = useThree();
  const raycaster = useRef(new THREE.Raycaster()).current;
  const clickTimeout = useRef();
  // Use Frame to get mouse at each frame
  useFrame((state) => {
    const { mouse } = state;

    raycaster.setFromCamera(mouse, camera);

    if (modelRef.current) {
      const intersects = raycaster.intersectObject(modelRef.current.scene, true);

      if (intersects.length > 0) {
        const intersection = intersects[0];
        const { point, face } = intersection;

        cylinderref.current.visible = true;
        cylinderref.current.position.copy(point);

        const lookAtPosition = new THREE.Vector3().copy(face.normal).multiplyScalar(-1).add(point);
        cylinderref.current.lookAt(lookAtPosition);
      } else {
        cylinderref.current.visible = false;
      }
    }
  });

  const handleMouseMove = (event) => {
    const { offsetX, offsetY } = event;
    const x = (offsetX / gl.domElement.clientWidth) * 2 - 1;
    const y = -(offsetY / gl.domElement.clientHeight) * 2 + 1;

    raycaster.setFromCamera({ x, y }, camera);
    if (drawingLine && startPoint) {
      const intersects = raycaster.intersectObject(modelRef.current.scene, true);

      if (intersects.length > 0) {
        addEndPoint(intersects[0].point.clone());
      }
    }
  };

  React.useEffect(() => {
    if (!gl) return; // Ensure gl is ready

    const domElement = gl.domElement;
    if (selectedMode === "Create mode" || selectedMode === "Scale mode") {
      domElement.addEventListener("mousemove", handleMouseMove);
    }


    return () => {
      domElement.removeEventListener("mousemove", handleMouseMove);
    };
  }, [gl, selectedMode, modelRef.current, deleteMeasurementPoint, points, polygons, historyPoints]);

  return null;
};

const solarDocument = {
  editor: new Editor(),
  panelGrids: [],
};

// Main Component
const CreateProto1 = ({
  productObjFiles,
  dispatch,
  maxWidth3DViewer,
  maxWidth3DViewerWithImages,
  from3DViwer,
  threejsRef,
  objUrl,
  mtlUrl,
  address,
  project,
  quoteProfile,
  modelFullView,
  capacity,
  setModelFullView
}) => {
  const { t } = useTranslation();

  // Reference for camera, controls, and other buttons
  const cameraRef = useRef();
  const sceneRef = useRef();
  const rendererRef = useRef();
  const extracamerasRef = useRef([]);

  const controlsRef = useRef();
  const isMouseDown = useRef(false);

  const modelRef = useRef();
  const homeIconRef = useRef();
  const planetIconRef = useRef();
  const handIconRef = useRef();
  const solarPanelIcon = useRef();
  const cylinderref = useRef();
  const iconsRef = useRef();

  const terraingroupRef = useRef();
  const clickTimeout = useRef(null); // Ref to store the click timeout

  const canvasContainerRef = useRef(null);
  // const panelGrids=documentRef.current.panelGrids

  // const [panelGrids, setPanelgrids] = useState(documentRef.current.panelGrids);
  const cabelingIcon = useRef();
  const polygonIcon = useRef();
  const scaleIconRef = useRef();
  const canvasRef = useRef();
  const isEditing = useRef(false);
  const deleteIconRef = useRef(null);
  const popupRef = useRef(null);
  const ambientLightRef = useRef();
  const compassRef = useRef();

  const documentRef = useRef(solarDocument);
  const doc = documentRef.current;
  const editor = doc.editor;
  let dragStart;

  // for getting default saved data
  // const project = useSelector(ProjectSelectors.getProject);

  // State for maintaining the model scale
  const [modelScale, setModelScale] = useState(0.9);
  const [isPanelPlacing, setIsPanelPlacing] = useState(false);

  // State for selection between panel models
  const [showSelectionMenu, setShowSelectionMenu] = useState({
    open: false,
    from: "",
  });

  const [selectedPanel, setSelectedPanel] = useState(null);

  // State for the cabeling mode
  const [mode, setMode] = useState("");

  // State for the cabeling mode
  const [startRadio, setStartRadio] = useState(false);
  const [mouseInteract, setMouseInteract] = useState(false);

  // State for showing measurement popup
  const [showPopup, setShowPopup] = useState(false);

  const [selectedPoint, setSelectedPoint] = useState(null);

  const [position, setPosition] = useState({ top: 0, left: 0 });

  const [iconPosition, setIconPosition] = useState(null);

  // state for delete Icon position
  const [visible, setVisible] = useState(false);

  // state for points
  const [points, setPoints] = useState([]);
  // state for points
  const [scalePoints, setScalePoints] = useState([]);
  // state for polygons
  const [polygons, setPolygons] = useState([]);
  // state for history polygons
  const [historyPolygons, setHistoryPolygons] = useState([]);
  // state for history cabling polygons
  const [historyCablingPolygons, setHistoryCablingPolygons] = useState([]);

  const [currentIndex, setCurrentIndex] = useState({ outer: 0, inner: 0 });

  const [drawingLine, setDrawingLine] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [disableRotation, setDisableRotation] = useState(false);
  const [divPosition, setDivPosition] = useState({ top: 0, left: 0 });
  const [message, setMessage] = useState("");
  const [deleteMeasurementPoint, setDeleteMeasurementPoint] = useState({
    polygonIndex: null,
    pointIndex: null,
    position: null,
  });

  const [selectedMode, setSelectedMode] = useState("");

  const [panlesLoaded, setPanelsLoaded] = useState(false);
  const [modelLoaded, setModelLoaded] = useState(false);
  const [initCables, setInitCables] = useState([]);

  // set only one time
  const [hasProcessed, setHasProcessed] = useState(false);

  // state for the cabeling feature
  const [cablingLoops, setCablingLoops] = useState([]);

  const [directionalLight, setDirectionalLight] = useState(null);
  const [solardata, setsolarData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedObjUrl, setSelectedObjUrl] = useState(null);

  // state for delete Icon position
  const [scalePopup, setScalePopup] = useState(false);

  // Fullscreen 
  const [fullscreen, setFullScreen] = useState(false)

  const [panelRendererReload, setPanelRendererReload] = useState(1);
  const [statisticsReload, setStatisticsReload] = useState(1);

  const quoteDetails = useSelector(ProjectSelectors.getQuote);
  const threeDModels = useSelector(ProjectSelectors.getThreeDModels);


  const handleSolarDataChange = (data) => {
    setsolarData(data);
  };

  /* useEffect(() => {
    // Set up the interval to run every 3 seconds (3000 milliseconds)
    const interval = setInterval(() => {
      
      console.info("This runs every 3 seconds", modelLoaded);
      // Add any other logic you want to trigger here
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); */

  useEffect(() => {
    if(panlesLoaded) {
      setVisibilityOnInit(documentRef.current.panelGrids);
      getCableOnInit(documentRef.current.panelGrids, initCables);

      const fetchData = async () => {
        const data = await solarProductionData(panelGrids, quoteDetails, []);
        handleSolarDataChange(data);
      };
      setTimeout(() => {
          fetchData();
      }, 500);
    }
  }, [panlesLoaded]);
  
  useEffect(() => {
    if (ambientLightRef.current) {
      ambientLightRef.current.layers.enable(0);
      ambientLightRef.current.layers.enable(1);
    }
  }, [ambientLightRef.current]);

  useEffect(() => {
    const onWindowResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (rendererRef.current) {
        rendererRef.current.setSize(width, height);

        rendererRef.current.setPixelRatio(window.devicePixelRatio);


      }
    };
    window.addEventListener("resize", onWindowResize, false);



    onWindowResize();

    return () => {
      window.removeEventListener("resize", onWindowResize, false);
    };
  }, []);

  // Reset all the buttons src
  const resetAllIcons = () => {
    homeIconRef.current.src = Home;
    planetIconRef.current.src = Planet;
    handIconRef.current.src = HandWhite;
    solarPanelIcon.current.src = BlueSolarPanel;
    cabelingIcon.current.src = CabelingIcon;
    polygonIcon.current.src = Polygon;
    scaleIconRef.current.src = ScaleWhiteIcon;
    setIsPanelPlacing(false);

    cylinderref.current.geometry.rotateX(Math.PI * 0.5);
    setDisableRotation(false);
    setSelectedMode("");
    setIconPosition(null);
    setScalePopup(false);
    isEditing.current = false;
    setScalePoints([]);
    setPoints([]);
    setMode("");
    setShowSelectionMenu({ open: false, from: "" });
    controlsRef.current.mouseButtons.LEFT = MOUSE.ROTATE;
    controlsRef.current.enablePan = false;
    controlsRef.current.update();
  };

  // Reset the model and camera on home button click
  const resetView = () => {
    resetAllIcons();
    setStartRadio(false);
    homeIconRef.current.src = HomeYellow;
    if (cameraRef.current && controlsRef.current) {
      cameraRef.current.position.set(0, 20, 35);
      cameraRef.current.fov = 75;
      // controlsRef.current.target.set(0, 0, 0);
      // controlsRef.current.update();
    }
    cameraRef.current.fov = 55;
    cameraRef.current.updateProjectionMatrix();
    // modelRef.current.scene.position.set(0, -2, 0);
    // setModelScale(modelScale);
  };

  // Assitance popup
  const appdispatch = useAppDispatch()
  const handleAssistance = ()=>{
    console.log("okahelly")
    appdispatch(PopupActions.openPopup(POPUP_KEYS.hubspot_meeting));
  }

  // Pan when hand button clicked
  const togglePan = () => {
    setStartRadio(false);
    resetAllIcons();
    handIconRef.current.src = Light;
    controlsRef.current.mouseButtons.LEFT = MOUSE.PAN;
    controlsRef.current.enablePan = true;
    controlsRef.current.update();
  };

  // Zoom in button
  const zoomIn = () => {
    cameraRef.current.fov = Math.max(10, cameraRef.current.fov - 3);
    setStartRadio(false);

    cameraRef.current.updateProjectionMatrix();
  };

  // Zoom out button
  const zoomOut = () => {
    cameraRef.current.fov = Math.min(100, cameraRef.current.fov + 3);
    setStartRadio(false);

    cameraRef.current.updateProjectionMatrix();
  };

  // Full screen button
  const toggleFullscreen = () => {
    setStartRadio(false);
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setFullScreen(false)
    } else {

      canvasRef.current.style.height = threejsRef.current.clientHeight
      threejsRef.current.requestFullscreen();
      setFullScreen(true)


    }
  };

  // Planet button
  const selectPlanet = () => {
    setStartRadio(false);
    resetAllIcons();
    planetIconRef.current.src = PlanetYellow;
  };

  // Solar panel button
  const handleSolarPanelClick = () => {
    setSelectedTab(TAB_KEYS.PANELGRIDS);
    resetAllIcons();
    setStartRadio(true);
    setSelectedMode("Grid move mode");
    solarPanelIcon.current.src = SolarPanelYellow;

    if (selectedPanel) {
      setIsPanelPlacing(true);
      canvasRef.current.addEventListener("mousemove",()=>{

        eventEmitter.emit("mouseover")
      })
      return;
    }

    setShowSelectionMenu({ open: true, from: "solarPanel" });
    canvasRef.current.addEventListener("mousemove",()=>{

      eventEmitter.emit("mouseover")
    })
  };

  // Solar panel button
  const handleMeasurementClick = () => {
    setSelectedTab(TAB_KEYS.MEASUREMENT);
    resetAllIcons();
    polygonIcon.current.src = MesureYellow;
    setStartRadio(true);
    setSelectedMode("Create mode");
    setShowPopup(true);
  };

  // Cableing button
  const handleCabelingClick = () => {
    setStartRadio(false);
    resetAllIcons();
    if (documentRef.current.panelGrids.length > 0) {
      cabelingIcon.current.src = CabelingYellowIcon;
      setShowSelectionMenu({ open: true, from: "cabeling" });
    } else {
      toast.warn(t("Please Draw one panel Grid!!"));
    }
  };

  const handleScaleClick = () => {
    resetAllIcons();
    scaleIconRef.current.src = ScaleYellowIcon;
    setSelectedMode("Scale mode");
    setScalePopup(true);
  };

  const saveFile = async (dataUrl, isCabelingImage) => {
    const formData = new FormData();
    formData.append("type", "quote");

    try {
      const res = await fetch(dataUrl);
      const blob = await res.blob();

      const file = new File([blob], isCabelingImage ? "canvas-cabeling.png" : "canvas.png", { type: "image/png" });
      formData.append("file_upload", file);

      const response = await axios.post(BackendApis.uploadImages.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      await ApiAxiosClient.put(update3dQuote.url, {
        image: response?.data?.data[0]?.url,
        id: project.quote.id,
      });

      return response?.data?.data[0]?.url || null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getStructureImage = useMemo(() => {
    return quoteProfile?.imgURLs.find((img) => {
      if (img.includes("Destination") && isValidURL(img)) {
        return address.city === "Gundelfingen" ? img.includes("Baked") : img.includes("Screenshot");
      }
      return null;
    });
  }, [address, quoteProfile?.imgURLs]);

  const getObjAndMtlFiles = useMemo(() => {
    const objFile = quoteProfile?.imgURLs.find((url) => url.includes(".obj"));
    const mtlFile = quoteProfile?.imgURLs.find((url) => url.includes(".mtl"));

    if (objFile && mtlFile) {
      return { objFile, mtlFile };
    }

    return null;
  }, [quoteProfile?.imgURLs]);

  const token = localStorage.getItem("token");

  const updateImages = async (imgURLs, toastId) => {
    const data = JSON.stringify({
      imgUrls: imgURLs,
      id: project.quote.id,
      profile: "green",
    });

    const config = {
      method: updateimgUrLs.method,
      url: updateimgUrLs.url,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };

    try {
      const response = await axios.request(config);

      dispatch(
        ProjectActions.updateProject({
          ...project,
          quote: {
            ...project.quote,
            profiles: {
              ...project.quote.profiles,
              green: {
                ...project.quote.profiles.green,
                imgURLs: [...imgURLs],
              },
            },
          },
        }),
      );
      // console.log(JSON.stringify(response.data.data.profiles.green.imgUrl));

      toast.dismiss(toastId);
      toast.success(t("Your images are successfully replaced!!"));
      return response;
    } catch (error) {
      console.error("Error updating image URLs:", error);
    }
  };

  useEffect(() => {
    if (project?.threeDObjDetails) {
      console.log("Updated threeDObjDetails:", project.threeDObjDetails);
    }
  }, [project.threeDObjDetails]);

  // Screenshot button
  const handleConfirmScreenshot = async () => {
    const toastId = toast.loading(t("3D_VIEW_OVERWRITING_SCREENSHOTS"));
    resetAllIcons();
    setIsDialogOpen(false);

    const canvas = canvasRef.current
    const renderer = rendererRef.current;
    const scene = sceneRef.current;
    const mainCameraView = cameraRef.current.clone();
    mainCameraView.layers.disableAll();
    mainCameraView.layers.enable(1);

    if (!renderer || !scene) {
      console.error("Renderer or scene is not available!");
      return;
    }

    const cameras = [mainCameraView, ...extracamerasRef.current];

    if (!cameras.length) {
      console.error("No cameras found in extraCamerasRef!");
      return;
    }

    try {
      const structureImage = getStructureImage;
      const objAndMtlFiles = getObjAndMtlFiles;

      let isFirstImage = true;
      const uploadPromises = cameras.map((camera, index) => {
        if (camera && cameraPositions[index]) {
          try {
            if (camera !== mainCameraView) {
              camera.lookAt(new THREE.Vector3(0, 0, 0));
            }
            renderer.render(scene, camera);

            const dataUrl = canvasRef.current.toDataURL("image/png");
            return saveFile(dataUrl, isFirstImage);
          } finally {
            isFirstImage = false;
          }
        }
        return null;
      });


      const uploadResponses = await Promise.all(uploadPromises);

      const uploadedImageUrls = uploadResponses.filter(Boolean);

      /* if (structureImage && isValidURL(structureImage)) {
        uploadedImageUrls.push(structureImage);
      } else {
        console.error("Not able to find the structure image!!");
      } */

      if (objAndMtlFiles && Object.keys(objAndMtlFiles).length > 0) {
        Object.values(objAndMtlFiles).forEach((file) => {
          uploadedImageUrls.push(file);
        });
      } else {
        console.error("Not able to find the .obj or .mtl file!!");
      }


      if (uploadedImageUrls.length > 0) {
        await updateImages(uploadedImageUrls, toastId);
      }

    } catch (error) {
      toast.dismiss(toastId);
      console.error("Error during screenshot upload:", error);
    }
  };

  const handleCancelScreenshot = async () => {
    const toastId = toast.loading(t("3D_VIEW_ADDING_SCREENSHOTS"));
    resetAllIcons();
    setIsDialogOpen(false);

    const canvas = canvasRef.current
    const renderer = rendererRef.current;
    const scene = sceneRef.current;
    const mainCameraView = cameraRef.current.clone();
    mainCameraView.layers.disableAll();
    mainCameraView.layers.enable(1);

    if (!renderer || !scene) {
      console.error("Renderer or scene is not available!");
      return;
    }

    const cameras = [mainCameraView, ...extracamerasRef.current];

    if (!cameras.length) {
      console.error("No cameras found in extraCamerasRef!");
      return;
    }

    try {
      const structureImage = getStructureImage;
      const objAndMtlFiles = getObjAndMtlFiles;

      let isFirstImage = true;
      const uploadPromises = cameras.map((camera, index) => {
        if (camera && cameraPositions[index]) {
          try {
            if (camera !== mainCameraView) {
              camera.lookAt(new THREE.Vector3(0, 0, 0));
            }
            renderer.render(scene, camera);

            const dataUrl = canvasRef.current.toDataURL("image/png");
            return saveFile(dataUrl, isFirstImage);
          } finally {
            isFirstImage = false;
          }
        }
        return null;
      });

      const uploadResponses = await Promise.all(uploadPromises);
      const responseURLs = uploadResponses.filter(Boolean);

      let uploadedImageUrls;
      if (threeDModels?.active && threeDModels?.models[threeDModels?.active]?.images) {
        uploadedImageUrls = threeDModels?.models[threeDModels?.active]?.images;
      }

      if(uploadedImageUrls.length > 0) {
        uploadedImageUrls = uploadedImageUrls.concat(responseURLs);
      }

      /* if (structureImage && isValidURL(structureImage)) {
        uploadedImageUrls.push(structureImage);
      } else {
        console.error("Not able to find the structure image!!");
      } */

      if (objAndMtlFiles && Object.keys(objAndMtlFiles).length > 0) {
        Object.values(objAndMtlFiles).forEach((file) => {
          uploadedImageUrls.push(file);
        });
      } else {
        console.error("Not able to find the .obj or .mtl file!!");
      }

      if (uploadedImageUrls.length > 0) {
        await updateImages(uploadedImageUrls, toastId);
      }

    } catch (error) {
      toast.dismiss(toastId);
      console.error("Error during screenshot upload:", error);
    }
  };

  const screenShotClick = () => {
    setIsDialogOpen(true);
  };


  // Save Button
  const handleSave = async (currentPolygons, currentCables) => {
    const toastId = toast.loading(t("3D_VIEW_SAVING"));

    const toJSON = documentRef.current.toJSON;
    const json = toJSON();

   /*  if (panelGrids.length > 0) { 
      if (solardata.length > 0) { */
        const s = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        };

        const monthly = solardata.map((ele) => ele.outputs.monthly.fixed);
        const total = solardata.map((ele) => ele.outputs.totals.fixed);

        monthly?.forEach((ele, index) => {
          ele.forEach((ele) => {
            s[ele.month] +=
              ele.E_m *
              panelGrids?.[index]?.children
                .slice(1)
                .filter((ele) => ele.name === "supported" && !ele?.isNotVisible).length;
          });
        });

        const panelCounts = panelGrids.reduce((ele, a, index) => {
          return (
            ele +
            panelGrids?.[index]?.children
              .slice(1)
              .filter((ele) => ele.name === "supported" && !ele?.isNotVisible).length
          );
        }, 0);

        const monthlySolarData = {
          "January (kWh)": s["1"],
          "February (kWh)": s["2"],
          "March (kWh)": s["3"],
          "April (kWh)": s["4"],
          "May (kWh)": s["5"],
          "June (kWh)": s["6"],
          "July (kWh)": s["7"],
          "August (kWh)": s["8"],
          "September (kWh)": s["9"],
          "October (kWh)": s["10"],
          "November (kWh)": s["11"],
          "December (kWh)": s["12"],
        };

        const totalAnnualProduction = Object.values(monthlySolarData).reduce((el, c) => el + c, 0);
        const calculatedtotalsSolargisData = total?.flat(2).reduce((a, c) => a + c.E_y, 0);

        await ApiAxiosClient.put(`${update3dQuote.url}`, {
          monthlySolarData,
          productionCapacity: capacity * panelCounts,
          moduleQuantity: panelCounts,
          annualSolarGeneration: totalAnnualProduction,
          id: project.quote.id,
        });

        dispatch(
          ProjectActions.updateProject({
            ...project,
            quote: {
              ...project.quote,
              profiles: {
                ...project.quote.profiles,
                green: {
                  ...project.quote.profiles.green,
                  monthlySolarData,
                  productionCapacity: capacity * panelCounts,
                  moduleQuantity: panelCounts,
                  annualSolarGeneration: totalAnnualProduction,
                },
              },
            },
          })
        );
      /* } else {
        toast.warn(t("Please Complete the Panel Placement first then you can Save it!!"));
        return;
      } */

      // Update the project with the 3D object details
      const createdPoints = project.threeDObjDetails.createdPoints;
      const createdLineLoops = cableIndexes(cablingLoops);

      dispatch(
        ProjectActions.updateProject({
          threeDObjDetails: {
            ...project.threeDObjDetails,
            createdPoints: polygons,
            createdLineLoops,
            scale: modelScale,
            panelPlacement: JSON.stringify(json),
            panelName: selectedObjUrl,
          },
        })
      );

      // Use a setTimeout to ensure the state has been updated before logging
      setTimeout(() => {
        console.log("Updated threeDObjDetails:", project?.threeDObjDetails);
      }, 1000);
      
      setTimeout(() => {
        toast.dismiss(toastId);
        toast.success(t("Your Information Saved Successfully!!"));
      }, 1000);
    
    /* } else {
      toast.warn(t("Please Complete the Panel Placement first then you can Save it!!"));
    } */
  };

  const handleUndoRedo = () => {
    documentRef.current.resetActive();
    editor.document.undo();
    // eventEmitter.emit("undo");
  };

  const handleSelection = async (params) => {
    if (showSelectionMenu.from === "solarPanel") {
      const { obj, mtl, height, width } = params;

      try {
        const { mtlLoader } = initLoaders();

        const dimentions = {
          height: height ? + height / 1000 : 2,
          width: width ? + width / 1000 : 1,
        };
        documentRef.current.dimentions = dimentions

        const materials = await loadMaterials(mtlLoader, mtl);
        const objModule = await loadOBJPanelModule(obj, materials, documentRef.current.dimentions);
        documentRef.current.modules = [objModule];

        setSelectedPanel(params);
        setSelectedObjUrl(obj);
        setIsPanelPlacing(true);


      } catch (error) {
        console.error("Error loading panel:", error);
      }
    } else {
      setMode(params);
    }
    setShowSelectionMenu({ open: false, from: "" });
  };

  const handleMaxwidth = () => {
    setModelFullView(!modelFullView)
  };

  const handleToggleRadiation = () => {
    if (directionalLight) {
      sceneRef.current.remove(directionalLight);
      setDirectionalLight(null);
    } else {
      const light = new THREE.DirectionalLight(0xea1601, 100);
      light.position.set(-3, 2, 2);
      sceneRef.current.add(light);
      setDirectionalLight(light);
    }
  };

  const handleClose = () => {
    setShowSelectionMenu({ open: false, from: "" });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const addEndPoint = (point) => {
    setEndPoint(point);
  };

  const handleDoubleClick = (e) => {

    e.stopPropagation();

    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current); // Clear the single-click timeout
      clickTimeout.current = null;
    }
    if (selectedMode === "Create mode" && points.length > 2) {
      const newPoints = addPointBetweenConsecutivePoints(points);

      editor.document.doCommand({
        name: "addMeasurementPolygon",
        newPoints,
        polygonIndex: historyPolygons.length,
        do: function () {
          setPolygons((prev) => [...prev, this.newPoints]);
          setHistoryPolygons((prev) => [...prev, this.newPoints]);
          setPoints([]);
          setShowPopup(true);
          setDrawingLine(false);
        },
        undo: function () {
          setPolygons((prev) => {
            const updatedPolygons = prev.slice(0, -1); // Remove the last added polygon

            // Set measurementPoints to the previous polygon or an empty array
            setPoints(prev[prev.length - 1] || []);
            return updatedPolygons;
          });

          setShowPopup(false);
        },
        redo: function (_, { historyPolygons, index }, cmd) {
          setPolygons((prevPolygons) => {
            const nextPolygon = historyPolygons[cmd.polygonIndex]; // Access the correct polygon from history
            if (nextPolygon) {
              return [...prevPolygons, nextPolygon];
            }
            return prevPolygons;
          });
          setPoints([]);
          setShowPopup(true);
        },
      });
    }
  };

  const debouncedDispatch = useCallback(
    throttle(() => {
      /* const createdLineLoops = cableIndexes(cablingLoops);
      
      dispatch(
        ProjectActions.updateProject({
          threeDObjDetails: {
            ...project.threeDObjDetails,
            createdPoints: polygons,
            createdLineLoops,
            scale: modelScale,
          },
        }),
      ); */

      setCurrentIndex({
        outer: polygons?.length > 0 ? polygons?.length - 1 : 0,
        inner: polygons[polygons?.length - 1]?.length || 0,
      });
    }, 500), // Adjust the debounce delay as needed
    [dispatch, polygons, cablingLoops, modelScale],
  );

  // Effect that runs when polygons change
  useEffect(() => {
    debouncedDispatch();
  }, [debouncedDispatch]);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setPoints([]);
      setDrawingLine(false); // Clear the points when Escape is pressed
    }
  };

  const handleClickOutside = (event) => {
    if (deleteIconRef.current && !deleteIconRef.current.contains(event.target)) {
      setVisible(false);
      setStartPoint(null);
      setDeleteMeasurementPoint({
        polygonIndex: null,
        pointIndex: null,
        position: null,
      });
    }

    if (iconsRef.current && !iconsRef.current.contains(event.target)) {
      setIconPosition(null);
      removeMoveIcon(event);

      // controlsRef.current.enabled = true;
      // controlsRef.current.update();
    }

    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowSelectionMenu({ open: false, from: "" });
    }
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown);

    if (canvasRef.current) {
      // Add event listener to detect clicks outside the component
      canvasRef.current.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      // Clean up the event listener on component unmount
      if (canvasRef.current) {
        canvasRef.current.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [canvasRef, showSelectionMenu, startPoint, deleteMeasurementPoint]);

  const redo = () => {
    editor.document.redo(
      mode === "manual"
        ? { historyCablingPolygons, index: cablingLoops.length }
        : { historyPolygons, index: polygons.length, points },
    );
  };

  const addPoint = (newPoint) => {
    if (isEditing.current) return;

    const currentPoints = points; // Directly use the current points state

    // Check if there are existing points
    if (currentPoints.length > 0) {
      // Get the last point in the array
      const lastPoint = currentPoints[0];

      // Calculate the distance between the new point and the last point
      const distance = lastPoint.distanceTo(newPoint);

      // Only add the new point if the distance is greater than 0.5
      if (distance < 0.5) {
        setDrawingLine(false);
        const newPoints = addPointBetweenConsecutivePoints(currentPoints);

        editor.document.doCommand({
          name: "addMeasurementPolygon",
          newPoints,
          polygonIndex: historyPolygons.length,
          historyPolygons: [...polygons, newPoints],
          do: function () {
            setPolygons((prev) => [...prev, this.newPoints]);
            setHistoryPolygons((prev) => [...prev, this.newPoints]);
            setPoints([]);
            setShowPopup(true);
          },
          undo: function () {
            setPolygons((prev) => {
              const updatedPolygons = prev.slice(0, -1); // Remove the last added polygon

              // Set measurementPoints to the previous polygon or an empty array
              setPoints(prev[prev.length - 1] || []);
              return updatedPolygons;
            });

            setShowPopup(false);
          },
          redo: function (_, { historyPolygons }, cmd) {
            setPolygons((prevPolygons) => {
              const nextPolygon = historyPolygons[cmd.polygonIndex]; // Access the correct polygon from history
              if (nextPolygon) {
                return [...prevPolygons, nextPolygon];
              }
              return prevPolygons;
            });
            setPoints([]);
            setShowPopup(true);
          },
        });

        return; // Exit function if the distance condition is not met
      }
    }

    if (selectedMode === "Scale mode" && currentPoints.length === 1) {
      setDrawingLine(false);
      setStartPoint(null);
      setScalePoints([...points, newPoint.clone()])
      setPoints([]);
      return;
    }

    setDrawingLine(true);
    setStartPoint(newPoint.clone());
    // Add the point and manage undo/redo
    editor.document.doCommand({
      name: "addMeasurementPoint",
      grid: newPoint.clone(),
      polygonIndex: historyPolygons.length,
      do: function () {
        setPoints([...points, this.grid]);
      },
      undo: function () {
        setPoints((prevPoints) => {
          if (prevPoints.length >= 2) {
            return prevPoints.slice(0, -2);
          } else {
            return []; // Handle case when there are fewer than 2 points
          }
        });
      },
      redo: function (_, { historyPolygons, index, points }, cmd) {
        const historyPoints = historyPolygons[cmd.polygonIndex];
        if (historyPoints) {
          setPoints([...points, historyPoints[points.length], historyPoints[points.length + 1]]);
        }
      },
    });
  };

  const addPointBetweenConsecutivePoints = (points) => {
    const newPoints = [];

    for (let i = 0; i < points.length; i++) {
      const point1 = points[i];
      const point2 = points[(i + 1) % points.length];
      const midpoint = new THREE.Vector3().copy(point1).add(point2).multiplyScalar(0.5);
      // Calculate midpoint

      newPoints.push(point1, midpoint);

      // Note: R3F does not use the traditional THREE.js methods to directly manipulate objects;
      // instead, use state and components to manage the scene.
    }

    return newPoints;
  };

  const getCableOnInit = (panelGrids, cableGroups) => {
    if(cableGroups.length > 0) {
      const panelCenters = panelGrids.map((grid) => {
        return grid.children
          .slice(1)
          .map((e) => {
            if(!(e.name === "supported")) {
              return null;
            };
            const position = calculatePanelCenter(e.children[0]);
            const surfacePosition = getSurfacePosition(position, e.children[0], 0.1);
            return { point: surfacePosition};
          });
      });

      if(panelGrids.length > 0) {
        const cableNodes = cableGroups.map((cableGroup) => {
          const nodeGroup = cableGroup.map((node) => {
            const panelGrid = panelGrids[node.gridIndex];
            const slicedGrid = panelGrid.children.slice(1);
            const panel = slicedGrid[node.panelIndex];
            const panelGridCenters = panelCenters[node.gridIndex];
            const surfacePosition = panelGridCenters[node.panelIndex];
            return {point: surfacePosition.point, panel};
          });
          return nodeGroup;
        });

        setCablingLoops(cableNodes);
      };
    };
  };

  const cableIndexes = (lineLoops) => {
    const panelGrids = documentRef.current.panelGrids;

    const createdLineLoops = lineLoops.map((lineLoop) => {
      const cablingIndexes = lineLoop.map((cableNode) => {
        let gridIndex;
        let panelIndex;
        panelGrids.map((panelGrid, panelGridIndex) => {
          panelGrid.children.map((panel, foundPanelIndex) => {
            if(panel.uuid === cableNode.panel.uuid) {
              gridIndex = panelGridIndex;
              panelIndex = foundPanelIndex - 1;
            };
          });
        });
        return {gridIndex, panelIndex};
      });

      return cablingIndexes;
    });
    
    return createdLineLoops;
  }

  useEffect(() => {
    if (!hasProcessed) {
      if (project?.threeDObjDetails?.createdPoints.length > 0) {
        const vector3Points = project.threeDObjDetails.createdPoints.map((points) => {
          return points.map((point) => new THREE.Vector3(point.x, point.y, point.z));
        });

        // Set polygons or perform other operations
        setPolygons(vector3Points);
        setHistoryPolygons(vector3Points);
        setShowPopup(true);
      }

      if(project?.threeDObjDetails?.createdLineLoops.length > 0) {
        setInitCables(project.threeDObjDetails.createdLineLoops);
      }

      if (project?.threeDObjDetails?.scale) {
        setModelScale(project?.threeDObjDetails?.scale);
      }
      
      // if (project?.threeDObjDetails?.createdLineLoops.length > 0 && documentRef.current.panelGrids.length > 0) {
      //   const vector3Points = project?.threeDObjDetails?.createdLineLoops?.map((points) => {
      //     if (!points) return [];
      //     return points?.map((point) => {
      //       if (!point) return {};
      //       const vectorPoint = new THREE.Vector3(point.x, point.y, point.z);

      //       // Find the panel that matches this point
      //       const matchedPanel = documentRef.current.panelGrids.find((panel) => {
      //         console.log(documentRef.current.panelGrids, "panel");
      //         return panel.children.slice(1).some((ele) => {
      //           if (ele.name === "supported") {
      //             const panelCenter = calculatePanelCenter(ele);
      //             return vectorPoint.distanceTo(panelCenter) < 0.1; // THRESHOLD is a small value like 0.1 or less
      //           }
      //           return false;
      //         });
      //       });
      //       // Return the point with the matched panel (or null if no match)
      //       return {
      //         point: vectorPoint,
      //         panel: matchedPanel ? matchedPanel : null,
      //       };
      //     });
      //   });

      //   // Set polygons or perform other operations
      //   setCablingLoops(vector3Points.filter(ele => ele.length > 0));
      // }
      setHasProcessed(true); // Mark as processed
    }
  }, [
    hasProcessed,
    project?.threeDObjDetails?.createdPoints,
    project?.threeDObjDetails?.createdLineLoops,
    project?.threeDObjDetails?.scale,
    documentRef.current.panelGrids,
  ]);

  const handleDeleteClick = () => {
    if (selectedPoint) {
      const updatedCablingLoops = cablingLoops
        .map((loop) =>
          loop.filter((point) => {
            return (
              point.point.x !== selectedPoint.x ||
              point.point.y !== selectedPoint.y ||
              point.point.z !== selectedPoint.z
            );
          }),
        )
        .filter((loop) => {
          return loop.length >= 2;
        }); // Keep only loops with 2 or more points

      // Update the state with the new loops
      setCablingLoops(updatedCablingLoops);
      setSelectedPoint(null);
      setVisible(false);
    }
  };

  const handleDeletePoint = () => {
    setPolygons((prevPolygons) => {
      return prevPolygons
        .map((polygon, i) => {
          if (i !== deleteMeasurementPoint.polygonIndex) return polygon; // Leave other polygons unchanged

          // Step 1: Remove the point itself and adjacent points if the index is even
          const filteredPolygon = polygon.filter((_, j) => {
            if (j === deleteMeasurementPoint.pointIndex) {
              return false; // Remove the point itself
            } else if (j % 2 !== 0) {
              return false; // Remove adjacent points if the index is even
            }
            return true; // Retain points that don't meet the removal condition
          });

          // Step 2: Re-add points using addPointBetweenConsecutivePoints
          const newPoints = addPointBetweenConsecutivePoints(filteredPolygon);

          // Replace the polygon with the new points, or return null if fewer than 2 points remain
          return filteredPolygon.length < 2 ? null : newPoints;
        })
        .filter(Boolean); // Remove null polygons from the array
    });

    setHistoryPolygons((prevPolygons) => {
      return prevPolygons
        .map((polygon, i) => {
          if (i !== deleteMeasurementPoint.polygonIndex) return polygon; // Leave other polygons unchanged

          // Step 1: Remove the point itself and adjacent points if the index is even
          const filteredPolygon = polygon.filter((_, j) => {
            if (j === deleteMeasurementPoint.pointIndex) {
              return false; // Remove the point itself
            } else if (j % 2 !== 0) {
              return false; // Remove adjacent points if the index is even
            }
            return true; // Retain points that don't meet the removal condition
          });

          // Step 2: Re-add points using addPointBetweenConsecutivePoints
          const newPoints = addPointBetweenConsecutivePoints(filteredPolygon);

          // Replace the polygon with the new points, or return null if fewer than 2 points remain
          return filteredPolygon.length < 2 ? null : newPoints;
        })
        .filter(Boolean); // Remove null polygons from the array
    });

    // Reset the delete measurement point
    setDeleteMeasurementPoint({
      pointIndex: null,
      position: null,
      polygonIndex: null,
    });
  };

  const handleMouseMove = (event) => {
    if (isMouseDown.current) {
      controlsRef.current.enabled = false;
      controlsRef.current.update();
      setDisableRotation(true)
      return;
    }
    if (!modelRef?.current?.scene || !canvasRef.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    // Step 1: Initialize the mouse vector with correct values

    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    const mouse = new THREE.Vector2(x, y);

    // Step 2: Set up the raycaster
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, cameraRef.current);

    // Step 3: Check for intersections with the 3D model
    const intersects = raycaster.intersectObject(modelRef.current.scene, true);

    if (selectedMode === "Create mode" && intersects.length > 0) {
      const mousePosition = intersects[0].point;

      if (intersects.length > 0) {
        addEndPoint(intersects[0].point.clone());
      }
      // Step 4: Determine the message based on the points array
      if (points.length === 0) {
        setMessage(t("Start Point"));
      } else {
        const firstPoint = points[0];
        const distance = mousePosition.distanceTo(firstPoint);

        if (distance < 0.5) {
          setMessage(t("End Point"));
        } else if (points.length === 2) {
          setMessage(t("Move to the first point to close the loop"));
        } else {
          setMessage("");
        }
      }

      // Step 5: Project the 3D position into 2D screen space for the div position
      const vector = mousePosition.clone().project(cameraRef.current);
      const x = (vector.x * 0.5 + 0.5) * canvasRef.current.clientWidth;
      const y = (-vector.y * 0.5 + 0.5) * canvasRef.current.clientHeight;
      // Step 6: Update the div position
      setDivPosition({ top: y + 20, left: x });
    } else {
      setMessage("");
    }
  };
  
  // const mouseMove = (event) => {
  //   console.log(controlsRef.current, disableRotation,"disable rotation")
  //   if (!modelRef?.current?.scene || !canvasRef.current) return;

  //   const rect = canvasRef.current.getBoundingClientRect();
  //   // Step 1: Initialize the mouse vector with correct values

  //   const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
  //   const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

  //   const mouse = new THREE.Vector2(x, y);

  //   // Step 2: Set up the raycaster
  //   const raycaster = new THREE.Raycaster();
  //   raycaster.setFromCamera(mouse, cameraRef.current);

  //   if (isMouseDown.current) {
  //     controlsRef.current.enabled = false;
  //     controlsRef.current.update();
  //     setDisableRotation(true)


  //     const intersect = raycaster.intersectObjects(doc.terrainGroup.children, true);

  //     if (intersect.length) {
  //       const intersectPoint = intersect[0].point.clone();
  //       const dragCurrent = intersectPoint.clone();
  //       if (!doc.selectedPanelGrid.userData.beforeDragPosition)
  //         doc.selectedPanelGrid.userData.beforeDragPosition =
  //           doc.selectedPanelGrid.position.clone();
  //       const dragDelta = dragCurrent.sub(dragStart);
  //       doc.selectedPanelGrid.position
  //         .copy(doc.selectedPanelGrid.userData.beforeDragPosition)
  //         .add(dragDelta);
  //       // doc.selectedPanelGrid.position.copy(intersectPoint).add({...dragOffset});
  //       // dragDelta.applyQuaternion(doc.selectedPanelGrid.quaternion);
  //       // doc.selectedPanelGrid.position.add(dragDelta);
  //       doc.selectedPanelGrid.disposeOccupancy();
  //       doc.selectedPanelGrid.recomputeOccupancy({
  //         targets: doc.terrainGroup.children,
  //       });

  //       doc.selectedPanelGrid.redrawOccupancy(sceneRef.current);
  //     }
  //   }
  // };

  // const mouseUp = () => {
  //   isMouseDown.current = false;
  //   setDisableRotation(false);

  //   controlsRef.current.enabled = true;
  //   controlsRef.current.update();
  //   if (doc.selectedPanelGrid) {
  //     doc.selectedPanelGrid.disposeOccupancy();
  //     doc.selectedPanelGrid.recomputeOccupancy({
  //       targets: doc.terrainGroup.children,
  //     });

  //     doc.selectedPanelGrid.redrawOccupancy(sceneRef.current);
  //     // canvasRef.current.removeEventListener("mousemove", mouseMove);
  //     // canvasRef.current.removeEventListener("mouseup", mouseUp);
  //   }
  // };

  const panelGrids = documentRef.current.panelGrids;
  const removePanelGrid = documentRef.current.removePanelGrid;
  const resetSelection = documentRef.current?.resetSelection;
  const selectedPanelGrid = documentRef.current?.selectedPanelGrid;

  function handleRemovePanelGrid(panelGrid) {
    // Find the index of the panelGrid to remove
    // resetSelection();

    const index = panelGrids.indexOf(panelGrid);
    const grid = panelGrids[index];

    // Remove all entries from cablingLoops where the panel matches the grid to remove
    const updatedCablingLoops = cablingLoops
      .map(
        (loop) =>
          loop.filter((point) => {
            return !grid.children
              .slice(1)
              .filter((ele) => ele.name)
              .find((e) => e.id === point.panel.id);
          }), // Assuming 'grid.id' is the panel identifier
      )
      .filter((loop) => loop.length > 0); // Remove empty loops

    // Update the state or variable holding cablingLoops
    setCablingLoops(updatedCablingLoops);
    removePanelGrid(grid, project.threeDObjDetails.createdPoints, cableIndexes(cablingLoops));

    grid?.parent?.remove(grid);

    if(documentRef.current.resetActive) {
      documentRef.current.resetActive();
    };
    /* let boxFrame =  documentRef.current.boxFrame;
    if (boxFrame) {
      boxFrame?.parent?.remove(boxFrame);
      sceneRef.current.remove(boxFrame);
      boxFrame = null;
    } */
    setIconPosition(null);
  }

  const calculateAngle = (A, B, C) => {
  
    // Vector components for AB and BC
    const ABx = A.x - B.x;
    const ABz = A.z - B.z;
    const BCx = C.x - B.x;
    const BCz = C.z - B.z;
  
    // Compute the cross and dot products
    const dotProduct = ABx * BCx + ABz * BCz;
    const crossProduct = ABx * BCz - ABz * BCx;
  
    // Compute angle in radians using atan2, converting directly to degrees
    const angleDegrees = Math.atan2(crossProduct, dotProduct) * (180 / Math.PI);

    return angleDegrees;
  }

  const updateCompassRotation = () => {
    if(!cameraRef.current || !controlsRef.current) {
      return;
    };
    const xAdjust = controlsRef.current.target.x;
    const zAdjust = controlsRef.current.target.z;
    const rotationAmount = calculateAngle(
      {x:xAdjust, z:zAdjust+1},
      {x:xAdjust, z:zAdjust},
      {x:cameraRef.current.position.x, z:cameraRef.current.position.z}
    );
    compassRef.current.style.transform = `rotate(${-rotationAmount}deg)`;
  };

  useEffect(() => {
    const interval = setInterval(updateCompassRotation, 50);
    return () => clearInterval(interval);
  }, []);

  const loader = new THREE.TextureLoader();
  const moveTexture = loader.load(MoveIcon);
  const rotateTexture = loader.load(RotateIcon);
  const deleteTexture = loader.load(deleteIcon);

  const handleMouseClick = (event) => {

    const rect = canvasRef.current.getBoundingClientRect();

    // Step 1: Initialize the mouse vector with correct values
    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    const mouse = new THREE.Vector2(x, y);

    // Step 2: Set up the raycaster
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, cameraRef.current);
    if (selectedMode === "Create mode" || selectedMode === "Scale mode") {
      if (clickTimeout.current) return; // Skip if double-click is detected

      clickTimeout.current = setTimeout(() => {
        clickTimeout.current = null; // Reset the timeout

        // Get the normalized mouse coordinates (-1 to 1)
        const { offsetX, offsetY } = event;
        const x = (offsetX / rendererRef.current.domElement.clientWidth) * 2 - 1;
        const y = -(offsetY / rendererRef.current.domElement.clientHeight) * 2 + 1;

        if (
          isEditing.current ||
          deleteMeasurementPoint.pointIndex ||
          deleteMeasurementPoint.polygonIndex
        )
          return;
        if (modelRef.current && (selectedMode === "Create mode" || selectedMode === "Scale mode")) {
          const intersects = raycaster.intersectObject(modelRef.current.scene, true);
          if (intersects.length > 0) {
            const intersection = intersects[0];
            addPoint(intersection.point);
          }
        }
      }, 200);
    }
  };

  const handleMouseUp = () => { };

  const handlePanelGridClick = (event) => {
    return; // comment out
    const rect = canvasRef.current.getBoundingClientRect();

    // Step 1: Initialize the mouse vector with correct values
    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    const mouse = new THREE.Vector2(x, y);

    // Step 2: Set up the raycaster
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, cameraRef.current);

    // if (resetSelection) {
    //   resetSelection();
    // }

    if (!isPanelPlacing) return;

    const hits = raycaster.intersectObjects(
      documentRef.current.panelGrids.map((pg) => pg.selectionMesh),


    );
    if (doc.selectedPanelGrid) {
      const existingMoveIcon = doc.selectedPanelGrid.children.filter(
        (child) => child.name === "rotate-icon" || child.name === "delete-icon" || child.name === "move-icon"
      );

      const hitsRotateIcon = raycaster.intersectObjects(existingMoveIcon, true);
      if (hitsRotateIcon[0]?.object?.name === "rotate-icon") {
        event.stopPropagation()
        eventEmitter.emit("rotate", event);
        return;
      }

      if (hitsRotateIcon[0]?.object.name === "delete-icon") {
        event.stopPropagation()
        removeMoveIcon(event);
        handleRemovePanelGrid(doc.selectedPanelGrid);
        return;
      }

      if (hitsRotateIcon[0]?.object?.name === "move-icon") {
        console.log("hitsrotateicon", hitsRotateIcon[0]?.object?.name);
        isMouseDown.current = true;
        eventEmitter.emit("move", event);
        return;
      }

    }

    if (hits.length > 0) {
      const clickedGrid = documentRef.current.panelGrids.find(
        (pg) => pg.selectionMesh.id === hits[0].object.id,
      );

      doc.selectedPanelGrid = clickedGrid;

      if (clickedGrid) {
        const childHits = raycaster.intersectObjects(clickedGrid.children.slice(1), true);
        if (childHits.length > 0) {
          const panelGrid = clickedGrid;

          const existingMoveIcon = panelGrid.children.filter(
            (child) =>
              child.name === "move-icon" ||
              child.name === "rotate-icon" ||
              child.name === "delete-icon",
          );

          if (existingMoveIcon) {
            const intersects = raycaster.intersectObjects(existingMoveIcon);
            if (intersects.length > 0) {
              // if (intersects[0]?.object.name === "move-icon") {
              //   console.log("doc.selectedPanelGrid", doc.selectedPanelGrid);
              //   isMouseDown.current = true;

              //   if (!doc.selectedPanelGrid) return;
              //   // resetSelection();

              //   if (childHits.length > 0) {
              //     console.log(childHits.length, "hitslength");

              //     isMouseDown.current = true;
              //     controlsRef.current.enabled = false;
              //     controlsRef.current.update();
              //     setDisableRotation(true);

              //     // Calculate the drag offset
              //     const intersectPoint = childHits[0].point;
              //     dragStart = intersectPoint.clone();
              //     canvasRef.current.addEventListener("mousemove", mouseMove);
              //     canvasRef.current.addEventListener("mouseup", mouseUp);
              //   }
              // }
              if (intersects[0]?.object.name === "rotate-icon") {
                console.log("hitsicon", intersects[0]?.object.name);
                eventEmitter.emit("rotate")
              }
              return;
            } else if (existingMoveIcon.length > 0) {
              existingMoveIcon.forEach((e) => {
                panelGrid?.remove(e);
              });
            }
          }

          // Step 3: Get the bounding box of the panelGrid
          const box = new THREE.Box3().setFromObject(panelGrid.selectionMesh);

          // Calculate the top right corner based on box dimensions
          // const topRightCorner = new THREE.Vector3(box.max.x, box.max.y, box.max.z);

          const rightCorner = new THREE.Vector3(box.max.x, box.max.y, box.max.z);

          // Step 4: Project the top right corner to screen coordinates
          const position = rightCorner.clone().project(cameraRef.current);

          const moveMaterial = new THREE.SpriteMaterial({
            map: moveTexture,
            color: 0xffffff,
            depthTest: false,
          });
          const moveIcon = new THREE.Sprite(moveMaterial);
          // Calculate the panel width and height based on the bounding box dimensions
          moveIcon.rotation.x = -Math.PI / 2; // Rotate to align with the panel horizontally
          moveIcon.rotation.y = Math.PI / 2;

          moveIcon.position.set(
            panelGrid.size.x * 0.5,
            panelGrid.size.y * 0.5,
            0.2,
          );

          // Scale the icon to an appropriate size
          moveIcon.scale.set(1, 1, 1);

          // Initially make the icon invisible
          moveIcon.visible = true;

          // Set the name for identification purposes
          moveIcon.name = "move-icon";

          // Add the iconMesh to the panelGrid
          panelGrid.add(moveIcon);

          // Convert the projected position to screen coordinates
          const screenX = (panelGrid.size.x + 0.5) * rect.width;
          const screenY = (panelGrid.size.y + 0.5) * rect.height;

          const rotateMaterial = new THREE.SpriteMaterial({
            map: rotateTexture,
            color: 0xffffff,
            depthTest: false,
          });
          const rotateIcon = new THREE.Sprite(rotateMaterial);
          // Calculate the panel width and height based on the bounding box dimensions
          rotateIcon.rotation.x = -Math.PI / 2; // Rotate to align with the panel horizontally
          rotateIcon.rotation.y = Math.PI / 2;

          rotateIcon.position.set(
            panelGrid.size.x * 1,
            panelGrid.size.y * 0.05,
            0.2,
          );

          // Scale the icon to an appropriate size
          rotateIcon.scale.set(1.5, 1.5, 1.5);

          // Initially make the icon invisible
          rotateIcon.visible = true;

          // Set the name for identification purposes
          rotateIcon.name = "rotate-icon";

          // Add the iconMesh to the panelGrid
          panelGrid.add(rotateIcon);

          const deleteMaterial = new THREE.SpriteMaterial({
            map: deleteTexture,
            depthTest: false,
          });
          const deleteIcon = new THREE.Sprite(deleteMaterial);
          deleteIcon.rotation.x = -Math.PI / 2; // delete to align with the panel horizontally
          deleteIcon.rotation.y = Math.PI / 2;

          deleteIcon.position.set(
            panelGrid.size.x * 1 + 1.5,
            panelGrid.size.y * 0.05 + 2,
            0.2,
          );

          // Scale the icon to an appropriate size
          deleteIcon.scale.set(2, 2, 2);

          // Initially make the icon invisible
          deleteIcon.visible = true;

          // Set the name for identification purposes
          deleteIcon.name = "delete-icon";

          panelGrid.add(deleteIcon);
        }
      }
    }
  };


  const getFileNameWithDimensions = (ele) => {
    const dimensionRegex = /\s?\d+\s*x\s*\d+\s*x\s*\d+/;
    // Remove old dimensions from the URL if they exist
    const baseFileName = ele.obj
      .split("/")
      .pop()
      .replace(dimensionRegex, "")
      .replaceAll(".obj", "");

    // Prepare new dimensions if they exist
    const newDimensions = [ele?.height, ele?.width, ele?.length].filter(Boolean).join("x");

    // Create the final file name by adding new dimensions (if any)
    return `${baseFileName.trim()}${newDimensions ? " " + newDimensions : ""}`;
  };


  const removeMoveIcon = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();

    // Step 1: Initialize the mouse vector with correct values
    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    const mouse = new THREE.Vector2(x, y);

    // Step 2: Set up the raycaster
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, cameraRef.current);

    panelGrids.forEach((panelGrid) => {
      const moveIcon = panelGrid.children.find((ele) => ele.name === "move-icon");
      moveIcon.remove(); // Remove each move-icon
    });
  };

  // camera positions
  const cameraPositions = [
    { position: [-10, 25, 5], fov: 55, layer: 1 },
    { position: [15, 15, 17], fov: 55, layer: 1 },
    { position: [-5, 20, -10], fov: 55, layer: 1 },
    { position: [3, 15, 0], fov: 55, layer: 1 },
    { position: [0, 15, 25], fov: 55, layer: 1 },
    { position: [0, 20, 0], fov: 55, layer: 1 },
  ];

  const handleRightClick = (event) => {
    // Prevent the default context menu from appearing
    if (mode === "Scale mode") {
      event.preventDefault();
      setScalePoints(points);
      setPoints([]);
      setDrawingLine(false);
    }

    // Cancle measurement or scaling selection
    else if (selectedMode === "Create mode" || selectedMode === "Scale mode") {
      event.preventDefault();
      setPoints([]);
      setDrawingLine(false);
    }
  };

  // Statistics display
  const TAB_KEYS = {
    MEASUREMENT: "MEASUREMENT",
    PANELGRIDS: "PANELGRIDS",
  };

  const [selectedTab, setSelectedTab] = useState(TAB_KEYS.MEASUREMENT);
  const [statisticsPosition, setStatisticsPosition] = useState(0);
  const [arrowRotation, setArrowRotation] = useState(0);

  const tabs = useMemo(() => {
    const tabs = [];

    tabs.push({
      key: TAB_KEYS.MEASUREMENT,
      label: t("3D_VIEW_MEASUREMENT"),
    });

    tabs.push({
      key: TAB_KEYS.PANELGRIDS,
      label: t("3D_VIEW_PANELS"),
    });
    
    return tabs;
  }, []);

  const handleTabClick = useCallback(
    (tabKey) => {
      setSelectedTab(tabKey);
    },
    [setSelectedTab],
  );

  const hideStatistics = () => {
    if(statisticsPosition === 0) {
      setStatisticsPosition(270);
      setArrowRotation(180);
    }else {
      setStatisticsPosition(0);
      setArrowRotation(0);
    }
  };

  return (
    <div ref={canvasContainerRef}>
        <Suspense fallback={null}>

      <Canvas
        style={{
          position: "relative",
          width: "100%",
          height: fullscreen ? "100%" : "85vh"
        }}
        gl={{ preserveDrawingBuffer: true }}
        camera={{ fov: 55, position: [0, 20, 35] }}
        onCreated={({ camera, scene, gl }) => {
          cameraRef.current = camera;
          sceneRef.current = scene;
          rendererRef.current = gl;
        }}
        ref={canvasRef}
        onDoubleClick={handleDoubleClick}
        onMouseMove={handleMouseMove}
        onMouseDown={handlePanelGridClick}
        onMouseUp={handleMouseUp}
        onClick={handleMouseClick}
        onContextMenu={handleRightClick}
      >
        <color args={["lightblue"]} attach="background" />

        {/* Extra cameras */}
        {cameraPositions.map(({ position, fov, layer, rotation, lookAt }, index) => (
          <PerspectiveCamera
            key={index}
            ref={(ref) => {
              if(ref) {
                extracamerasRef.current[index] = ref;
                ref.layers.disableAll();
                ref.layers.enable(layer);
              }
            }}
            position={position}
            fov={fov}
          />
        ))}

        <Suspense fallback={null}>
          <group>
            {/* Model component */}

            <Model
              ref={modelRef}
              scale={modelScale}
              // url={HouseModel}
              mtlUrl={mtlUrl}
              objUrl={objUrl}
              setPanelRendererReload={setPanelRendererReload}
            />

            {/* Cursor position */}
            <MouseLogger
              modelRef={modelRef}
              cylinderref={cylinderref}
              points={points}
              polygons={polygons}
              addEndPoint={addEndPoint}
              drawingLine={drawingLine}
              startPoint={startPoint}
              selectedMode={selectedMode}
              deleteMeasurementPoint={deleteMeasurementPoint}
              historyPoints={historyPolygons}
            />
            {/* manual and auto cabeling feature */}
            <Cabeling
              documentRef={documentRef}
              canvasRef={canvasRef}
              cameraRef={cameraRef}
              mode={mode}
              setCablingLoops={setCablingLoops}
              cablingLoops={cablingLoops}
              modelRef={modelRef}
              setMode={setMode}
              setSelectedPoint={setSelectedPoint}
              setVisible={setVisible}
              setPosition={setPosition}
              editor={editor}
              setHistoryCablingPolygons={setHistoryCablingPolygons}
            />

            {/* Measurement feature */}
            <Measurement
              setDisableRotation={setDisableRotation}
              polygons={polygons}
              controlsRef={controlsRef}
              setPolygons={setPolygons}
              setHistoryPolygons={setHistoryPolygons}
              points={points.length > 0 ? points : scalePoints}
              drawingLine={drawingLine}
              startPoint={startPoint}
              endPoint={endPoint}
              modelRef={modelRef}
              isEditing={isEditing}
              setDeleteMeasurementPoint={setDeleteMeasurementPoint}
              setVisible={setVisible}
              canvasRef={canvasRef}
              cameraRef={cameraRef}
              selectedMode={selectedMode}
              isPanelPlacing={isPanelPlacing}
            />

            {/* Cylinder mesh below cursor reference */}
            <mesh rotateX={Math.PI * 0.5} ref={cylinderref}>
              <cylinderGeometry args={[1.5, 1.5, 0.1, 16]} />
              <meshStandardMaterial color="teal" transparent opacity={0.5} depthTest={false} />
            </mesh>

            <PanelGridRenderer
              key={panelRendererReload}
              modelRef={modelRef}
              documentRef={documentRef}
              onSolarDataChange={handleSolarDataChange}
              project={project}
              productObjFiles={productObjFiles}
              setPanelsLoaded={setPanelsLoaded}
            />

            {/* Panel grid placement */}
            {isPanelPlacing && (
              <PanelGridPlacement
                modelRef={modelRef}
                cursorRef={cylinderref}
                controlsRef={controlsRef}
                documentRef={documentRef}
                cameraRef={cameraRef}
                onSolarDataChange={handleSolarDataChange}
                dispatch={dispatch}
                project={project}
                ProjectActions={ProjectActions}
                canvasRef={canvasRef}
                setIconPosition={setIconPosition}
                setDisableRotation={setDisableRotation}
                mouseDown={isMouseDown}
                threejsRef={threejsRef}
                setCablingLoops={setCablingLoops}
                cablingLoops={cablingLoops}
                selectedMode={selectedMode}
                setStatisticsReload={setStatisticsReload}
              />
            )}
          </group>
        </Suspense>
        <ambientLight intensity={1.5} ref={ambientLightRef} />

        {/* <directionalLight
            intensity={1.5}
            position={[0, 100, 0]}
            color={"#ffffff"} 
          /> */}

        {/* Controls */}
        <OrbitControls
          ref={controlsRef}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={0}
          enableDamping
          dampingFactor={0.05}
          screenSpacePanning={false}
          disableRotation={disableRotation}
        />

        <Environment files={EnvmapHDRI} intensity={1.5} />
      </Canvas>
      </Suspense>
      {/* </div> */}
      {/* Left side icons */}
      <div className="absolute top-4 left-4">
      <button className="bg-[#eff7fa] px-4 py-1 rounded-2xl shadow text-[#263542]" onClick={handleAssistance}>
          {t("Assistance")}
        </button>
        <label className="flex items-center gap-2 mt-2 bg-[#eff7fa] px-4 py-1 rounded-2xl shadow  text-[#263542]">
          <input
            type="checkbox"
            value=""
            className="sr-only peer"
            onChange={handleToggleRadiation}
          />
          <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:dark:bg-primary-color peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          {t("Show radiation")}
        </label>
        <button
          onClick={handleSave}
          className="bg-[#eff7fa] flex gap-2 px-2 py-1 rounded-2xl shadow mr-2 text-[#263542] mt-2"
        >
          <img src={Lock} /> {t("Save")}
        </button>
      </div>

      <div className="absolute top-[40%] md:top-[30%] left-4 py-2 px-1 flex flex-col items-center gap-2 bg-[#47647e] rounded-[20px]">
        <Tooltip
          position="right"
          tooltipText={t("3D_VIEW_RESET_VIEW")}
          vertical="-5px"
        >
          <img
            src={Home}
            className="cursor-pointer h-7 w-7 icon home"
            onClick={resetView}
            alt="Home"
            ref={homeIconRef}
          />
        </Tooltip>

        <Tooltip
          position="right"
          tooltipText={t("3D_VIEW_ROTATE")}
          vertical="5px"
        >
          <img
            src={Planet}
            className="cursor-pointer h-7 w-7 icon planet"
            onClick={selectPlanet}
            ref={planetIconRef}
          />
        </Tooltip>

        <Tooltip
          position="right"
          tooltipText={t("3D_VIEW_DRAG")}
          vertical="2px"
        >
          <img
            src={HandWhite}
            className="cursor-pointer h-7 w-7 icon hand"
            onClick={togglePan}
            alt="Hand"
            ref={handIconRef}
          />
        </Tooltip>

        <Tooltip
          position="right"
          tooltipText={t("3D_VIEW_SET_AREA")}
          vertical="-10px"
          horizontal="5px"
        >
          <img
            src={Polygon}
            ref={polygonIcon}
            className="w-5 h-5 cursor-pointer icon cabeling"
            onClick={handleMeasurementClick}
          />
        </Tooltip>

        <Tooltip
          position="right"
          tooltipText={t("3D_VIEW_PLACE_SOLAR_PANELS")}
          vertical="-10px"
          horizontal="5px"
        >
          <img
            src={BlueSolarPanel}
            className="w-5 h-5 cursor-pointer icon solarpanel"
            onClick={handleSolarPanelClick}
            ref={solarPanelIcon}
          />
        </Tooltip>

        <Tooltip
          position="right"
          tooltipText={t("3D_VIEW_CABELING")}
          horizontal="5px"
        >
          <img
            src={CabelingIcon}
            ref={cabelingIcon}
            className="w-5 h-5 cursor-pointer icon cabeling"
            onClick={handleCabelingClick}
          />
        </Tooltip>

        <Tooltip
          position="right"
          tooltipText={t("3D_VIEW_SCALE_MEASUREMENTS")}
          vertical="-10px"
          horizontal="5px"
        >
          <img
            src={ScaleWhiteIcon}
            ref={scaleIconRef}
            className="w-5 h-5 cursor-pointer scaleicon"
            onClick={handleScaleClick}
          />
        </Tooltip>

        <Tooltip
          position="right"
          tooltipText={t("3D_VIEW_TAKE_PICTURES")}
          vertical="-5px"
          horizontal="-3px"
        >
          <img
            src={ScreenShotIcon}
            className="w-8 h-8 cursor-pointer icon"
            onClick={screenShotClick}
          />
        </Tooltip>

      </div>

      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onCancel={handleCancelScreenshot}
        onConfirm={handleConfirmScreenshot}
      />

      <div className="absolute top-4 right-4">
        <Compass 
        ref={compassRef}
        fill={getColorFromCSS("--blue")} />
      </div>

      {/* Right side icons */}
      <div className="absolute top-28 right-4 py-4 px-3 flex flex-col items-center gap-1 space-y-1 bg-[#47647e] rounded-[20px]">

        <Tooltip
          position="left"
          tooltipText={t("3D_VIEW_FULLSCREEN")}
          horizontal="5px"
        >
          {fullscreen ?
            <FullscreenMinimize className="w-5 h-5 cursor-pointer" stroke={getColorFromCSS("--light-theme-background")} onClick={toggleFullscreen} /> :
            <FullscreenMaximize className="w-5 h-5 cursor-pointer" fill={getColorFromCSS("--light-theme-background")} stroke={getColorFromCSS("--light-theme-background")} onClick={toggleFullscreen} />
          }
        </Tooltip>

        <Tooltip
          position="left"
          tooltipText={t("3D_VIEW_FULL_WIDTH")}
          vertical="-10px"
          horizontal="5px"
        >
          <img className="w-5 h-5 cursor-pointer" src={MaxWidth} onClick={handleMaxwidth} />
        </Tooltip>

        <Tooltip
          position="left"
          tooltipText={t("3D_VIRW_ZOOM_IN")}
          vertical="-8px"
          horizontal="5px"
        >
          <button className="cursor-pointer" onClick={zoomIn}>
            <img src={ZoomIn} />
          </button>
        </Tooltip>

        <Tooltip
          position="left"
          tooltipText={t("3D_VIEW_ZOOM_OUT")}
          vertical="-5px"
        >
          <button className="cursor-pointer" onClick={zoomOut}>
            <img src={ZoomOut} />
          </button>
        </Tooltip>

        <Tooltip
          position="left"
          tooltipText={t("3D_VIEW_UNDO")}
          vertical="-8px"
          horizontal="5px"
        >
          <button className="w-5 h-5 cursor-pointer" onClick={handleUndoRedo}>
            <img src={UndoIcon} height={40} width={40} />
          </button>
        </Tooltip>

        <Tooltip
          position="left"
          tooltipText={t("3D_VIEW_REDO")}
          horizontal="5px"
        >
          <button className="w-5 h-5 cursor-pointer" onClick={redo}>
            <img src={Redo} height={40} width={40} />
          </button>
        </Tooltip>

      </div>
      {startRadio && (
        <RadioContainer
          setSelectedMode={setSelectedMode}
          selectedMode={selectedMode}
          isEditing={isEditing}
          setPoints={setPoints}
          setDrawingLine={setDrawingLine}
        />
      )}

      {(selectedPoint || deleteMeasurementPoint.position) && (
        <img
          ref={deleteIconRef}
          src={deleteIcon}
          alt="Delete Icon"
          style={{
            position: "absolute",
            height: "50px",
            width: "50px",
            display: visible ? "block" : "none",
            zIndex: 1000,
            backgroundColor: "white",
            borderRadius: "5px",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            top: position.top || deleteMeasurementPoint?.position?.top,
            left: position.left || deleteMeasurementPoint?.position?.left,
            transform: "translate(-50%, -50%)", // Center the icon on the click point
          }}
          onClick={deleteMeasurementPoint?.position?.top ? handleDeletePoint : handleDeleteClick}
        />
      )}

      {/* Selection Menu */}
      {showSelectionMenu &&
        showSelectionMenu.from === "solarPanel" &&
        productObjFiles &&
        productObjFiles.length > 0 && (
          <div className="absolute w-auto py-1 px-3 left-[30%] lg:left-[35%] top-[25%] bg-white rounded-lg flex flex-col ">
            <div className="flex items-center justify-end gap-9 ">
              <label className="text-lg text-blue-400 align-middle">{t("Select Panel")}</label>
              <button
                onClick={() => {
                  setShowSelectionMenu({ open: false, from: "" });
                }}
              >
                <img className="w-4 h-4" src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="flex justify-start">
              <ul className="mt-1">
                {productObjFiles.map((ele, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelection(ele)}
                    className="py-1 text-center cursor-pointer"
                  >
                    {getFileNameWithDimensions(ele) || `Panel ${index + 1}`}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

      {showSelectionMenu.open && showSelectionMenu.from === "cabeling" && (
        <div
          className="absolute py-2 px-3 left-[45%] top-[40%] bg-white rounded-lg flex items-center shadow-md"
          ref={popupRef}
        >
          <div className="flex gap-3">
            <div className="relative flex items-center text-gray-700 cursor-pointer">
              <img src={cablingBlueIcon} alt="Cabling Icon" className="w-4 h-4" />
              <img
                src={pluIcon}
                alt="Auto Icon"
                className="h-2 w-2 absolute right-[-10px] top-[-5px]"
              />
            </div>

            <div
              onClick={() => handleSelection("auto")}
              className="relative flex items-center text-gray-700 cursor-pointer"
            >
              <span>{t("Auto")}</span>
              <img
                src={aiIcon}
                alt="Auto Icon"
                className="h-3 w-3 absolute right-[-15px] top-[-5px]"
              />
            </div>
            <span className="ml-1 text-gray-300">|</span>
            <div
              onClick={() => handleSelection("manual")}
              className="relative flex items-center text-gray-700 cursor-pointer"
            >
              <span>{t("Manual")}</span>
              <img
                src={penIcon}
                alt="Cabling Icon"
                className="h-3 w-3 absolute right-[-15px] top-[-5px]"
              />
            </div>
          </div>
        </div>
      )}

      {iconPosition && (
        <div
          ref={iconsRef}
          style={{
            position: "absolute",
            left: `${iconPosition.x}px`,
            top: `${iconPosition.y}px`,
            zIndex: 1000,
            backgroundColor: "rgba(45, 71, 100, 0.8)",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={deleteIcon}
            alt="Delete"
            style={{ cursor: "pointer", height: 40, width: 40 }}
            onClick={(event) => {
              /* delete logic */
              removeMoveIcon(event);
              handleRemovePanelGrid(iconPosition?.panelGrid);
            }}
          />
        </div>
      )}

      <div style={{
              position: "absolute",
              right: "0px",
              bottom: "0px",
              width: "300px",
              height: "175px", 
              overflow: "hidden",
              pointerEvents: "none"
            }}>
        <div style={{
              position: "absolute",
              right: "12px",
              bottom: "12px",
              display: "flex",
              transform: `translateX(${statisticsPosition}px)`,
              transition: "transform 0.5s ease-in-out",
              pointerEvents: "auto"
            }}>
          <div onClick={hideStatistics} style={{
            position: "absolute",
            top: "50%",
            right:"102%",
            width: "25px",
            height: "25px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            transition: "transform 0.5s ease-in-out",
            transform: `rotate(${arrowRotation}deg)`
            }}>
            <HideStatistics
              stroke={getColorFromCSS("--blue")} style={{
                width: "17px",
                height: "17px",
                transform: "translateX(1px)"
              }}
            />
          </div>
          <div className=" bg-white rounded-lg overflow-hidden max-h-40 w-64 h-full flex flex-col" >
            <div className="w-full">
              <TabBar tabs={tabs} clickHandler={handleTabClick} selected={selectedTab}></TabBar>
            </div>
            {selectedTab === TAB_KEYS.PANELGRIDS && solardata.length === 0 &&
              <div class="relative h-screen max-h-[calc(100%-2.4rem)]">
                <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                  {t("3D_VIEW_NO_PANELS_SET")}
                </div>
              </div>
            }
            {selectedTab === TAB_KEYS.PANELGRIDS && solardata.length > 0 &&
              <div key={statisticsReload} className="overflow-y-scroll max-h-[calc(100%-2.4rem)]">
              
                {solardata.map((data, index) => {
                  const counts = panelGrids?.[index]?.children?.filter(
                    (ele) => ele.name === "supported" && !ele?.isNotVisible,
                  )?.length;

                  const calculatedTotalsSolargisData = data?.outputs?.totals?.fixed?.E_y;
                  const azimuth = data?.details?.azimuth?.toFixed(2);

                  return (
                    <div key={index} className="flex flex-col px-2.5 pb-2.5 text-sm">
                      <div className="flex gap-2">
                        <h4
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            flex: 1,             
                            textAlign: "center",
                          }}
                        >
                          {t("Panel Grid")} {index + 1}
                          <img
                          src={deleteIcon}
                          onClick={() => {
                            // const grid = panelGrids[index];
                            // removePanelGrid(grid);
                            // grid?.parent?.remove(grid);
                            // eventEmitter.emit("undo");
                            handleRemovePanelGrid(panelGrids[index])
                          }}
                          className="cursor-pointer h-7 w-7"
                          alt="delete icon"
                        />
                        </h4>
                      </div>
                      <div>
                        <span style={{
                          display: "block"
                        }}>
                          {t("Yearly Totals")}: {(calculatedTotalsSolargisData * counts).toFixed(2)} kWh
                        </span>
                        <span style={{
                          display: "block"
                        }}>
                          {t("Panel Count")}: {counts}
                        </span>
                        <span style={{
                          display: "block"
                        }}>
                          {t("Azimuth")}: {azimuth}°
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            }
            {selectedTab === TAB_KEYS.MEASUREMENT && polygons.length === 0 && 
              <div class="relative h-screen max-h-[calc(100%-2.4rem)]">
                <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                  {t("3D_VIEW_NO_MEASUREMENTS_SET")}
                </div>
              </div>
            }
            {selectedTab === TAB_KEYS.MEASUREMENT && polygons.length > 0 &&
              <div className="overflow-y-scroll max-h-[calc(100%-2.4rem)]">
              
                <PolygonInfoPopup
                polygons={polygons}
                onClose={handleClosePopup}
                setPolygons={setPolygons}
                cablingLoops={cablingLoops}
                setHistoryPolygons={setHistoryPolygons}
                editor={editor}
                />
              </div>
            }
          </div>
        </div>
      </div>
      
      {message && (
        <div
          style={{
            position: "absolute",
            top: divPosition.top,
            left: divPosition.left,
            padding: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            borderRadius: "5px",
            pointerEvents: "none",
          }}
        >
          {message}
        </div>
      )}
      {scalePoints?.[1] && scalePopup && (
        <MeasurementPopup
          setModelScale={setModelScale}
          modelScale={modelScale}
          measuredValue={scalePoints[1]?.distanceTo(scalePoints[0])?.toFixed(2)}
          setScalePoints={setScalePoints}
        />
      )}
    </div>
  );
};

export default CreateProto1;
