import { Checkbox } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  FilterPossibilityArray,
  ProductFilterKeys,
  ProductFilters,
  simulatePrefPossibility,
  useValidPreferenceMap,
} from "src/hooks";

import { ProductWithPrice } from "src/db-types";
import { filterProducts } from "src/helpers";
import { ValueOf, getNumber } from "src/utils";

import * as S from "./styles";

import { ProjectActions } from "src/redux/actionCreators";
import { getApplicaitonFilters } from "src/redux/application/selector";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

interface FilterCardProps {
  onPreferenceChange?: (filters: ProductFilters) => void;
  preferences?: ProductFilters;
  filteredProducts?: ProductWithPrice[];
  unfilteredProducts?: ProductWithPrice[];
}
export const SolarPanelFilters: React.FC<FilterCardProps> = (props: FilterCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { preferences: preferencesRedux } = useSelector(ProjectSelectors.getSolarPanel);
  const filteredProductsRedux = useSelector(ProductSelectors.getFilteredSolarPanels);
  const unfilteredProductsRedux = useSelector(ProductSelectors.getSolarPanels);
  const defaultFilters = useSelector(getApplicaitonFilters);

  const preferences = useMemo(() => {
    if (props.preferences) return props.preferences;

    return { ...preferencesRedux };
  }, [preferencesRedux, props.preferences]);

  const filteredProducts = useMemo(() => {
    if (props.filteredProducts) return props.filteredProducts;
    return filteredProductsRedux;
  }, [filteredProductsRedux, props.filteredProducts]);

  const unfilteredProducts = useMemo(() => {
    if (props.unfilteredProducts) return props.unfilteredProducts;
    return unfilteredProductsRedux;
  }, [props.unfilteredProducts, unfilteredProductsRedux]);

  const possibilityFilters = useValidPreferenceMap({
    preferences,
    products: filteredProducts,
  });

  const unfilteredPossibility = useValidPreferenceMap({
    preferences: defaultFilters,
    products: unfilteredProducts,
  });

  const onPreferenceChange = useCallback(
    (key: ProductFilterKeys, value: ValueOf<ProductFilters>): void => {
      const updatedPreferences: Partial<ProductFilters> = {
        [key]: value,
      };
      if (key === "applicableMaxCapacity") {
        const productsInRange = filterProducts({
          filters: {
            ...defaultFilters,
            applicableMaxCapacity: getNumber(value),
          },
          products: unfilteredProducts,
        });
        const totalInRange = productsInRange.length;

        const filtersAfterRange = simulatePrefPossibility({
          preferences: {
            ...defaultFilters,
            applicableMaxCapacity: getNumber(value),
          },
          products: unfilteredProducts,
        });

        (Object.keys(filtersAfterRange) as Array<keyof ProductFilters>).forEach((key) => {
          if (key === "applicableMaxCapacity") return;

          let nextValueOfFilter = false;
          const preferenceIsSet = !!preferences[key];
          const appliesToAllAvailabel = filtersAfterRange[key].productCountIfChosen >= totalInRange;
          if (preferenceIsSet && appliesToAllAvailabel) {
            nextValueOfFilter = true;
          }
          updatedPreferences[key] = nextValueOfFilter;
        });
      }
      if (props.onPreferenceChange) {
        props.onPreferenceChange(updatedPreferences as ProductFilters);
      } else {
        dispatch(
          ProjectActions.updateSolarPanel({
            preferences: updatedPreferences,
          }),
        );
      }
    },
    [defaultFilters, dispatch, preferences, props, unfilteredProducts],
  );

  return (
    <S.Preferences>
      {(Object.entries(unfilteredPossibility) as FilterPossibilityArray)
        .filter(([key, { canBeChosen }]) => {
          return canBeChosen && key !== "applicableMaxCapacity";
        })
        .map(([key]) => {
          if (key === "applicableMaxCapacity") return <div key={key}></div>;

          return (
            <div key={key} className="preference">
              <Checkbox
                checked={preferences[key] ?? false}
                disabled={possibilityFilters[key]?.canNotBeChosen}
                onChange={(e) => {
                  onPreferenceChange(key, e.target.checked);
                }}
              >
                <S.StyledPreferenceText disabled={possibilityFilters[key]?.canNotBeChosen}>
                  {t(key)}
                </S.StyledPreferenceText>
              </Checkbox>
            </div>
          );
        })}
    </S.Preferences>
  );
};
