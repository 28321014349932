import styled from "styled-components/macro";
interface InputAll {
  ischangecolor?: Boolean;
}

export const MainContainer = styled.div`
  background: #fafafa;
  border-radius: 12px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  width: calc(100% + 20px);
  margin-left: -15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: "6px";
  justify-content: flex-start;
  padding-top: 15px;
  margin-top: 10px;
  .same_div {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: self-end;
  }
  @media (max-width: 500px) {
    margin-left: unset;
    width: 100%;
    margin-top: 10px;
  }
`;
export const SubContainer = styled.div`
  width: 92%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 106px;
  .same_part {
    display: flex;
    width: 99%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  .heatpump-cost {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    padding-left: 5px;
    margin-block: 0.5rem;
    p {
      font-weight: 600;
      font-size: 17px;
      line-height: 18px;
      color: #2d4764;
    }
  }
  .flexing {
    display: flex;
    gap: 11px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #304864;
    justify-content: center;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .icon {
    cursor: pointer;
    margin-right: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 1rem;
    div {
      background: #2d4764dd;
      width: 1rem;
      height: 3px;
      border-radius: 3px;
    }
  }
  .text_first {
    font-size: 16px;
  }
`;
export const SubContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-block: 0.5em;
  flex-wrap: wrap;
`;
export const Percentage = styled.div<InputAll>`
  position: absolute;
  right: 13px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => {
    if (props.ischangecolor) {
      return "#666666";
    } else {
      return "#2d4764";
    }
  }};
`;
export const SubContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding-top: 12px;
  /* padding-bottom: 8px; */
`;
export const SubContainer3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
`;
export const SubContainerText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d4764;
`;
export const SubContainerValue = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #304864;
  min-width: 77px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const SubContainerText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
  cursor: pointer;
`;
