import { DEFAULT_MICRO_INVERTER_COST } from "src/data";
import { InstallerDB, ProductDB } from "src/db-types";

import { ProjectType } from "src/redux/project";

export interface ProductCostProps {
  projectType: ProjectType;
  product: ProductDB;
  quantityToBuy?: number;
  installer?: InstallerDB | null;
}

export const getProductCost = (props: ProductCostProps): number => {
  const { product, projectType, quantityToBuy, installer } = props;
  const bulkPriceProduct = product?.bulkPrice?.[`${installer?._id || "ADMIN"}`];

  const isMicroInverter = product.type === "inverter" && product.subtype === "premium";
  if (isMicroInverter) return DEFAULT_MICRO_INVERTER_COST;

  if (projectType === "b2b") return product.priceB2b;
  else
    return (
      bulkPriceProduct
        ?.filter((bulkPrice) => Number(bulkPrice.quantity) <= (quantityToBuy || 0))
        .sort((a, b) => Number(b.quantity) - Number(a.quantity))[0]?.priceb2c || product.priceB2c
    );
};
