import { Html, Line } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as THREE from "three";

// Draws a line between the two points
const MeasurementLine = ({ points, fromPolygon, isPanelPlacing }) => {
  if (points.length < 2) return null;
  const n = points.length;

  return (
    <>
      {points.map((point, index) => {
        if (index < points.length - 1) {
          const p1 = points[index];
          const p2 = points[(index + 1) % n];
          const p3 = points[(index + 2) % n];

          const isEvenIndex = index % 2 === 0;

          if (!isEvenIndex && fromPolygon) return <></>;
          return (
            <React.Fragment key={index}>
              <Line
                points={[p1, fromPolygon ? p3 : p2]} // Array of points [start, end]
                color="green"
                lineWidth={4}
                dashed={false}
                depthTest={false}
              />
              {!isPanelPlacing && p1 && p2 && p3 && (
                <DistanceLabel point1={p1} point2={p2} point3={fromPolygon ? p3 : p2} />
              )}
            </React.Fragment>
          );
        }
        return null;
      })}
    </>
  );
};

const PointMesh = ({
  position,
  index,
  polygonIndex,
  onEditStart,
  onEditEnd,
  onDrag,
  modelRef,
  fromPolygon,
  isEditing,
  handlePointClick,
  setEditStartPosition,
  editStartPosition,
  selectedMode,
}) => {
  const [hovered, setHovered] = useState(false);
  const [dragging, setDragging] = useState(false);
  const meshRef = useRef();

  useFrame(({ mouse, camera }) => {
    if (dragging) {
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);
      const intersectPoint = new THREE.Vector3();

      // Assuming downward raycast; adjust if needed

      const intersectModel = raycaster.intersectObject(modelRef.current.scene, true);
      const intersects = raycaster.intersectObject(meshRef.current.parent);

      if (intersectModel.length > 0 && intersects.length > 0) {
        const newPosition = intersects[0].point;
        raycaster.ray.origin.copy(newPosition);
        raycaster.ray.direction.set(0, -1, 0).normalize();
        intersectPoint.copy(intersectModel[0].point);

        onDrag(polygonIndex, index, intersectPoint);
      }
    }
  });

  const handlePointerDown = (event) => {
    if (!isEditing.current && selectedMode === "Create mode") return;
    if (setEditStartPosition) {
      setEditStartPosition({ position, index, polygonIndex });
    }
    event.stopPropagation();
    setDragging(true);
    onEditStart(polygonIndex, index, position);
  };

  const handlePointerUp = (event) => {
    if (!isEditing.current && selectedMode === "Create mode") return;
    event.stopPropagation();
    onEditEnd(polygonIndex, index);
    setDragging(false);
  };

  const handleClick = (event) => {
    if (
      editStartPosition?.position?.distanceTo(position) < 0.5 &&
      editStartPosition?.index === index &&
      editStartPosition?.polygonIndex === polygonIndex
    ) {
      event.stopPropagation();
      if (handlePointClick && index % 2 === 0) {
        handlePointClick(polygonIndex, index, position);
        setEditStartPosition(null);
      }
    }
  };

  const sphereSize = fromPolygon && index % 2 === 1 ? 0.1 : 0.2;

  return (
    <mesh
      ref={meshRef}
      position={position}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onClick={selectedMode !== "Create mode" && handleClick}
    >
      <sphereGeometry args={[sphereSize, 32, 32]} />
      <meshStandardMaterial color={hovered ? "orange" : "white"} depthTest={false} />
    </mesh>
  );
};

const DistanceLabel = ({ point1, point2, point3 }) => {
  const midPoint = new THREE.Vector3().addVectors(point1, point2).multiplyScalar(0.5);
  const distance = point1.distanceTo(point3).toFixed(2);

  return (
    <Html position={midPoint} center style={{ pointerEvents: "none" }} zIndexRange={[999, 0]}>
      <div
        style={{
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.60)",
          padding: "3px 7px",
          borderRadius: "10px",
          fontSize: "13px",
          fontWeight: "bold",
          width: "max-content",
        }}
      >
        {`${distance} m`}
      </div>
    </Html>
  );
};

const Measurement = ({
  setDisableRotation,
  polygons,
  setPolygons,
  points,
  drawingLine,
  startPoint,
  endPoint,
  modelRef,
  isEditing,
  setDeleteMeasurementPoint,
  setVisible,
  cameraRef,
  canvasRef,
  selectedMode,
  isPanelPlacing,
  controlsRef,
  setHistoryPolygons
}) => {
  const [editStartPosition, setEditStartPosition] = useState(null);
  const { t } = useTranslation();
  const handleEditStart = (index, _, position) => {
    if (isEditing.current && selectedMode === "Edit mode") {
      controlsRef.current.enabled = false;
      setDisableRotation(true); // Disable rotation while editing
    }
  };

  const handleEditEnd = (polygonIndex, pointIndex) => {
    if (isEditing.current && pointIndex % 2 === 1 && selectedMode === "Edit mode") {
      setPolygons((prevPolygons) => {
        return prevPolygons.map((polygon, pIndex) => {
          if (pIndex === polygonIndex) {
            // Use map to construct the new polygon array
            const updatedPolygon = polygon.map((point, idx) => {
              if (idx === pointIndex) {
                const prevPoint = polygon[(idx - 1) % polygon.length];
                const nextPoint = polygon[(idx + 1) % polygon.length];

                // Calculate the new points
                const pointBetweenPrevAndCurrent = new THREE.Vector3()
                  .copy(prevPoint)
                  .add(point)
                  .multiplyScalar(0.5);

                const pointBetweenCurrentAndNext = new THREE.Vector3()
                  .copy(point)
                  .add(nextPoint)
                  .multiplyScalar(0.5);

                // Return an array containing the new points and the current point
                return [pointBetweenPrevAndCurrent, point, pointBetweenCurrentAndNext];
              }
              return [point]; // Return the current point in an array
            });
            // Flatten the array to remove nested arrays
            return updatedPolygon.flat();
          }

          return polygon; // Return other polygons unchanged
        });
      });
      setHistoryPolygons((prevPolygons) => {
        return prevPolygons.map((polygon, pIndex) => {
          if (pIndex === polygonIndex) {
            // Use map to construct the new polygon array
            const updatedPolygon = polygon.map((point, idx) => {
              if (idx === pointIndex) {
                const prevPoint = polygon[(idx - 1) % polygon.length];
                const nextPoint = polygon[(idx + 1) % polygon.length];

                // Calculate the new points
                const pointBetweenPrevAndCurrent = new THREE.Vector3()
                  .copy(prevPoint)
                  .add(point)
                  .multiplyScalar(0.5);

                const pointBetweenCurrentAndNext = new THREE.Vector3()
                  .copy(point)
                  .add(nextPoint)
                  .multiplyScalar(0.5);

                // Return an array containing the new points and the current point
                return [pointBetweenPrevAndCurrent, point, pointBetweenCurrentAndNext];
              }
              return [point]; // Return the current point in an array
            });
            // Flatten the array to remove nested arrays
            return updatedPolygon.flat();
          }

          return polygon; // Return other polygons unchanged
        });
      })
    }
    controlsRef.current.enabled = true;
    setDisableRotation(false); // Resume rotation after editing
  };

  const handleDrag = (polygonIndex, pointIndex, newPosition) => {
    if (isEditing.current && selectedMode === "Edit mode") {
      setPolygons((prevPolygons) => {
        // Create a deep copy of the polygons array
        const updatedPolygons = prevPolygons.map((polygon, pIndex) => {
          // If it's the polygon being edited
          if (pIndex === polygonIndex) {
            // Update the specific point
            const updatedPolygon = polygon.map((point, ptIndex) =>
              ptIndex === pointIndex ? newPosition : point,
            );

            // Check if the current point is at an even index (or an odd index if zero-indexed)
            if (pointIndex % 2 === 0) {
              // Update the positions of the odd-indexed points
              return updatedPolygon.map((point, ptIndex) => {
                // If the point is at an odd index, calculate its new position
                if (ptIndex % 2 === 1) {
                  const prevPoint = updatedPolygon[ptIndex - 1];
                  const nextPoint = updatedPolygon[(ptIndex + 1) % polygon.length];
                  return new THREE.Vector3().copy(prevPoint).add(nextPoint).multiplyScalar(0.5);
                }
                return point;
              });
            }

            return updatedPolygon;
          }

          // Otherwise, return the polygon unchanged
          return polygon;
        });

        return updatedPolygons;
      });
    }
  };

  const calculatePolygonMetrics = (points) => {
    let perimeter = 0;
    let area = 0;
    const n = points.length;

    // Loop through each point
    for (let i = 0; i < n; i++) {
      const p1 = points[i];
      const p2 = points[(i + 1) % n];

      if (p1 && p2) {
        // Calculate side length (distance between points)
        const sideLength = p1?.distanceTo(p2);
        perimeter += sideLength;

        // Shoelace formula for area
        area += p1.x * p2.z - p2.x * p1.z;
      }
    }

    area = Math.abs(area) / 2;
    return { perimeter, area };
  };

  const calculateCentroid = (points) => {
    if (points.length === 0) return new THREE.Vector3();

    const sum = points.reduce((acc, point) => acc.add(point), new THREE.Vector3());
    const centroid = sum.divideScalar(points.length);

    return centroid;
  };

  const PolygonShape = ({ points = [] }) => {
    const geomShape = useMemo(() => {
      const points2d = points.map((v3) => new THREE.Vector2(v3.x, v3.z));

      const shapePositions = new THREE.Shape(points2d);
      shapePositions.autoClose = true;

      const geomShape = new THREE.ShapeGeometry(shapePositions);
      const pos = geomShape.attributes.position;

      const pointMap = [];
      for (let i = 0; i < pos.count; i++) {
        const p = new THREE.Vector2(pos.getX(i), pos.getY(i));
        let nearestDist = Infinity;
        let nearestIndex;
        for (let j = 0; j < points2d.length; j++) {
          const d = points2d[j].distanceTo(p);
          if (d < nearestDist) {
            nearestIndex = j;
            nearestDist = d;
          }
        }
        pointMap.push(nearestIndex);
      }

      for (let i = 0; i < pos.count; i++) pos.setZ(i, -points[pos.count - 1 - i].y);

      for (let i = 0; i < pos.count; i++) pos.setZ(i, -points[pointMap[i]].y);

      pos.needsUpdate = true;
      geomShape.computeVertexNormals();

      return geomShape;
    }, [points]);

    const { perimeter, area } = useMemo(() => calculatePolygonMetrics(points), [points]);

    const centroid = useMemo(() => calculateCentroid(points), [points]);

    return (
      <>
        <mesh geometry={geomShape} rotation={[Math.PI * 0.5, 0, 0]}>
          <meshBasicMaterial
            attach="material"
            color={0x22d94a}
            side={THREE.DoubleSide}
            transparent
            opacity={0.7}
            depthTest={false}
          />
        </mesh>
        {!isPanelPlacing && (
          <Html
            position={centroid}
            center
            style={{
              pointerEvents: "none",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.60)",
              padding: "3px 7px",
              borderRadius: "10px",
              fontSize: "13px",
              fontWeight: "bold",
              width: "max-content",
            }}
            zIndexRange={[999, 0]}
          >
            <div>{`${t("Area")}: ${area.toFixed(2)} m2`}</div>
            <div>{`${t("Perimeter")}: ${perimeter.toFixed(2)} m`}</div>
          </Html>
        )}
      </>
    );
  };

  const handlePointClick = (polygonIndex, pointIndex, position) => {
    if (position) {
      // Project the 3D position into 2D screen space
      const vector = position.clone().project(cameraRef.current);
      const x = (vector.x * 0.5 + 0.5) * canvasRef.current.clientWidth;
      const y = (-vector.y * 0.5 + 0.5) * canvasRef.current.clientHeight;

      // Set the delete icon's position based on the projected coordinates
      setDeleteMeasurementPoint({
        pointIndex,
        polygonIndex,
        position: { top: y + 40, left: x },
      });
      setVisible(true);
    }
  };

  return (
    <>
      {/* Render closed polygons as shapes */}
      {(polygons || []).map((polygonPoints, index) => {
        return (
          polygonPoints?.length > 0 && <PolygonShape key={index} points={polygonPoints || []} />
        );
      })}

      {/* Render the points as spheres */}
      {points.map((point, index) => (
        <PointMesh
          key={index}
          index={index}
          position={point}
          fromPolygon={false}
          modelRef={modelRef}
          onEditStart={handleEditStart}
          onEditEnd={handleEditEnd}
          onDrag={handleDrag}
          isEditing={isEditing}
          selectedMode={selectedMode}
        />
      ))}
      {polygons.map((points, polygonIndex) => {
        return (points || []).map((point, index) => (
          <PointMesh
            key={index}
            polygonIndex={polygonIndex}
            index={index}
            position={point}
            onEditStart={handleEditStart}
            onEditEnd={handleEditEnd}
            onDrag={handleDrag}
            modelRef={modelRef}
            fromPolygon={true}
            isEditing={isEditing}
            handlePointClick={handlePointClick}
            setEditStartPosition={setEditStartPosition}
            editStartPosition={editStartPosition}
            selectedMode={selectedMode}
          />
        ));
      })}

      {/* Measurement Line */}
      {polygons.map((points, index) => (
        <MeasurementLine
          key={index}
          points={[...points, points[0]]}
          fromPolygon={true}
          isPanelPlacing={isPanelPlacing}
        />
      ))}

      {drawingLine && startPoint && endPoint && (
        <Line points={[startPoint, endPoint]} color="green" lineWidth={4} depthTest={false} />
      )}
      {drawingLine && startPoint && endPoint && (
        <DistanceLabel point1={startPoint} point2={endPoint} point3={endPoint} />
      )}

      <MeasurementLine points={points} fromPolygon={false} />
    </>
  );
};

export default Measurement;
