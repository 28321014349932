import {
  Backdrop,
  Checkbox,
  CircularProgress,
  createTheme,
  FormControlLabel,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { Input, Select } from "antd";
import axios from "axios";
import html2canvas from "html2canvas";
import JoditEditor from "jodit-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAppSelector } from "src/hooks";

import { downloadProjectPdf, shareProjectWithCustomer } from "src/api/backend-endpoints";
import { SolarImage } from "src/assets/images";
import { LongLogo } from "src/assets/svgs";
import { ApiAxiosClient } from "src/axios";
import useDownloadPdf from "src/pages/ApplicationPage/hooks/useDownloadPdf";
import { PopupSkeleton } from "src/shared/components";
import { isQuoteNotVerified, isQuotePresent } from "src/utils";

import cssClasses from "./savedProjectUserDetail.module.css";

import { FlagActions, ProjectActions } from "src/redux/actionCreators";
import { FLAG_KEYS } from "src/redux/flags";
import {
  selectors as PopupSelectors,
  actionCreators as PopupActions,
  POPUP_KEYS,
} from "src/redux/popups";
import { updateProject } from "src/redux/project/action/action.creators";
import { getAddress, getProject } from "src/redux/project/selectors";
import { UserInfo } from "src/redux/project/types/project-state";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { getInstaller } from "src/redux/user/selectors";

interface ISendofferPopupContentProps {
  setShowSendMailPopup: (arg: boolean) => void;
  handleShareProject: (e: any) => void;
  emailContent: string;
  setEmailContent: (arg: string) => void;
  sendPdfWithEmail: boolean;
  setSendPdfWithEmail: (arg: boolean) => void;
  sendMailToSelf: boolean;
  setSendMailToSelf: (arg: boolean) => void;
  loader: boolean;
  loading: boolean;
  setLoading: (arg: boolean) => void;
  getOfferPdf: ({ templateId }: { templateId: string | undefined }) => Promise<string | undefined>;
  pdfTemplateId: string | undefined;
  setPdfTemplateId: (arg: string) => void;
  emailSubject: string;
  setEmailSubject: (arg: string) => void;
}

function dataURLToFile(dataURL: string) {
  const [header, base64Data]: any = dataURL.split(",");
  const mimeType = header.match(/:(.*?);/)[1];

  // Generate a unique filename using the MIME type and current timestamp
  const extension = mimeType.split("/")[1]; // e.g., "png" from "image/png"
  const fileName = `file_${Date.now()}.${extension}`;

  const binaryString = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  return new File([uint8Array], fileName, { type: mimeType });
}

const processDataUrls = async (content: string) => {
  try {
    const dataUrls = content.match(/data:image\/[^;]+;base64[^"]+/g);
    if (!dataUrls) return content;

    let newContent = content;

    for (const url of dataUrls) {
      const converted = dataURLToFile(url);
      const formData = new FormData();
      formData.append("file", converted);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/media/email-assets`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      newContent = newContent.replace(url, response.data.data.url);
    }

    return newContent;
  } catch (error) {
    console.error(error);
  }
};

const NewEditor = ({
  setEmailContent,
  loading,
  setLoading,
  pdfTemplateId,
  setPdfTemplateId,
  emailSubject,
  setEmailSubject,
}: any) => {
  const { t } = useTranslation();
  const [content, setContent] = useState("");
  const [templateOptions, setTemplateOptions] = useState([]);
  const [pdfTemplates, setPdfTemplates] = useState([]);
  const userDetails = useSelector(UserSelectors.getUser);
  const templateResponse = useRef<any>([]);

  const fetchTemplates = useCallback(async () => {
    try {
      setLoading(true);
      if (!userDetails?.iid) return;

      const response = await ApiAxiosClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/email-template/${userDetails?.iid}`,
      );
      setTemplateOptions(
        response.data.data.map((template: any) => ({
          value: template._id,
          label: template.templateName,
        })),
      );
      templateResponse.current = response.data.data;
    } catch (error) {
      toast.error("An error occurred while fetching the templates.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [userDetails?.iid, setLoading]);

  const fetchPdfTemplates = useCallback(async () => {
    try {
      setLoading(true);
      if (!userDetails?.iid) return;

      const response = await ApiAxiosClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/comman/templates/${userDetails?.iid}`,
      );
      setPdfTemplates(response.data.templates);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [userDetails?.iid, setLoading]);

  const handleSelectTemplate = useCallback(
    (id) => {
      const template: any = templateResponse.current.find((template: any) => template._id === id);
      if (!template) return;
      setContent(template.content);
      setEmailSubject(template?.subject || "");
    },
    [templateResponse.current],
  );

  useEffect(() => {
    setEmailContent(content);
  }, [content]);

  useEffect(() => {
    fetchTemplates();
    fetchPdfTemplates();
  }, []);

  const config = useMemo(() => {
    const joditConfigs = {
      readonly: false,
      placeholder: "",
      uploader: {
        insertImageAsBase64URI: true,
      },
      language: "de",
      buttons:
        "bold,underline,eraser,ul,ol,font,fontsize,image,video,cut,copy,paste,copyformat,table,link,indent,left,undo,redo",
    };
    return joditConfigs;
  }, []);

  return (
    <div className="grid gap-3">
      {userDetails?.iid && templateOptions.length !== 1 && (
        <div className="mx-auto">
          <Select
            placeholder={t("Select Template")}
            options={templateOptions}
            onChange={handleSelectTemplate}
            style={{ width: 400, textAlign: "center" }}
          />
        </div>
      )}
      {userDetails?.iid && pdfTemplates?.length !== 1 && (
        <div className="mx-auto">
          <Select
            placeholder={t("Select PDF Template")}
            options={pdfTemplates.map((template: any) => ({
              value: template.id,
              label: template.name,
            }))}
            value={pdfTemplateId}
            onChange={(value: string) => setPdfTemplateId(value)}
            style={{ width: 400, textAlign: "center" }}
          />
        </div>
      )}
      <Input
        value={emailSubject}
        onChange={(e) => setEmailSubject(e.target.value)}
        placeholder={t("Subject")}
        style={{
          width: "400px",
          borderRadius: "5px",
          justifySelf: "center",
          textAlign: emailSubject ? "left" : "center",
        }}
      />
      <JoditEditor
        value={content}
        config={config}
        onBlur={(newContent: any) => setContent(newContent)}
      />
    </div>
  );
};

const SendOfferPopupContent: React.FC<ISendofferPopupContentProps> = ({
  setShowSendMailPopup,
  handleShareProject,
  emailContent,
  setEmailContent,
  sendPdfWithEmail,
  setSendPdfWithEmail,
  sendMailToSelf,
  setSendMailToSelf,
  loader,
  loading,
  setLoading,
  getOfferPdf,
  pdfTemplateId,
  setPdfTemplateId,
  emailSubject,
  setEmailSubject,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const completeAddress = useSelector(ProjectSelectors.getCompleteAddress);

  const onCancelClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowSendMailPopup(false);
    },
    [setShowSendMailPopup],
  );

  const handleChangeAddPdfToEmail = useCallback(
    (e, checked) => {
      e.preventDefault();
      e.stopPropagation();
      setSendPdfWithEmail(checked);
    },
    [setSendPdfWithEmail],
  );

  const handleChangeSendMailToSelf = useCallback(
    (e, checked) => {
      e.preventDefault();
      e.stopPropagation();
      setSendMailToSelf(checked);
    },
    [setSendMailToSelf],
  );

  const handlePreviewPdf = useCallback(async () => {
    try {
      setLoading(true);
      const url = await getOfferPdf({ templateId: pdfTemplateId });

      dispatch(PopupActions.openPopup(POPUP_KEYS.pdfPreview, { url }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [getOfferPdf, pdfTemplateId]);

  return (
    <>
      <div className={cssClasses.headerOuterContainer}>
        <div className={cssClasses.headerInnerContainer}>
          <LongLogo />
          <div className={cssClasses.headerTitle}>
            {t("Send the offer to your customer per mail")}
          </div>
          <div className={cssClasses.formSubtitle}>{completeAddress}</div>
          <NewEditor
            setEmailContent={setEmailContent}
            loading={loading}
            setLoading={setLoading}
            pdfTemplateId={pdfTemplateId}
            setPdfTemplateId={setPdfTemplateId}
            emailSubject={emailSubject}
            setEmailSubject={setEmailSubject}
          />
          <div className="flex justify-center items-center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendPdfWithEmail}
                  onChange={handleChangeAddPdfToEmail}
                  defaultChecked
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
              label={<div className={cssClasses.checkboxText}>{t("Add PDF to E-Mail")}</div>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendMailToSelf}
                  onChange={handleChangeSendMailToSelf}
                  defaultChecked
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
              label={<div className={cssClasses.checkboxText}>{t("Send mail to self")}</div>}
            />
          </div>
          <div className={cssClasses.buttonContainer}>
            <button
              className={cssClasses.secondaryButton}
              onClick={handlePreviewPdf}
              disabled={loader}
            >
              {loader ? t("Sending...") : t("Preview PDF")}
            </button>
            <button
              className={cssClasses.primaryButton}
              onClick={handleShareProject}
              disabled={loader}
            >
              {loader ? t("Sending...") : t("Send to customer")}
            </button>
            <button className={cssClasses.secondaryButton} onClick={onCancelClick}>
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
      <Backdrop
        sx={(theme) => ({ color: "#FFD75D", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#2d4764",
          fontFamily: "Nunito"
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#2d4764",
        },
      },
    },
  },
});

const SavedProjectUserDetailPopup: React.FC = () => {
  const [showSendMailPopup, setShowSendMailPopup] = useState(false);
  const [sendPdfWithEmail, setSendPdfWithEmail] = useState(false);
  const [sendMailToSelf, setSendMailToSelf] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const mapboxAddress = useSelector(getAddress);
  const project = useSelector(getProject);
  const generatePdfData = useDownloadPdf();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<any>({});
  const installerDetails = useSelector(UserSelectors.getInstaller);
  const userDetails = useSelector(UserSelectors.getUser);
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const status = useSelector(ProjectSelectors.getQuoteStatus);
  const [pdfTemplateId, setPdfTemplateId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!setShowSendMailPopup) {
      setEmailContent("");
      setEmailSubject("");
      setSendPdfWithEmail(false);
    }
  }, [showSendMailPopup]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const instalelrParnter = useSelector(getInstaller);

  const [formData, setFormData] = useState<UserInfo>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const completeAddress = useSelector(ProjectSelectors.getCompleteAddress);
  const user = useSelector(UserSelectors.getUser);
  const projectUserInfo = useSelector(ProjectSelectors.getUserInfo);

  const onCloseClick = useCallback(() => {
    dispatch(FlagActions.setFlag(FLAG_KEYS.map_screenshot_mode, false));
    dispatch(PopupActions.closePopup(POPUP_KEYS.saved_project_user_info));
  }, [dispatch]);

  useEffect(() => {
    if (projectUserInfo) {
      setFormData({
        firstName: projectUserInfo.firstName,
        lastName: projectUserInfo.lastName,
        email: projectUserInfo.email,
        phoneNumber: projectUserInfo.phoneNumber,
      });
    } else if (user) {
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });
    }
  }, [user, projectUserInfo]);

  const handleUpdateFormData = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
    [],
  );

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(ProjectActions.addUserInfo(formData));
      toast.success(t("User info saved successfully"));
      onCloseClick();
    },
    [dispatch, formData, t, onCloseClick],
  );

  const isToShowSolarLayout = useMemo(
    () => isQuotePresent(quoteId) && isQuoteNotVerified(status),
    [isQuoteNotVerified, isQuotePresent, quoteId, status],
  );

  const takeScreenshot = useCallback(async () => {
    const input = document.getElementById("SolarLayout");

    if (input === null) return "";
    const canvas = await html2canvas(input, { useCORS: true, allowTaint: false });
    const image = canvas.toDataURL("image/png");
    return image;
  }, []);

  const getOfferPdf = useCallback(
    async ({ templateId }) => {
      try {
        if (pdfUrl[templateId]) return pdfUrl[templateId];
        const pdfName = `Solarprojekt-SolarHub-${mapboxAddress?.address} ${
          mapboxAddress?.postcode
        }-${project?.shortId ? project?.shortId : ""}`;

        const payload = await generatePdfData({ templateId });

        const response = await ApiAxiosClient.post(
          `${downloadProjectPdf.url}/?fileName=${pdfName}`,
          payload,
        );
        const url = encodeURI(response.data.url);
        setPdfUrl((prev: any) => ({ ...prev, [templateId]: url }));
        return url;
      } catch (error) {
        console.error(error);
      }
    },
    [
      mapboxAddress?.address,
      mapboxAddress?.postcode,
      generatePdfData,
      project?.shortId,
      pdfUrl,
      setPdfUrl,
      isToShowSolarLayout,
      takeScreenshot,
    ],
  );

  const handleShareProject = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setLoader(true);
      try {
        setLoading(true);
        let url: any = "";
        console.log({ sendPdfWithEmail });
        const updatedEmailContent = await processDataUrls(emailContent);
        if (sendPdfWithEmail) {
          url = await getOfferPdf({ templateId: pdfTemplateId });
          dispatch(updateProject({ ...project, projectPdf: url }));
          dispatch(ProjectActions.addUserInfo(formData));
          const payload = {
            name: `${formData.firstName} ${formData.lastName}`,
            projectLink: location.href,
            address: completeAddress,
            email: formData.email,
            iid: instalelrParnter?._id,
            emailContent: updatedEmailContent,
            pdf: url,
            subject: emailSubject,
          };
          if (!instalelrParnter?._id) {
            delete payload.iid;
          }
          await ApiAxiosClient.post(shareProjectWithCustomer.url, payload);
          toast.success(t("The project details have been successfully sent to the user."));
        } else {
          dispatch(ProjectActions.addUserInfo(formData));

          const payload = {
            name: `${formData.firstName} ${formData.lastName}`,
            projectLink: location.href,
            address: completeAddress,
            email: formData.email,
            iid: instalelrParnter?._id,
            emailContent: updatedEmailContent,
            pdf: url,
            subject: emailSubject,
          };
          if (!instalelrParnter?._id) {
            delete payload.iid;
          }
          await ApiAxiosClient.post(shareProjectWithCustomer.url, payload);
          setLoader(false);
          toast.success(t("The project details have been successfully sent to the user."));
        }

        if (sendMailToSelf && (installerDetails?.email || userDetails?.email)) {
          if (!url) {
            url = await getOfferPdf({ templateId: pdfTemplateId });
          }
          const payload = {
            name: `${formData.firstName} ${formData.lastName}`,
            projectLink: location.href,
            address: completeAddress,
            email: installerDetails?.email || userDetails?.email,
            iid: instalelrParnter?._id,
            emailContent: updatedEmailContent,
            pdf: url,
            subject: emailSubject,
          };
          if (!instalelrParnter?._id) {
            delete payload.iid;
          }
          await ApiAxiosClient.post(shareProjectWithCustomer.url, payload);
          toast.success(t("The project details have been successfully sent to the self."));
        }
      } catch (error) {
        setLoader(false);
        toast.error(t("An error occurred while sending the project details to the user."));
      } finally {
        setLoader(false);
        setLoading(false);
      }
      onCloseClick();
    },
    [
      onCloseClick,
      sendPdfWithEmail,
      dispatch,
      formData,
      completeAddress,
      instalelrParnter?._id,
      emailContent,
      t,
      getOfferPdf,
      pdfTemplateId,
      emailSubject,
    ],
  );

  const handleOpenSendMailPopup = useCallback(() => {
    setShowSendMailPopup(true);
  }, []);

  return (
    <PopupSkeleton
      handleClose={onCloseClick}
      open={true}
      styless={{
        height: "100%",
        maxHeight: "fit-content",
        width: window.innerWidth > 500 ? "70vw" : "auto",
      }}
    >
      <div className={cssClasses.popupContainer}>
        {showSendMailPopup ? (
          <SendOfferPopupContent
            setShowSendMailPopup={setShowSendMailPopup}
            handleShareProject={handleShareProject}
            emailContent={emailContent}
            emailSubject={emailSubject}
            setEmailSubject={setEmailSubject}
            setEmailContent={setEmailContent}
            sendPdfWithEmail={sendPdfWithEmail}
            setSendPdfWithEmail={setSendPdfWithEmail}
            sendMailToSelf={sendMailToSelf}
            setSendMailToSelf={setSendMailToSelf}
            loader={loader}
            loading={loading}
            setLoading={setLoading}
            getOfferPdf={getOfferPdf}
            pdfTemplateId={pdfTemplateId}
            setPdfTemplateId={setPdfTemplateId}
          />
        ) : (
          <>
            <div className={cssClasses.popupImage}>
              <img src={SolarImage} />
            </div>
            <div className={cssClasses.popupFormContainer}>
              <div className={cssClasses.formHeader}>
                <LongLogo />
              </div>
              <div className={cssClasses.formTitle}>{t("Contact details project")}</div>
              <div className={cssClasses.formSubtitle}>{completeAddress}</div>
              <ThemeProvider theme={theme}>
                <div className={cssClasses.formContainer}>
                  <TextField
                    fullWidth
                    name="firstName"
                    id="firstname-form-field"
                    label={t("First Name")}
                    variant="outlined"
                    size="small"
                    value={formData.firstName}
                    onChange={handleUpdateFormData}
                  />
                  <TextField
                    fullWidth
                    name="lastName"
                    id="lastname-form-field"
                    label={t("Last Name")}
                    variant="outlined"
                    size="small"
                    value={formData.lastName}
                    onChange={handleUpdateFormData}
                  />
                  <TextField
                    fullWidth
                    name="email"
                    id="email-form-field"
                    label={t("E-Mail")}
                    variant="outlined"
                    size="small"
                    value={formData.email}
                    onChange={handleUpdateFormData}
                  />
                  <TextField
                    fullWidth
                    name="phoneNumber"
                    id="phone-form-field"
                    label={t("Phone")}
                    variant="outlined"
                    size="small"
                    value={formData.phoneNumber}
                    onChange={handleUpdateFormData}
                  />
                  <div className={cssClasses.buttonContainer}>
                    <button className={cssClasses.primaryButton} onClick={handleSave}>
                      {t("Save")}
                    </button>
                    <button
                      className={cssClasses.secondaryButton}
                      onClick={handleOpenSendMailPopup}
                    >
                      {t("Send project details")}
                    </button>
                  </div>
                </div>
              </ThemeProvider>
            </div>
          </>
        )}
      </div>
    </PopupSkeleton>
  );
};

export default SavedProjectUserDetailPopup;
