import styled from "styled-components/macro";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  position: relative;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  margin-bottom: 12px;
  @media only screen and (max-width: 500px) {
    width: 99%;
    padding-bottom: 16px;
    margin-bottom: 0px;
    margin-top: 15px
  }
  @media only screen and (min-width: 501px) {
    flex-shrink: 0;
  }
  padding-bottom: 37px;
  /* overflow-y: scroll; */
`;
export const MainHeader = styled.div`
  width: 100%;
  display: flex;
`;

interface MainHeaderItemProps {
  name: string;
  selected: string;
}
export const MainHeaderItem = styled.div<MainHeaderItemProps>`
  width: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  border-bottom: ${(props) => {
    if (props.name === props.selected) {
      return "3px solid #ffd75d";
    } else {
      return "3px solid #fff7dd";
    }
  }};
  font-family: "Nunito";
  font-style: normal;
  font-weight: ${(props) => {
    if (props.name === props.selected) {
      return "700";
    } else {
      return "600";
    }
  }};

  border-radius: ${(props) => {
    if (props.name === "Green") {
      return "0px 22px 0px 0px";
    } else if (props.name === "Eco") {
      return "22px 0px 0px 0px";
    } else {
      return "0px";
    }
  }};
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.005em;
  text-transform: capitalize;
  :hover {
    background: ${(props) => {
      if (props.name === props.selected) {
        return "#FFF5D8";
      } else {
        return "#FFF5D8";
      }
    }};
    border-radius: ${(props) => {
      if (props.name === "Green") {
        return "0px 22px 0px 0px";
      } else if (props.name === "Eco") {
        return "22px 0px 0px 0px";
      } else {
        return "0px";
      }
    }};
    border-bottom: ${(props) => {
      if (props.name === props.selected) {
        return "3px solid #ffd75d";
      } else {
        return "3px solid #ffd75d";
      }
    }};
    font-weight: ${(props) => {
      if (props.name === props.selected) {
        return "700";
      } else {
        return "700";
      }
    }};
  }
  color: ${(props) => {
    if (props.name === props.selected) {
      return "#2D4764";
    } else {
      return "#666666";
    }
  }};
  cursor: pointer;
`;
