import Easing from "./Easing.js"
import *as THREE from "three"
let {Vector3} = THREE;
const FlowManager = function (){
    let flows = [];
    class Flow{
    waitCondition;
    constructor(fn) {
        this.fn = fn;
    }
    start(target) {
        flows.push(this);
        this.flow = this.fn(...arguments)
        return this;
    }
    then(something) {
        this.thenCb = something;
    }
    update(now = performance.now()) {
        //console.log('pnow', performance.now())
        if (typeof this.waitCondition == 'number') {
            if (now < this.waitCondition)
                return 0;
        } else if (typeof this.waitCondition == 'function')
            if(!this.waitCondition())
                return this.waitCondition;
        
        this.waitCondition = this.flow.next().value;
        if (typeof this.waitCondition == 'number')
            this.waitCondition += now;
        if (this.waitCondition === undefined)
            this.thenCb && this.thenCb()
        return this.waitCondition;
    }
}

let start = this.start=function(fn,target){
    return new Flow(fn).start(...([...arguments].slice(1)));
}
this.tweenVector3 = function({object, value='position', start, end, delay=0, duration=250, easing}) {
    this.start(function*({object, value, start, end, delay, duration, easing}) {
        let saveAutoUpdate = object.matrixAutoUpdate
        object.matrixAutoUpdate = true;
        let vEnd = end || new Vector3().copy(object[value]);
        let vStart = start || new Vector3().copy(Vector3.prototype.set.call(object[value], .01, .01, .01));
        yield delay;
        let tStart = performance.now();
        let tNow = tStart;
        while (tNow < (tStart + duration)) {
            let alpha = (tNow - tStart) / duration;
            Vector3.prototype.lerpVectors.call(object[value], vStart, vEnd, easing ? easing(alpha) : alpha);
            yield 0;
            tNow = performance.now();
        }
        Vector3.prototype.copy.call(object[value], vEnd);
        object.updateMatrix();
        object.matrixAutoUpdate = saveAutoUpdate;
    }, {
        object,
        value,
        start,
        end,
        delay,
        duration,
        easing
    })
}

    this.updateAll = (now = performance.now())=>{
        let fl = flows;
        let write = 0;
        for (let i = 0; i < fl.length; i++) {
            let f = fl[i];
            let wait = f.update(now);
            if (wait === undefined)
                write--;
            else
                (write !== i) && (fl[write] = fl[i]);
            write++;
        }
        fl.length = write;
    }
    Object.assign(this,Easing)
}

export default FlowManager;