import styled from "styled-components/macro";

interface Container {
  threeDViewerMaxWidth: boolean;
  modelFullView: boolean;
  // fullscreen: boolean;
}

export const Container = styled.div<Container>`
  display: grid;
  grid-template-columns: ${(props: any) =>
    props.threeDViewerMaxWidth || props.modelFullView ? "1fr" : "1.5fr 1fr"};
  gap: 30px;
  align-items: center;
  width: 100%;
  position: relative;

  & > :nth-child(3) {
    grid-column: 1 / -1;
    justify-self: center;
    width: 100%;
    text-align: center;
  }
  .fullscreen {
    width: 100%;
    height: 100%;
  }

  .content {
    height: 100% !important;
    width: 100% !important;
    position: relative !important;
  }
  .threeD_loader_component {
    width: 100%;
    height: 95%;
    position: relative !important;
    background-color:lightblue;
    cursor: crosshair;
    canvas {
      position: relative !important;
      width: 100% !important;
      height: :100% !important;
      display: block !important;
    }
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-inline: auto;
  }
`;

export const ObjViewContainer = styled.div`
  height: 100%;
  @media only screen and (max-width: 500px) {
    width: 100%;
    height: 370px;
  }
`;
