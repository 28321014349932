import styled from "styled-components";

export const MainContainer1 = styled.div`
  max-width: 261.6px;
  flex: 1;
  height: 78.2px;
  background: #ffffff;
  box-shadow: 0px 1.86191px 7.44764px rgba(0, 0, 0, 0.12);
  border-radius: 18.6191px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  color: #304864;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;

  .text {
    flex-grow: 1;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    max-width: unset;
    padding-left: 10px;
  }
`;
