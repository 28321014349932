import { Raycaster, Vector3, Quaternion } from "three";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";


const _tempVector = new Vector3();
const _tempVector2 = new Vector3();
const _tempQuaternion = new Quaternion();
const _unit = {
	X: new Vector3( 1, 0, 0 ),
	Y: new Vector3( 0, 1, 0 ),
	Z: new Vector3( 0, 0, 1 )
};

const _changeEvent = { type: "change" };
const _mouseDownEvent = { type: "mouseDown", mode: null };
const _mouseUpEvent = { type: "mouseUp", mode: null };
const _objectChangeEvent = { type: "objectChange" };

const _raycaster = new Raycaster();
let _getIndex;
let _lineIndex;
let _documentRef;
let _refreashPanels;

class MyCustomTransformControls extends TransformControls {
  constructor(camera, domElement, getIndex, documentRef, refreashPanels) {
    super(camera, domElement);

    _getIndex = getIndex;
    _documentRef = documentRef;
	_refreashPanels = refreashPanels;

	this.setSpace("local");
	this.setMode("scale");

	this.showX = false;
	this.showY = false;
	this.showZ = false;
	
	const helper = this._gizmo.helper.scale.children;
	helper.forEach(scale => {
		scale.material.opacity = 0;
	});
  }

  pointerHover( pointer ) {

    if ( this.object === undefined || this.dragging === true ) return;
	if(!_documentRef.current.scaleArrows) {return;};

	if ( pointer !== null ) _raycaster.setFromCamera( pointer, this.camera );

	_lineIndex = _getIndex();

    switch(_lineIndex) {
      case 0:
        
        this.move = true;
        this.axis = "Y";
        break;
      case 1:
        this.move = false;
        this.axis = "Y";
        break;
      case 2:
        this.move = true;
        this.axis = "X";
        break;
      case 3:
        this.move = false;
        this.axis = "X";
        break;
      default:
        this.move = false;
        this.axis = null;
    } 
  }

  pointerDown( pointer ) {

    if ( this.object === undefined || this.dragging === true || ( pointer != null && pointer.button !== 0 ) ) return;
	if(!_documentRef.current.scaleArrows) {return;};
    if(!_documentRef.current.activeGrid) {return;}
	
    let corners;
    let cornerDelta;

	if(_lineIndex === 0 || _lineIndex === 2) {
		corners = _documentRef.current.boxFrame.cornerWorldCoordinates;
		cornerDelta = corners[2].clone().sub(corners[0]);
        _documentRef.current.activeGrid.position.add(cornerDelta);
		_documentRef.current.activeGrid.selectionMesh.position.copy(new Vector3(-_documentRef.current.activeGrid.selectionMesh.scale.x, -_documentRef.current.activeGrid.selectionMesh.scale.y, 0));
	}

    if ( this.axis !== null ) {

      if ( pointer !== null ) _raycaster.setFromCamera( pointer, this.camera );

      const planeIntersect = intersectObjectWithRay( this._plane, _raycaster, true );

      if ( planeIntersect ) {

        this.object.updateMatrixWorld();
        this.object.parent.updateMatrixWorld();

        this._positionStart.copy( this.object.position );
        this._quaternionStart.copy( this.object.quaternion );
        this._scaleStart.copy( this.object.scale );

        this.object.matrixWorld.decompose( this.worldPositionStart, this.worldQuaternionStart, this._worldScaleStart );

        this.pointStart.copy( planeIntersect.point ).sub( this.worldPositionStart );

      }

      this.dragging = true;
      _mouseDownEvent.mode = this.mode;
      this.dispatchEvent( _mouseDownEvent );

	  _documentRef.current.showScale(_documentRef.current.activeGrid, _lineIndex);
	  _documentRef.current.updateBoxFrame(_documentRef.current.activeGrid)
	  _refreashPanels();
    }

  }

  pointerMove( pointer ) {

		const axis = this.axis;
		const mode = this.mode;
		const object = this.object;
		let space = this.space;

		if ( mode === "scale" ) {

			space = "local";

		} else if ( axis === "E" || axis === "XYZE" || axis === "XYZ" ) {

			space = "world";

		}

		if ( object === undefined || axis === null || this.dragging === false || ( pointer !== null && pointer.button !== - 1 ) ) return;

		if ( pointer !== null ) _raycaster.setFromCamera( pointer, this.camera );

		const planeIntersect = intersectObjectWithRay( this._plane, _raycaster, true );

		if ( ! planeIntersect ) return;

		this.pointEnd.copy( planeIntersect.point ).sub( this.worldPositionStart );

		if ( mode === "translate" ) {

			// Apply translate

			this._offset.copy( this.pointEnd ).sub( this.pointStart );

			if ( space === "local" && axis !== "XYZ" ) {

				this._offset.applyQuaternion( this._worldQuaternionInv );

			}

			if ( axis.indexOf( "X" ) === - 1 ) this._offset.x = 0;
			if ( axis.indexOf( "Y" ) === - 1 ) this._offset.y = 0;
			if ( axis.indexOf( "Z" ) === - 1 ) this._offset.z = 0;

			if ( space === "local" && axis !== "XYZ" ) {

				this._offset.applyQuaternion( this._quaternionStart ).divide( this._parentScale );

			} else {

				this._offset.applyQuaternion( this._parentQuaternionInv ).divide( this._parentScale );

			}

			object.position.copy( this._offset ).add( this._positionStart );

			// Apply translation snap

			if ( this.translationSnap ) {

				if ( space === "local" ) {

					object.position.applyQuaternion( _tempQuaternion.copy( this._quaternionStart ).invert() );

					if ( axis.search( "X" ) !== - 1 ) {

						object.position.x = Math.round( object.position.x / this.translationSnap ) * this.translationSnap;

					}

					if ( axis.search( "Y" ) !== - 1 ) {

						object.position.y = Math.round( object.position.y / this.translationSnap ) * this.translationSnap;

					}

					if ( axis.search( "Z" ) !== - 1 ) {

						object.position.z = Math.round( object.position.z / this.translationSnap ) * this.translationSnap;

					}

					object.position.applyQuaternion( this._quaternionStart );

				}

				if ( space === "world" ) {

					if ( object.parent ) {

						object.position.add( _tempVector.setFromMatrixPosition( object.parent.matrixWorld ) );

					}

					if ( axis.search( "X" ) !== - 1 ) {

						object.position.x = Math.round( object.position.x / this.translationSnap ) * this.translationSnap;

					}

					if ( axis.search( "Y" ) !== - 1 ) {

						object.position.y = Math.round( object.position.y / this.translationSnap ) * this.translationSnap;

					}

					if ( axis.search( "Z" ) !== - 1 ) {

						object.position.z = Math.round( object.position.z / this.translationSnap ) * this.translationSnap;

					}

					if ( object.parent ) {

						object.position.sub( _tempVector.setFromMatrixPosition( object.parent.matrixWorld ) );

					}

				}

			}

			object.position.x = Math.max( this.minX, Math.min( this.maxX, object.position.x ) );
			object.position.y = Math.max( this.minY, Math.min( this.maxY, object.position.y ) );
			object.position.z = Math.max( this.minZ, Math.min( this.maxZ, object.position.z ) );

		} else if ( mode === "scale" ) {

			if ( axis.search( "XYZ" ) !== - 1 ) {

				let d = this.pointEnd.length() / this.pointStart.length();

				if ( this.pointEnd.dot( this.pointStart ) < 0 ) d *= - 1;

				_tempVector2.set( d, d, d );

			} else {

				_tempVector.copy( this.pointStart );
				_tempVector2.copy( this.pointEnd );

				_tempVector.applyQuaternion( this._worldQuaternionInv );
				_tempVector2.applyQuaternion( this._worldQuaternionInv );

				_tempVector2.divide( _tempVector );

				if ( axis.search( "X" ) === - 1 ) {

					_tempVector2.x = 1;

				}

				if ( axis.search( "Y" ) === - 1 ) {

					_tempVector2.y = 1;

				}

				if ( axis.search( "Z" ) === - 1 ) {

					_tempVector2.z = 1;

				}

			}

			// Apply scale

			if(_tempVector2.x > 0 && _tempVector2.y > 0 && _tempVector2.z > 0) {

				object.scale.copy( this._scaleStart ).multiply( _tempVector2 );
	
				if ( this.scaleSnap ) {
	
					if ( axis.search( "X" ) !== - 1 ) {
	
						object.scale.x = Math.round( object.scale.x / this.scaleSnap ) * this.scaleSnap || this.scaleSnap;
	
					}
	
					if ( axis.search( "Y" ) !== - 1 ) {
	
						object.scale.y = Math.round( object.scale.y / this.scaleSnap ) * this.scaleSnap || this.scaleSnap;
	
					}
	
					if ( axis.search( "Z" ) !== - 1 ) {
	
						object.scale.z = Math.round( object.scale.z / this.scaleSnap ) * this.scaleSnap || this.scaleSnap;
	
					}
	
				}

			}
		} else if ( mode === "rotate" ) {

			this._offset.copy( this.pointEnd ).sub( this.pointStart );

			const ROTATION_SPEED = 20 / this.worldPosition.distanceTo( _tempVector.setFromMatrixPosition( this.camera.matrixWorld ) );

			let _inPlaneRotation = false;

			if ( axis === "XYZE" ) {

				this.rotationAxis.copy( this._offset ).cross( this.eye ).normalize();
				this.rotationAngle = this._offset.dot( _tempVector.copy( this.rotationAxis ).cross( this.eye ) ) * ROTATION_SPEED;

			} else if ( axis === "X" || axis === "Y" || axis === "Z" ) {

				this.rotationAxis.copy( _unit[ axis ] );

				_tempVector.copy( _unit[ axis ] );

				if ( space === "local" ) {

					_tempVector.applyQuaternion( this.worldQuaternion );

				}

				_tempVector.cross( this.eye );

				// When _tempVector is 0 after cross with this.eye the vectors are parallel and should use in-plane rotation logic.
				if ( _tempVector.length() === 0 ) {

					_inPlaneRotation = true;

				} else {

					this.rotationAngle = this._offset.dot( _tempVector.normalize() ) * ROTATION_SPEED;

				}


			}

			if ( axis === "E" || _inPlaneRotation ) {

				this.rotationAxis.copy( this.eye );
				this.rotationAngle = this.pointEnd.angleTo( this.pointStart );

				this._startNorm.copy( this.pointStart ).normalize();
				this._endNorm.copy( this.pointEnd ).normalize();

				this.rotationAngle *= ( this._endNorm.cross( this._startNorm ).dot( this.eye ) < 0 ? 1 : - 1 );

			}

			// Apply rotation snap

			if ( this.rotationSnap ) this.rotationAngle = Math.round( this.rotationAngle / this.rotationSnap ) * this.rotationSnap;

			// Apply rotate
			if ( space === "local" && axis !== "E" && axis !== "XYZE" ) {

				object.quaternion.copy( this._quaternionStart );
				object.quaternion.multiply( _tempQuaternion.setFromAxisAngle( this.rotationAxis, this.rotationAngle ) ).normalize();

			} else {

				this.rotationAxis.applyQuaternion( this._parentQuaternionInv );
				object.quaternion.copy( _tempQuaternion.setFromAxisAngle( this.rotationAxis, this.rotationAngle ) );
				object.quaternion.multiply( this._quaternionStart ).normalize();

			}

		}

		_documentRef.current.showScale(_documentRef.current.activeGrid, _lineIndex);
		_documentRef.current.updateBoxFrame(_documentRef.current.activeGrid);

		_refreashPanels();

		this.dispatchEvent( _changeEvent );
		this.dispatchEvent( _objectChangeEvent );

	}

  	pointerUp( pointer ) {

		if ( pointer !== null && pointer.button !== 0 ) return;
		if(!_documentRef.current.activeGrid) {return;}

		if(_lineIndex === 0 || _lineIndex === 2) {
			const corners = _documentRef.current.boxFrame.cornerWorldCoordinates;
			const cornerDelta = corners[2].clone().sub(corners[0]);
			_documentRef.current.activeGrid.position.sub(cornerDelta);
			_documentRef.current.activeGrid.selectionMesh.position.copy(new Vector3(0, 0, 0));
		}

		this.normalizeScale();
		_refreashPanels();

		if ( this.dragging && ( this.axis !== null ) ) {

			_mouseUpEvent.mode = this.mode;
			this.dispatchEvent( _mouseUpEvent );

		}

		this.dragging = false;
		this.axis = null;

	}	

	normalizeScale () {
		_documentRef.current.activeGrid.selectionMesh.scale.multiply(_documentRef.current.activeGrid.scale);
		_documentRef.current.activeGrid.size.multiply(_documentRef.current.activeGrid.scale);
		_documentRef.current.activeGrid.scale.copy(new Vector3(1, 1, 1));
		_documentRef.current.activeGrid.selectionSize.copy(_documentRef.current.activeGrid.selectionMesh.scale);
	}

}

function intersectObjectWithRay( object, raycaster, includeInvisible ) {

	const allIntersections = raycaster.intersectObject( object, true );

	for ( let i = 0; i < allIntersections.length; i ++ ) {

		if ( allIntersections[ i ].object.visible || includeInvisible ) {

			return allIntersections[ i ];

		}

	}

	return false;

}

export default MyCustomTransformControls;