import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAverageCapacity } from "src/helpers";

import { ProjectActions } from "src/redux/actionCreators";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

export const useSynchronizeInverterPreference = (): void => {
  const dispatch = useDispatch();

  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);
  const heatDimension = useSelector(ProjectSelectors.getHeatpump)?.configuration?.heatpumpDimension;

  useEffect(() => {
    const capacity = quantity * getAverageCapacity(filteredSolarPanels);
    dispatch(
      ProjectActions.updateInverter({
        preferences: {
          applicableMaxCapacity: capacity,
        },
      }),
    );
    dispatch(
      ProjectActions.updateHeatpump({
        preferences: {
          applicableMaxCapacity: (heatDimension * 1000),
        },
      }),
    );
  }, [dispatch, filteredSolarPanels, quantity]);
};
