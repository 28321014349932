import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./RadioContainer.css";

const RadioContainer = ({ selectedMode, setSelectedMode, isEditing, setPoints, setDrawingLine }) => {
  const { t } = useTranslation();
  const handleModeChange = (mode) => {
    setSelectedMode(mode);
    isEditing.current = false;
    if (mode === "Edit mode") {
      isEditing.current = true;
      setPoints([]);
      setDrawingLine(false);
    }
  };

  const measurementRadio = () => {
    return (
      <div className="radio-container">
        <div className="radio-item">
          <input
            type="radio"
            id="createMode"
            name="modeRadio"
            value="Create mode"
            checked={selectedMode === "Create mode"}
            onChange={() => handleModeChange("Create mode")}
          />
          <label htmlFor="createMode" onClick={() => handleModeChange("Create mode")}>
            {t("Create mode")}
          </label>
        </div>
        <span className="separator"></span>
        <div className="radio-item">
          <input
            type="radio"
            id="editMode"
            name="modeRadio"
            value="Edit mode"
            checked={selectedMode === "Edit mode"}
            onChange={() => handleModeChange("Edit mode")}
          />
          <label htmlFor="editMode" onClick={() => handleModeChange("Edit mode")}>
            {t("Edit mode")}
          </label>
        </div>
      </div>
    );
  }

  const panelgridRadio = () => {
    return (
      <div className="radio-container">
        <div className="radio-item">
          <input
            type="radio"
            id="gridMoveMode"
            name="modeRadio"
            value="Grid move mode"
            checked={selectedMode === "Grid move mode"}
            onChange={() => handleModeChange("Grid move mode")}
          />
          <label htmlFor="gridMoveMode" onClick={() => handleModeChange("Grid move mode")}>
            {t("GRID_MOVE_MODE")}
          </label>
        </div>
        <span className="separator"></span>
        <div className="radio-item">
          <input
            type="radio"
            id="panelEditMode"
            name="modeRadio"
            value="Panel edit mode"
            checked={selectedMode === "Panel edit mode"}
            onChange={() => handleModeChange("Panel edit mode")}
          />
          <label htmlFor="panelEditMode" onClick={() => handleModeChange("Panel edit mode")}>
            {t("PANEL_EDIT_MODE")}
          </label>
        </div>
      </div>
    );
  }

  return (
    <div>
      {selectedMode === "Create mode" || selectedMode === "Edit mode" ? measurementRadio() : panelgridRadio()}
    </div>
  );
};

export default RadioContainer;
