import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, useAppSelector } from "src/hooks";

import { ROOF_PITCH_FACTORS } from "src/data";
import { getAverageCapacity } from "src/helpers";

import { ProjectActions } from "src/redux/actionCreators";
import { getProfileName, getQuote } from "src/redux/project/selectors";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

export const useSolarPanelProduction = (): void => {
  const dispatch = useAppDispatch();
  const quote = useSelector(getQuote);
  const profile = useSelector(getProfileName);

  const solarPanel = useAppSelector(ProjectSelectors.getSolarPanel);
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const filteredSolarPanels = useAppSelector(ProductSelectors.getFilteredSolarPanels);
  const { roofPitch, productionReducedDueToShade, increasedCapacityFromAdminPanel } =
    useAppSelector(ProjectSelectors.getEnergyProductionParams);

  useEffect(() => {
    const quantity = solarPanel.quantity;
    let capacity = getAverageCapacity(filteredSolarPanels);
    if (solarPanelProduct?.item) {
      capacity = Number(solarPanelProduct?.item?.capacity);
    }

    const roofPitchFactor = ROOF_PITCH_FACTORS[Math.floor(roofPitch)].factor;
    const increseCapacityFromAdminPanle = quote.profiles[profile].productionIncrese;

    let productionRatio = 0;
    const isAnnualProdAndProCapIsThere =
      quote.profiles[profile].annualSolarGeneration && quote.profiles[profile].productionCapacity;
    if (isAnnualProdAndProCapIsThere) {
      productionRatio =
        quote.profiles[profile].annualSolarGeneration / quote.profiles[profile].productionCapacity;
    } else if (quote.profiles[profile].productionRatio) {
      const numberProductionRatio = Number(quote.profiles[profile].productionRatio);
      productionRatio = numberProductionRatio;
    }

    if (!isAnnualProdAndProCapIsThere && productionRatio) {
      capacity = capacity / 1000;
    }

    const rawProduction = quantity * capacity * (productionRatio || roofPitchFactor);
    const reductionByShade = (rawProduction * productionReducedDueToShade) / 100;

    const realProduction = rawProduction - reductionByShade;

    // if (realProduction == 0) production = proj.productionByBatterySimulation;
    // else production = realProduction + getNumber(proj.increseCapacityFromAdminPanle);
    const production = realProduction + Number(increseCapacityFromAdminPanle || 0);

    dispatch(
      ProjectActions.updateEnergy({
        solarPanelProduction: production,
      }),
    );
  }, [
    dispatch,
    filteredSolarPanels,
    quote,
    increasedCapacityFromAdminPanel,
    productionReducedDueToShade,
    roofPitch,
    solarPanel.quantity,
    profile,
    solarPanelProduct,
  ]);
};
