import styled from "styled-components";

export const ConsumptionReviewWrapper = styled("div")<{ width: any }>`
  width: 100%;
  display: flex;
  padding-left: ${({ width }) => (width > 550 ? "12px" : "0px")};
  align-items: center;
  justify-content: flex-start;
  /* padding-left: 28px; */
  .main-consumption {
    /* background: #f9f9f9; */
    width: 100%;
    position: relative;
    /* padding: 13px; */
  }
  .heading {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 157%;
    letter-spacing: 0.1px;
    color: #2d4764;
  }
  .makeItCenter {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
  }
  .firstContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
  }
  .smallText {
    color: "rgba(45, 71, 100, 1)";
    font-size: 14px;
  }
  .makeFlex {
    display: flex;
    justify-items: flex-start;
    align-items: center;
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .last_container {
    position: absolute;
    bottom: 26px;
    right: 17px;
  }
  .mainContainer {
    height: 140px;
  }
`;
interface newColor {
  color?: string;
}
export const SameText = styled("div")<newColor>`
  color: ${({ color }) => {
    if (color) {
      return color;
    } else {
      return "rgba(242, 153, 74, 1)";
    }
  }};
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
`;
