import { Button, CircularProgress, Tooltip } from "@mui/material";
import { Avatar, Image } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Offer from "src/assets/chartTabs/Offer.svg";
import { MobileMenuIcon } from "src/assets/svgs";
import { AUTH_MODES, AuthPopupProps } from "src/popups/auth/types";
import { LanguageDropdown } from "src/shared/components";

import { NavItemKey } from "../../constants/navItemKey";

import { CallNow, HeaderMenu } from "./components";

import { POPUP_KEYS, actionCreators as PopupActions } from "src/redux/popups";
import { selectors as UserSelectors } from "src/redux/user";
import { useTranslation } from "react-i18next";
import { getProject } from "src/redux/project/selectors";
import { useAppSelector, useURLData } from "src/hooks";
import { AppActions } from "src/redux";
import { FlagActions } from "src/redux/actionCreators";
import { FLAG_KEYS } from "src/redux/flags";
import { getUser } from "src/redux/user/selectors";
import { AddProjectIcon } from "src/assets/svgs";
import { openPopup } from "src/redux/popups/action/action.creators";
import * as S from "./RightNav.styles";
import rolechecker from "src/shared/RoleChecker/rolechecker";

interface RightNavProps {
  menuButtonClickHandler: Function;
}

const RightNav: React.FC<RightNavProps> = (props: RightNavProps) => {
  const user = useAppSelector(getUser);

  const { menuButtonClickHandler } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const project = useSelector(getProject);

  const isAuthLoading = useSelector(UserSelectors.getIsAuthLoading);
  const currentUser = useSelector(UserSelectors.getUser);

  const { isOnSaved } = useURLData();

  const [avatarContent, setAvatarContent] = useState({
    content: "G",
    isLink: false,
  });

  const openLoginPopup = useCallback(() => {
    const data: AuthPopupProps = {
      authMode: AUTH_MODES.login,
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.auth, data));
  }, [dispatch]);

  const openOfferFlowPopup = useCallback(() => {
    const data: any = {
      quote: {
        ...project,
        ...project.selectedInstallers[0],
        matchedId: project.selectedInstallers[0]?._id,
      },
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.installer_offer, data));
  }, [dispatch, project]);

  const handleSaveProjectUserDetailPopupOpen = useCallback(() => {
    dispatch(FlagActions.setFlag(FLAG_KEYS.map_screenshot_mode, true));
    dispatch(AppActions.setCurrentStep(0));
    dispatch(PopupActions.openPopup(POPUP_KEYS.saved_project_user_info));
  }, [dispatch]);

  useEffect(() => {
    const noCurrentUser = currentUser === null;
    if (noCurrentUser) return;

    const { photoURL, email } = currentUser;

    if (photoURL) {
      setAvatarContent({
        content: photoURL,
        isLink: true,
      });
      return;
    }

    if (email) {
      setAvatarContent({
        content: email[0],
        isLink: false,
      });
    }
  }, [currentUser]);

  const loginButton = useMemo(() => {
    const notAuthenticated = !isAuthLoading && !currentUser;
    if (notAuthenticated) {
      return (
        <Button className="button login-button" onClick={openLoginPopup}>
          Login
        </Button>
      );
    }

    return <></>;
  }, [currentUser, isAuthLoading, openLoginPopup]);

  if (isAuthLoading) {
    return <CircularProgress size={20} className="circular-progress-custom" />;
  }

  const hasLinkAvatar = !isAuthLoading && currentUser && avatarContent.isLink;
  const hasLetterAvatar = !isAuthLoading && currentUser && !avatarContent.isLink;

  const isToShowOfferButton =
    !!project.selectedInstallers.length &&
    !!project.selectedInstallers[0].selectedConstants?.length &&
    project.selectedInstallers[0].isDetailedOffer;
  const isRoleValid = rolechecker(user?.role);
  return (
    <div className="third">
      {isToShowOfferButton && (
        <div className="offerflow" onClick={openOfferFlowPopup}>
          <img src={Offer} alt="Offerflow" />
          <p>{t("view offers")}</p>
        </div>
      )}

      {isRoleValid && (
        <>
          <S.styledNewProject
            className=" bg-primary hover:bg-[#ffd75d] text-black border-none text-sm flex items-center rounded-2xl pl-0"
            onClick={() => {
              dispatch(openPopup(POPUP_KEYS.welcome_address));
              dispatch(openPopup(POPUP_KEYS.saved_project_user_info));
            }}
          >
            <img src={AddProjectIcon} alt="Add Project Icon" width={30} height={30} />
            <span>{t("New project")}</span>
          </S.styledNewProject>
          {/* <AddProjectIcon
            style={{ cursor: "pointer", }}
            onClick={() => {
              dispatch(openPopup(POPUP_KEYS.welcome_address));
              dispatch(openPopup(POPUP_KEYS.saved_project_user_info));
            }}
          /> */}
        </>
      )}

      <div className="hideOnMobile">
        <LanguageDropdown />
      </div>
      <div>
        <CallNow />
      </div>
      {hasLinkAvatar && (
        <Avatar
          src={<Image referrerPolicy="no-referrer" src={avatarContent.content} />}
          style={{ textTransform: "capitalize" }}
          size={35}
        />
      )}
      {hasLetterAvatar && (
        <button onClick={handleSaveProjectUserDetailPopupOpen} disabled={!isOnSaved}>
          <Avatar
            className="avtart"
            style={{ minWidth: "35px", textTransform: "capitalize" }}
            size="large"
          >
            {avatarContent.content}
          </Avatar>
        </button>
      )}
      {currentUser && (
        <div className="hideOnMobile">
          <HeaderMenu menuButtonClickHandler={menuButtonClickHandler} />
        </div>
      )}
      {loginButton}
      <MobileMenuIcon
        onClick={() => menuButtonClickHandler(NavItemKey.MOBILE_OPEN_MENU)}
        className="hideOnDesktop"
      />
    </div>
  );
};

export default RightNav;
