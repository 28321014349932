import styled from "styled-components/macro";
export const ListSelectedProductWrapper: any = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 17px;
  margin-block: 4px;
  .same_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    @media screen and (max-width: 500px) {
      padding-left: 10px;
    }
  }
  @media screen and (max-width: 500px) {
    padding-left: unset;
  }
  .make_hover {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .same_texts {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #2d4764;
  }
  .same_buttons2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    box-sizing: border-box;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #304864;
    
  }
  .same_container3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 6px;
    @media screen and (max-width: 500px) {
      padding-right: 20px;
      padding-left: 10px;
      margin-top: 28px;
    }
  }
  .same_container2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
  }
  .make_it_bc_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    img {
      cursor: pointer;
    }
  }
`;

export const Lable = styled("div")`
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 40%;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px;
  color: #2d4764;
  font-weight: normal;
`;
