import styled from "styled-components/macro";

export const ProductHeaderCard = styled.div`
  background: #ffffff;
  box-shadow: 1px 1px 10px 0px #0000001a;
  border-radius: 22px;

  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-bottom: 0.5rem;

  .product-view-listing {
    border-top: 1px solid #e2e2e2;
    margin-top: 10px;
    padding-top: 10px;
  }
`;

export const ConfigurationRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 1rem;
`;
export const ImageAndTitle = styled.div`
  flex: 0.6;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .heading {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #2d4764 ;
    text-align: center;

    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  .image-div {
    max-height: 150px;
    width: 250px;

    img {
      object-fit: contain;
      max-height: 100%;
      margin: auto;
    }

    #german-flag {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      height: 15px;
      width: 25px;
    }
    @media screen and (max-width: 700px) {
      width: unset;
    }
  }
`;

export const Preferences = styled.div`
  color: #2d4764;
  font-family: "Nunito";
  flex: 1;

  @media screen and (max-width: 700px) {
    margin-top: 1rem;
    margin-left: 17px;
  }
  .heading {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 1rem;
  }
`;

export const PriceBox = styled.div`
  position: relative;
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 2.5rem;
  padding-top: 3rem;
  gap: 0.2rem;

  .delete-button {
    position: absolute;
    border: 1px solid var(--blue);
    padding: 0.5rem;
    border-radius: 50%;
    top: 0;
    right: 1rem;
  }

  .price {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #2d4764;
  }
  .sub-title {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #666666;
  }
`;

export const ShowOptionsButtonRow = styled.div`
  display: flex;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
  }
`;
