/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */


import { Box, Stack, Skeleton, Grid, Divider } from "@mui/material";

const ApplicationLoader = () => {
  return (
    <div style={{ width: "100%" }}>
      <Stack padding={4}>
        <Stack width={"100%"} height={"4rem"}>
          <Grid
            container
            spacing={2}
            flexDirection="row"
            columns={12}
            justifyContent="space-between"
            display={"flex"}
          >
            <Grid item xs={6}>
              <Skeleton animation="wave" variant="rectangular" height={"2rem"} width={"200px"} />
            </Grid>
            <Grid item xs={6} flex={0} flexBasis={0} justifyContent="end" display={"flex"}>
              <Skeleton animation="wave" variant="circular" width={35} height={"2.2rem"} />
            </Grid>
          </Grid>
        </Stack>
        <Divider />
        <Grid
          container
          rowGap={0.5}
          paddingX={{ lg: 12, md: 6, sm: 2, xs: 1 }}
          flexDirection="column"
        >
          <Grid item xs={12}>
            <Skeleton
              animation="wave"
              variant="text"
              height={15}
              style={{ margin: "40px 0 0 0" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="text" height={15} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="text" height={15} />
          </Grid>
        </Grid>

        <div style={{ height: "3rem" }} />
        <Grid
          container
          spacing={2}
          flexDirection="row"
          columns={12}
          paddingX={{ lg: 12, md: 6, sm: 2, xs: 1 }}
        >
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Skeleton animation="wave" variant="rectangular" height={550} />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Skeleton animation="wave" variant="rectangular" height={550} />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Skeleton animation="wave" variant="rectangular" height={550} />
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default ApplicationLoader;
