import React from "react";

import { InfoIcon } from "src/shared/components";

import * as S from "./AddProductButton.styles";

interface AddProductButtonProps {
  text: string;
  onClick: () => void;
  onInfoIconClick: () => void;
  ImageComponent: React.ReactElement;
}

export const AddProductButton: React.FC<AddProductButtonProps> = (props: AddProductButtonProps) => {
  const { ImageComponent, onClick, text, onInfoIconClick } = props;

  return (
    <S.MainContainer1 onClick={onClick}>
      <div style={{ maxWidth: "10%", minWidth: "40px" }}>{ImageComponent}</div>
      <div className="text">{text}</div>
      <div
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          event.stopPropagation();
          onInfoIconClick();
        }}
      >
        <InfoIcon />
      </div>
    </S.MainContainer1>
  );
};
