import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNaivgateAbsolute, useWindowDimensions } from "src/hooks";

import ThreeDViewer from "src/pages/ApplicationPage/components/ApplicationStep3/components/3DViewer/3DViewer";
import { PopupSkeleton } from "src/shared/components";

import * as S from "./ThreeDModel.styles";

import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";

interface ThreeDModelProps {
  isfromSavedProject?: boolean;
}

export const ThreeDModel: React.FC<ThreeDModelProps> = ( {isfromSavedProject}) => {
  const dispatch = useDispatch();
  const navigate = useNaivgateAbsolute();
  const {width} = useWindowDimensions()

  return (
    <PopupSkeleton styless={{height: "90vh",width: width<=600 ? "100vw" :"90vw"}}
      handleClose={() => {
        dispatch(closePopup(POPUP_KEYS.threedmodel));
        isfromSavedProject && navigate("/useroverview/my-project-id");
      }}
      open={true}
    >
      <S.MainContentWrapper>
        <ThreeDViewer />
      </S.MainContentWrapper>
    </PopupSkeleton>
  );
};
export default ThreeDModel;
