import { Checkbox, Slider } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  FilterPossibilityArray,
  ProductFilterKeys,
  ProductFilters,
  simulatePrefPossibility,
  useValidPreferenceMap,
} from "src/hooks";

import { ProductWithPrice } from "src/db-types";
import { filterProducts } from "src/helpers";
import { MasterInput } from "src/shared/components";
import { ParameterStyles } from "src/shared/StyledComponents";
import { ValueOf, getNumber } from "src/utils";

import * as S from "./styles";

import { ProjectActions } from "src/redux/actionCreators";
import { getApplicaitonFilters } from "src/redux/application/selector";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

interface FilterCardProps {
  onPreferenceChange?: (filters: ProductFilters) => void;
  preferences?: ProductFilters;
  filteredProducts?: ProductWithPrice[];
  unfilteredProducts?: ProductWithPrice[];
}
export const BatteryFilters: React.FC<FilterCardProps> = (props: FilterCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultFilters = useSelector(getApplicaitonFilters);

  const [minMaxCapacity, setMinMaxCapacity] = useState({
    min: 0,
    max: 0,
  });

  const { preferences: preferencesRedux } = useSelector(ProjectSelectors.getBattery);
  const unfilteredProductsRedux = useSelector(ProductSelectors.getBatteries);
  const filteredProductsRedux = useSelector(ProductSelectors.getFilteredBatteries);

  const preferences = useMemo(() => {
    if (props.preferences) return props.preferences;
    return preferencesRedux;
  }, [preferencesRedux, props.preferences]);

  const filteredProducts = useMemo(() => {
    if (props.filteredProducts) return props.filteredProducts;
    return filteredProductsRedux;
  }, [filteredProductsRedux, props.filteredProducts]);

  const unfilteredProducts = useMemo(() => {
    if (props.unfilteredProducts) return props.unfilteredProducts;
    return unfilteredProductsRedux;
  }, [props.unfilteredProducts, unfilteredProductsRedux]);

  const posibilityFilters = useValidPreferenceMap({
    preferences,
    products: filteredProducts,
  });

  const unfilteredPossibility = useValidPreferenceMap({
    preferences: defaultFilters,
    products: unfilteredProducts,
  });

  useEffect(() => {
    const validProducts = unfilteredProducts.filter(
      (p) => getNumber(p.applicableMaxCapacity) !== 0,
    );
    const min = validProducts.reduce(
      (min, prod) => Math.min(min, prod.applicableMaxCapacity as number),
      Number.MAX_VALUE,
    );
    const minInKWH = Math.floor(min / 1000);

    setMinMaxCapacity((p) => ({ ...p, min: minInKWH }));
  }, [unfilteredProducts]);

  useEffect(() => {
    const max = unfilteredProducts.reduce(
      (max, prod) => Math.max(max, prod.applicableMaxCapacity as number),
      Number.MIN_VALUE,
    );

    const maxInKWH = Math.floor(max / 1000);

    setMinMaxCapacity((p) => ({ ...p, max: maxInKWH }));
  }, [unfilteredProducts]);

  const onPreferenceChange = useCallback(
    (key: ProductFilterKeys, value: ValueOf<ProductFilters>): void => {
      const updatedPreferences: Partial<ProductFilters> = {
        [key]: value,
      };
     
      if (props.onPreferenceChange) {
        props.onPreferenceChange(updatedPreferences as ProductFilters);
      } else {
        dispatch(
          ProjectActions.updateBattery({
            preferences: updatedPreferences,
          }),
        );
      }
    },
    [props, dispatch],
  );

  const currApplicableMaxCapacity = useMemo(() => {
    return Math.max(getNumber(preferences.applicableMaxCapacity) / 1000, minMaxCapacity.min);
  }, [preferences.applicableMaxCapacity, minMaxCapacity.min]);

  return (
    <S.Preferences>
      <div className="preference hasSliderOrInput" style={{ width: "100%" }}>
        <span>{t("capacity")}</span>
        <ParameterStyles.Input>
          <Slider
            min={minMaxCapacity.min}
            max={minMaxCapacity.max}
            step={1}
            value={currApplicableMaxCapacity}
            onChange={(value) => {
              onPreferenceChange("applicableMaxCapacity", value * 1000);
            }}
          />
        </ParameterStyles.Input>
        <MasterInput
          value={currApplicableMaxCapacity}
          onChange={(value) => {
            onPreferenceChange("applicableMaxCapacity", value * 1000);
          }}
          label="kWh"
        />
      </div>
      {(Object.entries(unfilteredPossibility) as FilterPossibilityArray)
        .filter(([key, { canBeChosen }]) => {
          return canBeChosen && key !== "applicableMaxCapacity";
        })
        .map(([key]) => {
          if (key === "applicableMaxCapacity") return <div key={key}></div>;

          return (
            <div key={key} className="preference">
              <Checkbox
                checked={preferences[key] ?? false}
                disabled={posibilityFilters[key]?.canNotBeChosen}
                onChange={(e) => {
                  onPreferenceChange(key, e.target.checked);
                }}
              >
                <S.StyledPreferenceText disabled={posibilityFilters[key]?.canNotBeChosen}>
                  {t(key)}
                </S.StyledPreferenceText>
              </Checkbox>
            </div>
          );
        })}
    </S.Preferences>
  );
};
