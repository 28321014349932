import axios from "axios";

export async function solarProductionData(panelGrids, quoteDetails, solargisData) {
  const RAD2DEG = 180 / Math.PI;

  const getPanelData = () => {
    const pd = [];
    panelGrids.forEach((pg) => {
      const panelData = pg.occupancy.results[0];
      // console.log(panelData?.indicator, "panelData.indicator");
      if (panelData?.indicator) {
        const o = panelData.indicator;
        let tilt = RAD2DEG * o.parent.rotation.x;
        let azimuth = RAD2DEG * o.parent.rotation.y;

        // Normalize tilt to [0, 90]
        tilt = Math.abs(tilt % 360);
        if (tilt > 90) {
          tilt = 90; // Clamp tilt to a maximum of 90 degrees
        }

        // Normalize azimuth to [-180, 180]
        azimuth = ((azimuth + 180) % 360) - 180;

        // console.log(pg.children, "3758+++");
        pd.push({
          type: panelData.object.name,
          moduleQuantity: pg.children
          .slice(1)
          .filter((ele) => ele.name === "supported" && !ele?.isNotVisible)?.length,
          orientation: o.parent.rotation.clone(),
          tilt,
          azimuth,
        });
        
      }
    });
    return pd;
  };

  const data = getPanelData();

  const promises = data.map(async (e) => {
    const url = `https://staging.backend-v2.solarhub24.de/api/comman/pvcalc?lat=${quoteDetails.lat}&lon=${quoteDetails?.long}&peakpower=0.408&loss=2.5&aspect=${e.azimuth}&angle=${e.tilt}&outputformat=json&pvtechchoice=crystSi`;

    try {
      const response = await axios.get(url);
      return { outputs: response.data.outputs, details: e };
    } catch (error) {
      console.error("Error making the API call:", error);
      return null;
    }
  });

  solargisData = await Promise.all(promises);


  return solargisData;
  
}
