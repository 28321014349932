import { Html, Line, Plane, Sphere } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as THREE from "three";
import { TextureLoader } from "three";

import { deleteIcon, plusIcon } from "src/assets/step33dViwer";

const raycaster = new THREE.Raycaster();

const AddPointAtPosition = ({ position, onClick, pointIndex, index }) => {
  return (
    <group>
      {/* Outline Sphere */}
      <Sphere args={[0.12, 32, 32]} position={position}>
        <meshBasicMaterial color={0x000000} depthTest={false} />
      </Sphere>

      {/* Main Sphere */}
      <Sphere args={[0.09, 32, 32]} position={position} onClick={onClick}>
        <meshBasicMaterial color={0x08da91} depthTest={false} />
      </Sphere>
    </group>
  );
};

const AddLineBetweenPoints = ({ points, index }) => {
  const positionPoints = points.map((ele) => ele.point);
  return <Line points={positionPoints} color="#22d94a" lineWidth={2} depthTest={false} />;
};

const LineGroup = ({ points, onDelete, index }) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);

  const positionPoints = points.map((ele) => ele.point);
  const popupRef = useRef();

  const handlePointerOver = () => setHovered(true);
  const handlePointerOut = () => setHovered(false);

  const handleClick = () => setSelected(true);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setSelected(false);
      }
    };

    if (selected) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selected]);

  return (
    <group>
      <Line
        points={positionPoints}
        color={hovered ? "#ff0000" : "#08DA91"}
        lineWidth={4}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
        onClick={handleClick}
        depthTest={false}
      />
      {selected && (
        <Html position={positionPoints[index]}>
          <div className="popup-container" ref={popupRef}>
            <button
              className="delete-button"
              onClick={() => {
                onDelete(index);
                setSelected(false);
              }}
            >
              <img src={deleteIcon} alt="deleteIcon" height={40} width={40} />
              {t("Delete cable")}
            </button>
            <span className="separator">|</span>
            <button className="cancel-button" onClick={() => setSelected(false)}>
              {t("cancel")}
            </button>
          </div>
        </Html>
      )}
    </group>
  );
};

export const calculatePanelCenter = (panelGrid) => {
  console.log(panelGrid, "panelGrid")
  panelGrid.updateMatrixWorld(true);
  const box = new THREE.Box3().setFromObject(panelGrid);
  const center = new THREE.Vector3();
  box.getCenter(center);
  return center;
};

export const getSurfacePosition = (position, panel, offset = 0.1) => {
  const raycaster = new THREE.Raycaster();
  const panelNormal = new THREE.Vector3(0, 1, 0); // Assuming Y-axis is the up direction

  // Set the raycaster to cast from above the panel, towards the panel
  raycaster.set(
    new THREE.Vector3(position.x, position.y + 10, position.z), // Start above the panel
    panelNormal.clone().negate(), // Cast the ray downwards
  );

  // Perform the raycast and find intersections
  const intersects = raycaster.intersectObject(panel);

  // If there's an intersection, return the intersection point with an offset
  if (intersects.length > 0) {
    const intersectPoint = intersects[0].object;

    const panelCenter = calculatePanelCenter(intersectPoint.parent.parent.children[0]);

    panelCenter.add(panelNormal.clone().multiplyScalar(offset));
    return panelCenter;
  }

  // If no intersection, return the original position (this shouldn't happen if the ray is aimed correctly)
  return position;
};

export const calculatePanelCornersWithMargin = (panelGrid, margin = 0.2) => {
  // Ensure the panelGrid's world matrix is up to date
  panelGrid.updateMatrixWorld(true);

  // Calculate the bounding box of the panel
  const box = new THREE.Box3().setFromObject(panelGrid);

  // Calculate the center of the panel
  const center = new THREE.Vector3();
  box.getCenter(center);

  // Calculate the extents of the panel (half size)
  const halfSize = new THREE.Vector3();
  box.getSize(halfSize).multiplyScalar(0.5);

  // Add margin to the half size for each dimension
  // halfSize.addScalar(margin);

  // Calculate the corners in the panel's local space

  const leftTopCornerLocal = new THREE.Vector3(center.x - halfSize.x + 0.5, 0.1, center.z);
  const rightTopCornerLocal = new THREE.Vector3(center.x + halfSize.x - 0.5, 0.1, center.z);
  const leftBottomCornerLocal = new THREE.Vector3(center.x - halfSize.x, center.y, center.z);

  // const leftTopCornerLocal = new THREE.Vector3(-halfSize.x, halfSize.y, 0);
  // const rightTopCornerLocal = new THREE.Vector3(halfSize.x, halfSize.y, 0);
  // const leftBottomCornerLocal = new THREE.Vector3(-halfSize.x, -halfSize.y, 0);

  // Convert local corners to world space
  const leftTopCorner = leftTopCornerLocal.applyMatrix4(panelGrid.matrixWorld);
  const rightTopCorner = rightTopCornerLocal.applyMatrix4(panelGrid.matrixWorld);
  const leftBottomCorner = leftBottomCornerLocal.applyMatrix4(panelGrid.matrixWorld);

  return { leftTopCorner, rightTopCorner, leftBottomCorner: leftBottomCornerLocal };
};

export function throttle(func, limit) {
  let lastFunc;
  let lastRan;
  return function (...args) {
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}

const Cabeling = ({
  documentRef,
  cameraRef,
  canvasRef,
  mode,
  setCablingLoops,
  cablingLoops,
  setMode,
  setSelectedPoint,
  setVisible,
  setPosition,
  editor,
  setHistoryCablingPolygons,
}) => {
  const [points, setPoints] = useState([]);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const plusTexture = useLoader(TextureLoader, plusIcon);
  const canvasElement = canvasRef?.current;

  useFrame((state) => {
    const { mouse } = state;
    raycaster.setFromCamera(mouse, cameraRef.current);
  });

  function updateTextSprite(sprite, newText, visibility = true) {
    const canvas = sprite.material.map.image;
    const context = canvas.getContext("2d");

    // Clear the previous text
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Redraw the new text
    context.font = "72px Arial"; // Ensure the font matches what you used initially
    context.fillStyle = "rgba(8, 218, 145, 1)"; // Adjust color as needed
    context.fillText(newText, 0, 72); // Adjust position as needed

    // Mark the texture for update
    sprite.material.map.needsUpdate = true;

    // Update visibility
    sprite.visible = visibility;
  }

  const handleMouseMove = throttle((event) => {
    if (!startPoint || endPoint) return;
    const intersects = raycaster.intersectObjects(
      documentRef.current.panelGrids.map((pg) => pg.selectionMesh),
      false,
    );

    if (intersects.length > 0) {
      const hoveredGrid = documentRef.current.panelGrids.find(
        (pg) => pg.selectionMesh.id === intersects[0].object.id,
      );

      if (hoveredGrid) {
        const childHits = raycaster.intersectObjects(hoveredGrid.children.slice(1), true);

        if (childHits.length > 0) {
          const currentPoint = childHits[0].object;
          let supportedParent = currentPoint;
          while (supportedParent && supportedParent.name !== "supported") {
            supportedParent = supportedParent.parent;
          }

          if (supportedParent && supportedParent.name === "supported") {
            const panelCenter = calculatePanelCenter(supportedParent.children[0]);

            const panelOrigins = calculatePanelCornersWithMargin(supportedParent.children[0], 0);

            const connectionExists = cablingLoops.some((cableArray) =>
              cableArray.some((cable) => cable.id === currentPoint.id),
            );

            // Check if the point already exists in the current points array
            const pointExistsInCurrentPoints = points.some(
              (point) =>
                point.point.x === panelCenter.x &&
                point.point.y === panelCenter.y &&
                point.point.z === panelCenter.z,
            );

            // Check if the point already exists in any cabling loop
            const pointExistsInLoops = cablingLoops.some((loop) =>
              loop.some(
                (point) =>
                  point.point.x === panelCenter.x &&
                  point.point.y === panelCenter.y &&
                  point.point.z === panelCenter.z,
              ),
            );

            const filteredPoints = [
              ...points,
              {
                point: panelCenter,
                number: panelOrigins.leftBottomCorner,
                panel: supportedParent,
              },
            ].filter(
              (point, index, self) =>
                index ===
                self.findIndex(
                  (p) =>
                    p.point.x === point.point.x &&
                    p.point.y === point.point.y &&
                    p.point.z === point.point.z,
                ),
            );

            if (!pointExistsInCurrentPoints && !pointExistsInLoops) {
              editor.document.doCommand({
                name: "addCablingPoint",
                do: function () {
                  setPoints(filteredPoints);
                },
                undo: function () {
                  setPoints((prevPoints) => {
                    if (prevPoints.length >= 1) {
                      return prevPoints.slice(0, -1);
                    } else {
                      return []; // Handle case when there are fewer than 2 points
                    }
                  });
                },
                redo: function (_, { historyCablingPolygons, index }) {
                  const historyPoints = historyCablingPolygons[index];
                  setPoints([
                    ...points,
                    historyPoints[points.length],
                  ]);
                },
              });
            }
          }
        }
      }
    }
  }, 50);

  const handleClick = (event) => {
    const intersects = raycaster.intersectObjects(
      documentRef.current.panelGrids.map((pg) => pg.selectionMesh),
      false,
    );

    if (intersects.length > 0) {
      const clickedGrid = documentRef.current.panelGrids.find(
        (pg) => pg.selectionMesh.id === intersects[0].object.id,
      );

      if (clickedGrid) {
        const childHits = raycaster.intersectObjects(clickedGrid.children.slice(1), true);
        if (childHits.length > 0) {
          let currentPoint = childHits[0].object;
          let supportedParent = currentPoint;
          while (supportedParent && supportedParent.name !== "supported") {
            supportedParent = supportedParent.parent;
          }

          if (supportedParent && supportedParent.name === "supported") {
            currentPoint = supportedParent;
          }

          const panelCenter = calculatePanelCenter(supportedParent.children[0]);
          const panelOrigins = calculatePanelCornersWithMargin(supportedParent.children[0], 0.1);

          const isPointInLoops = cablingLoops.some((loop) =>
            loop.some(
              (point) =>
                point.point.x === panelCenter.x &&
                point.point.y === panelCenter.y &&
                point.point.z === panelCenter.z,
            ),
          );
          if (!startPoint && !isPointInLoops) {
            // First click - set start point.point and enable mouse movement tracking
            // Add the point and manage undo/redo
            editor.document.doCommand({
              name: "addCablingPoint",
              do: function () {
                setStartPoint(panelCenter);
                setPoints([
                  {
                    point: panelCenter,
                    number: panelOrigins.leftBottomCorner,
                    panel: supportedParent,
                  },
                ]);
              },
              undo: function () {
                setPoints((prevPoints) => {
                  if (prevPoints.length >= 1) {
                    return prevPoints.slice(0, -1);
                  } else {
                    return []; // Handle case when there are fewer than 2 points
                  }
                });
              },
              redo: function (_, { historyCablingPolygons, index }) {
                console.log()
                const historyPoints = historyCablingPolygons[index];
                setPoints([
                  ...points,
                  historyPoints[points.length]
                ]);
              },
            });
          } else if (!endPoint && startPoint && !isPointInLoops) {
            // Second click - close the cabling loop and save it
            const filteredPoints = [
              ...points,
              {
                point: panelCenter,
                number: panelOrigins.leftBottomCorner,
                panel: supportedParent,
              },
            ].filter(
              (point, index, self) =>
                index ===
                self.findIndex(
                  (p) =>
                    p.point.x === point.point.x &&
                    p.point.y === point.point.y &&
                    p.point.z === point.point.z,
                ),
            );

            editor.document.doCommand({
              name: "addCablingPolygons",
              do: function () {
                setPoints(filteredPoints);
                setCablingLoops((prevLoops) => [...prevLoops, filteredPoints]);
                setHistoryCablingPolygons((prevLoops) => [...prevLoops, filteredPoints]);

                // Reset for the next cabling loop
                setStartPoint(null);
                setPoints([]);
                setEndPoint(null);
              },
              undo: function () {
                setCablingLoops((prev) => {
                  const updatedPolygons = prev.slice(0, -1); // Remove the last added polygon

                  // Set measurementPoints to the previous polygon or an empty array
                  setPoints(prev[prev.length - 1] || []);
                  return updatedPolygons;
                });
              },
              redo: function (_, { historyCablingPolygons, index }) {
                setCablingLoops((prevPolygons) => {
                  const nextPolygon = historyCablingPolygons[index]; // Access the correct polygon from history
                  if (nextPolygon) {
                    return [...prevPolygons, nextPolygon];
                  }
                  return prevPolygons;
                });
                setPoints([]);
              },
            });


            canvasElement.removeEventListener("mousemove", handleMouseMove);
          }
        }
      }
    }
  };

  const handleShowChildrens = useCallback(() => {
    // First, set all relevant children to invisible
    documentRef.current.panelGrids.map((loop) => {
      loop.children.slice(1).map((e) => {
        if (e.name === "supported") {
          e.children[3].visible = false;
          e.children[4].visible = false;
          e.children[5].visible = false;
        }
      });
    });

    cablingLoops.forEach((loop) => {
      if (loop.length > 0) {
        // Show the 3rd child of the first point in the loop
        loop[0].panel.children[3].visible = true;

        // Show the 4th child of the last point in the loop
        loop[loop.length - 1].panel.children[4].visible = true;

        // If there's a middle element (i.e., a loop with more than 2 points)
        // if (loop.length > 2) {
        loop.forEach((e, index) => {
          updateTextSprite(e.panel.children[5], index + 1, true);
        });
        // }
      }
    });
  }, [cablingLoops]);

  useEffect(() => {
    handleShowChildrens();
  }, [handleShowChildrens]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // Reset the cabling process
        setStartPoint(null);
        setEndPoint(null);
        setPoints([]);

        // Remove event listeners
        if (canvasElement) {
          canvasElement.removeEventListener("mousemove", handleMouseMove);
          canvasElement.removeEventListener("click", handleClick);
        }
      }
    };

    const handleDoubleClick = (event) => {
      const intersects = raycaster.intersectObjects(
        documentRef.current.panelGrids.map((pg) => pg.selectionMesh),
        false,
      );

      if (intersects.length > 0) {
        const clickedGrid = documentRef.current.panelGrids.find(
          (pg) => pg.selectionMesh.id === intersects[0].object.id,
        );

        if (clickedGrid) {
          const childHits = raycaster.intersectObjects(clickedGrid.children.slice(1), true);
          if (childHits.length > 0) {
            let currentPoint = childHits[0].object;
            let supportedParent = currentPoint;
            while (supportedParent && supportedParent.name !== "supported") {
              supportedParent = supportedParent.parent;
            }

            if (supportedParent && supportedParent.name === "supported") {
              currentPoint = supportedParent;
            }

            const panelCenter = calculatePanelCenter(supportedParent.children[0]);
            const panelOrigins = calculatePanelCornersWithMargin(supportedParent.children[0], 0.1);

            const isPointInLoops = cablingLoops.some((loop) =>
              loop.some(
                (point) =>
                  point.point.x === panelCenter.x &&
                  point.point.y === panelCenter.y &&
                  point.point.z === panelCenter.z,
              ),
            );

            if (!isPointInLoops) {
              // Store data into state on double-click
              const doubleClickData = {
                point: panelCenter,
                number: panelOrigins.leftBottomCorner,
                panel: supportedParent,
              };

              const filteredPoints = [...points, doubleClickData].filter(
                (point, index, self) =>
                  index ===
                  self.findIndex(
                    (p) =>
                      p.point.x === point.point.x &&
                      p.point.y === point.point.y &&
                      p.point.z === point.point.z,
                  ),
              );

              editor.document.doCommand({
                name: "addCablingPolygons",
                do: function () {
                  setPoints(filteredPoints);
                  setCablingLoops((prevLoops) => [...prevLoops, filteredPoints]);
                  setHistoryCablingPolygons((prevLoops) => [...prevLoops, filteredPoints]);

                  // Reset for the next cabling loop
                  setStartPoint(null);
                  setPoints([]);
                  setEndPoint(null);
                },
                undo: function () {
                  setCablingLoops((prev) => {
                    const updatedPolygons = prev.slice(0, -1); // Remove the last added polygon

                    // Set measurementPoints to the previous polygon or an empty array
                    setPoints(prev[prev.length - 1] || []);
                    return updatedPolygons;
                  });
                },
                redo: function (_, { historyCablingPolygons, index }) {
                  setCablingLoops((prevPolygons) => {
                    const nextPolygon = historyCablingPolygons[index]; // Access the correct polygon from history
                    if (nextPolygon) {
                      return [...prevPolygons, nextPolygon];
                    }
                    return prevPolygons;
                  });
                  setPoints([]);
                },
              });

            }
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    if (mode === "auto") {
      const data = documentRef.current.panelGrids.map((ele) => {
        return ele.children
          .slice(1)
          .filter((e) => e.name === "supported")
          .map((e) => {
            console.log(e, "e.children")
            const position = calculatePanelCenter(e.children[0]);

            const surfacePosition = getSurfacePosition(position, e.children[0], 0.1);
            return { point: surfacePosition, panel: e };
          });
      });

      setMode("");

      // Add the point and manage undo/redo
      editor.document.doCommand({
        name: "addAutoCabling",
        do: function () {
          setCablingLoops(data);
        },
        undo: function () {
          setCablingLoops([]);
        },
        redo: function () {
          setCablingLoops(data);
        },
      });
      return;
    }

    if (canvasElement && mode === "manual") {
      canvasElement.addEventListener("click", handleClick);
      if (startPoint) {
        canvasElement?.addEventListener("mousemove", handleMouseMove);
        canvasElement.addEventListener("dblclick", handleDoubleClick);
      }
    }

    return () => {
      if (canvasElement) {
        canvasElement.removeEventListener("click", handleClick);
        canvasElement.removeEventListener("dblclick", handleDoubleClick);
        if (!endPoint) {
          canvasElement.removeEventListener("mousemove", handleMouseMove);
        }
      }
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [canvasRef, points, startPoint, endPoint, mode, cablingLoops]);

  const handlePointClick = (event, position) => {
    if (position) {
      // Project the 3D position into 2D screen space
      const vector = position.clone().project(cameraRef.current);
      const x = (vector.x * 0.5 + 0.5) * canvasRef.current.clientWidth;
      const y = (-vector.y * 0.5 + 0.5) * canvasRef.current.clientHeight;

      // Set the delete icon's position based on the projected coordinates
      setPosition({ top: y + 40, left: x });
      setSelectedPoint(position);
      setVisible(true);
    }
  };

  const handleDelete = (groupId) => {
    setCablingLoops((loops) => loops.filter((loop, index) => index !== groupId));
    setHistoryCablingPolygons((loops) => loops.filter((loop, index) => index !== groupId));
  };

  return (
    <>
      {/* Render all completed cabling loops */}
      {cablingLoops.map((loop, index) => (
        <React.Fragment key={index}>
          <LineGroup points={loop} onDelete={handleDelete} index={index} />
          {loop.map((position, pointIndex) => (
            <AddPointAtPosition
              key={`${index}-${pointIndex}`}
              position={position.point}
              onClick={(e) => handlePointClick(e, position.point)}
              pointIndex={position.number}
              index={pointIndex}
            />
          ))}
        </React.Fragment>
      ))}

      {/* Render the current in-progress loop */}
      {points.map((position, index) => (
        <AddPointAtPosition key={`current-${index}`} position={position.point} />
      ))}
      {points.length > 1 && <AddLineBetweenPoints points={points} />}
    </>
  );
};

export default Cabeling;
