import "./Tooltip.css";

// Tooltip component to add to buttons and other elements
export default function Tooltip({
    children,
    tooltipText="Tooltip text",
    position="top",
    vertical="0px",
    horizontal="0px",
}){

    return(
        <div className="tooltip-trigger">
            {children}
            <div 
                className={`tooltip tooltip-${position}`} 
                style={{
                    "--vertical": vertical,
                    "--horizontal": horizontal
                }}
            >
                {tooltipText}
            </div>
        </div>
    )
}