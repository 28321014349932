class Document {
  constructor() {
    this.history = [];
    this.cursor = 0;
    this.points = [];
    this.lines = [];
    this.manualPoints = [];
    this.manualLines = [];
    this.measurementPoints = [];
    this.measurementLines = [];
    this.polygons = [];
    this.oldPolygons = [];
    this.cables = [];
  }

  doCommand(cmd) {
    this.history[this.cursor++] = cmd;
    cmd.do(this);
    this.history.length = Math.min(this.history.length, this.cursor);
  }

  redo(data) {
    if (this.cursor < this.history.length) {
      const cmd = this.history[this.cursor];
      if (cmd && typeof cmd.redo === "function") {
        cmd.redo(this, data, cmd);
      } else if (cmd) {
        cmd.do(this, data, cmd);
      }
      this.cursor++;
    }
  }

  undo() {
    if (this.cursor > 0) {
      this.cursor--;
      const cmd = this.history[this.cursor];
      cmd.undo(this);
    }
  }
}
// Modified Editor class
class Editor {
  constructor() {
    this.document = new Document();
  }

  addPoint(point) {
    const cmd = new AddPointCommand(point);
    this.document.doCommand(cmd);
  }

  addLine(line) {
    const cmd = new AddLineCommand(line);
    this.document.doCommand(cmd);
  }

  addMeasurementPoint(point) {
    const cmd = new AddMeasurementPointCommand(point);
    this.document.doCommand(cmd);
  }

  addMeasurementLine(line) {
    const cmd = new AddMeasurementLineCommand(line);
    this.document.doCommand(cmd);
  }

  clearMeasurementPointsandLines(callTrue) {
    const cmd = new ClearMeasurementPointsAndLinesCommand(
      this.document.measurementPoints,
      this.document.measurementLines,
      callTrue,
    );
    this.document.doCommand(cmd);
  }

  clearPointsAndLines() {
    const cmd = new ClearPointsAndLinesCommand(this.document.manualPoints, this.document.manualLines);
    this.document.doCommand(cmd);
  }

  autoCabling(panelGrids, calculatePanelCenter) {
    const cmd = new AutoCablingCommand(
      panelGrids,
      calculatePanelCenter,
      addPointAtPosition,
      addLineBetweenPoints,
      this.cables,
    );
    this.document.doCommand(cmd);
  }

  undo() {
    this.document.undo();
  }

  redo(data) {
    this.document.redo(data);
  }
}

export { Editor, Document };
