import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryButton } from "src/shared/StyledComponents";

import Common3dViewer from "./components/3DViewerLeft/Common3dViewer";
import InformationPart from "./components/InformationPartRight/InformationPart";
import * as S from "./styles";

import { AppActions } from "src/redux";
import { AppSelectors, ProjectSelectors } from "src/redux/selectors";


interface IThreeDViewer {
  isFromSavedProject?: boolean;
}

const ThreeDViewer = ({ isFromSavedProject = false }: IThreeDViewer) => {
  const [modelFullView, setModelFullView] = useState<boolean>(true);
  // const [fullscreen, setFullScreen] = useState<boolean>(false);
  const threeDViewerMaxWidth = useSelector(ProjectSelectors.getMaxWidth3dViewer);
  const maxWidth3DViewerWithImages = useSelector(ProjectSelectors.getMaxWidth3DViewerWithImages);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector(AppSelectors.getCurrentStep);

  const nextStepBtnClickHandler = () => {
    dispatch(AppActions.setCurrentStep(3));
  };
  return (
    <S.Container threeDViewerMaxWidth={modelFullView} modelFullView={modelFullView}>
      {!modelFullView ? (
        <>
          <S.ObjViewContainer>
            <Common3dViewer
              from3DViwer={true}
              maxWidth3DViewer={threeDViewerMaxWidth}
              maxWidth3DViewerWithImages={maxWidth3DViewerWithImages}
              modelFullView={modelFullView}
              setModelFullView={setModelFullView}
            />
          </S.ObjViewContainer>

          {!modelFullView && <InformationPart />}
          {currentStep === 2 ? (
            <PrimaryButton
              onClick={nextStepBtnClickHandler}
              style={{
                width: "230px",
                marginInline: "auto",
              }}
              className="!mt-11"
            >
              {`${t("NEXT STEP")}`}
            </PrimaryButton>
          ) : (
            <div style={{ marginBlock: "0.5rem" }} />
          )}
        </>
      ) : (
        <Common3dViewer
          from3DViwer={true}
          maxWidth3DViewer={threeDViewerMaxWidth}
          maxWidth3DViewerWithImages={maxWidth3DViewerWithImages}
          modelFullView={modelFullView}
          setModelFullView={setModelFullView}
          // fullscreen={fullscreen}
          // setFullscreen={setFullScreen}
        />
      )}
    </S.Container>
  );
};

export default ThreeDViewer;
